/**
 *@name: 设置中心接口
 *@date: 2021-04-14
 *@version: 3.0
 *@description: 新表模块
 */
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/setting`; //服务前缀

/**
 *门票
 */
//【setting008】字典接口 -刘资涌
export const dataDictionary = data => {
  return http.get(`${prefix}/data/dictionary`, data);
};

//分页获取打印模板 -刘资涌
export const printTemplatePage = data => {
  return http.get(`${prefix}/print/template/page`, data);
};
// 【setting009】查询渠道接口
export let qmpChannel = data => {
  return http.get(`${prefix}/qmp/channel`, data);
};

//【setting004】省市级连 -刘资涌
export const chinaRegion = (data, config) => {
  return http.get(`${prefix}/china/region`, data, config);
};

// 通过汉字地址获取省市区的id
export const chinaRegionAddress = data => {
  return http.get(`${prefix}/china/region/address`, data);
};

// 不分页获取打印模板 -刘资涌
export const getPrintTemplateList = data => {
  return http.get(`${prefix}/print/template/list`, data);
};
