<template>
  <div id="index" :class="{ 'show-menu': !showMenu }">
    <main class="main flex-start">
      <!-- 左侧菜单 -->
      <left-nav />
      <div class="flex-grow" style="overflow: hidden;">
        <!--头部-->
        <top-bar />
        <!--二级路由-->
        <!-- <transition :name="slideName">-->
        <router-view v-if="isRouteAlive && currentLeftMenuKey" />
        <!--</transition>-->
      </div>
    </main>
    <!-- 硬件设备设置start -->
    <init-setting :vis.sync="showSetting" />
    <!-- 硬件设备设置end -->
  </div>
</template>

<script>
import TopBar from "@/components/common/TopBar";
import InitSetting from "@/components/hardware/InitSetting";
import LeftNav from "../components/common/LeftNav.vue";

export default {
  name: "Index",
  components: {
    TopBar,
    InitSetting,
    LeftNav
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouteAlive: true, // 刷新路由组件
      slideName: "slide-fade-up",
      password: "",
      winHeight: "1000px",
      showSetting: false,
      showContent: false
    };
  },
  // created() {
  //   if (!localStorage.getItem("token")) {
  //     this.$router.push("/login");
  //   }
  // },
  mounted() {
    // 如果没有保存过硬件配置，则弹出硬件配置
    if (!localStorage.getItem("Hardware")) {
      this.showSetting = true;
    }
    let that = this;
    window.echo = function(str) {
      that.$message.info(str);
    };
  },

  beforeDestroy() {
    window.echo = function() {};
  },

  computed: {
    // 用户信息
    user() {
      return this.$store.state.common.user;
    },
    // 是否展示菜单
    showMenu() {
      return this.$store.state.common.showMenu;
    },
    // 当前头部激活菜单
    currentLeftMenuKey() {
      return this.$store.state.common.currentLeftMenuKey;
    }
  },
  methods: {
    // 切换商户触发更新
    reload() {
      this.isRouteAlive = false;
      this.$nextTick(() => {
        this.isRouteAlive = true;
      });
    }
  }
};
</script>

<style lang="less">
/*菜单隐藏后，修改mwrapper高度*/
.show-menu {
  /*旧版*/
  .wrapper {
    height: calc(100vh - 16px);
  }
  /*新版*/
  .wrapper-new {
    height: 100vh !important;
  }
}
</style>

<style lang="less" scoped>
#index {
  height: 100%;
  * {
    box-sizing: border-box;
  }
  > .main {
    height: 100%;
    min-height: 0;
  }
}

/*切换动画*/
.slide-fade-up-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-up-enter {
  transform: translateY(100%);
  opacity: 0;
}
.slide-fade-up-leave-to {
  transform: translateY(-200%);
  position: absolute;
  opacity: 0;
}

.slide-fade-down-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-down-enter {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-fade-down-leave-to {
  transform: translateY(200%);
  position: absolute;
  opacity: 0;
}
</style>
