export default {
  // 商户信息
  sellerInfoMutation(state, data) {
    state.seller_info = data;
  },

  // 重置state，清空历史数据
  resetState(state) {
    const data = {
      seller_info: {} // 零售商户信息：用于交账页面
    };
    for (let key in state) {
      state[key] = data[key];
    }
  }
};
