/**
 * @name: 餐饮路由
 */

export default [
  {
    path: "/catering",
    component: () =>
      import(
        /* webpackChunkName: "chuck-catering" */ "../views/catering/sell/CateringIndex.vue"
      ),
    children: [
      {
        //桌台点餐
        path: "",
        name: "TableOrder",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/sell/TableOrder.vue"
          )
      },
      {
        //快速点餐
        path: "/catering/menuList",
        name: "MenuList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/menuList.vue"
          )
      },
      {
        //取号排队
        path: "/catering/TakeNumber",
        name: "TakeNumber",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/number/TakeNumber.vue"
          )
      },
      {
        //叫号
        path: "/catering/CallNumber",
        name: "CallNumber",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/number/CallNumber.vue"
          )
      },
      {
        // 取餐
        path: "/catering/takeFood",
        name: "TakeFood",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/take/TakeFood.vue"
          )
      },
      {
        // 取餐列表
        path: "/catering/TakeFoodList",
        name: "TakeFoodList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/take/TakeFoodList.vue"
          )
      }
    ]
  }
];
