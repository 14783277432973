<!--
  @name: 顶部按业态区分的菜单
  @author: fengyanlong
  @date:2021-08-17
-->
<template>
  <div class="top-nav flex-start align-center" ref="dropRef">
    <div
      class="item flex-shrink mr-x"
      :class="{ primary: currentTopMenuKey === menu.menuPermissionKey }"
      v-for="(menu, index) in topMenus"
      :key="index"
    >
      <p @click="menuClick(menu)" style="user-select: none;">{{ menu.name }}</p>
      <ul class="sub-panel" v-show="checkMenu.id === menu.id">
        <li class="gray">选择商户</li>
        <li
          v-for="item in menu.children"
          :key="item.id"
          @click="getLeftMenuList(menu, item)"
          :class="{ active: saleMerchantId === item.merchantId }"
        >
          {{ item.merchantName }}
        </li>
      </ul>
    </div>
    <!--把设计稿放到每个页面展示的选中商户，统一改到头部，方便管理-->
    <p class="name flex-grow text-1" v-if="saleMerchantName">
      当前商户：{{ saleMerchantName }}
    </p>
  </div>
</template>

<script>
import { getMainMenuList, getSubMenuList } from "../../api/user";

export default {
  name: "top-nav",
  data() {
    return {
      topMenus: [],
      checkMenu: {},
      newVersion: localStorage.newVersion === "true" // 是否使用新版窗口， 默认true
    };
  },
  // 切换商户触发路由更新
  inject: ["reload"],
  created() {
    // 获取头部动态菜单
    this.getTopMenuList();
  },
  computed: {
    // 选中的菜单key
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    },
    // 选中的左边菜单key
    currentLeftMenuKey() {
      return this.$store.state.common.currentLeftMenuKey;
    },
    // 销售商户Id
    saleMerchantId() {
      return this.$store.state.common.saleMerchantId;
    },
    // 销售商户Id
    saleMerchantName() {
      return this.$store.state.common.saleMerchantName;
    }
  },
  mounted() {
    document.addEventListener("click", this.outClick);
    // this.getMerchantList();
  },

  beforeDestroy() {
    document.removeEventListener("click", this.outClick);
  },

  methods: {
    // 获取头部菜单
    getTopMenuList() {
      getMainMenuList({
        channel: this.newVersion ? 66 : 6 // 新版票务为66，旧版为6
      })
        .then(res => {
          if (res.length === 0) {
            this.$confirm({
              title: "系统提示",
              content: `您还没有系统菜单权限，请联系景区管理员！${
                this.newVersion ? "或尝试切到旧版" : "或尝试切到新版"
              }`,
              okText: "重新登录",
              cancelText: this.newVersion ? "回到旧版" : "体验新版",
              onOk: () => {
                localStorage.removeItem("token");
                this.$router.push("/login");
              },
              onCancel: () => {
                this.$store.commit("common/setSaleMerchant", {
                  merchantId: "",
                  merchantName: ""
                });
                this.$store.commit("common/setCurrentTopMenuKey", "");
                this.$store.commit("common/setCurrentLeftMenuKey", "");
                localStorage.setItem(
                  "newVersion",
                  (!this.newVersion).toString()
                );
                location.reload();
              }
            });
          } else {
            this.topMenus = res;
            this.activeMenu();
          }
        })
        .catch(() => {});
    },
    // 获取左边菜单列表
    getLeftMenuList({ menuPermissionKey }, { merchantId, merchantName }) {
      getSubMenuList({
        merchantId,
        menuPermissionKey,
        channel: this.newVersion ? 66 : 6 // 新版票务为66，旧版为6
      })
        .then(res => {
          this.$store.commit("common/setCurrentLeftMenuList", res);
          this.$store.commit("common/setCurrentTopMenuKey", menuPermissionKey);
          let index = res.findIndex(
            temp => temp.menuPermissionKey === this.currentLeftMenuKey
          );
          if (index === -1) index = 0;
          const item = res[index];
          this.$store.commit(
            "common/setCurrentLeftMenuKey",
            item.menuPermissionKey
          );
          this.$router.push(item.url);
          // 相同页面切换商户刷新路由
          if (merchantId) this.reload();
          this.$store.commit("common/setSaleMerchant", {
            merchantId: merchantId || "",
            merchantName: merchantName || ""
          });
          this.checkMenu = {};
        })
        .catch(() => {});
    },

    // 激活菜单
    activeMenu() {
      let index = this.topMenus.findIndex(
        temp => temp.menuPermissionKey === this.currentTopMenuKey
      );
      if (index === -1) index = 0;

      let item = this.topMenus[index];
      // 如果是业态菜单，则包含商户, 默认选择第一个商户
      if (
        item.children.length &&
        !this.newVersion &&
        item.menuPermissionKey !== "appCateringNew"
      ) {
        let idx = item.children.findIndex(
          temp => temp.merchantId === this.saleMerchantId
        );
        if (idx === -1) idx = 0;
        this.getLeftMenuList(item, item.children[idx]);
      }
      // 否则为通用菜单
      else {
        this.getLeftMenuList(item, {});
      }
    },

    //点击顶部菜单
    menuClick(menu) {
      // 新版直接获取左边菜单，不需要选商户 （除了餐饮临时）
      // 或没有商户列表 或者旧版兼容长白山，票务不需要选商户，直接获取左边菜单
      if (
        (this.newVersion && menu.menuPermissionKey !== "appCateringNew") ||
        menu.children.length === 0 ||
        (menu.menuPermissionKey === "appHome" &&
          sessionStorage.appointmentSelection == 2)
      ) {
        this.getLeftMenuList(menu, {});
      }
      // 旧版需要选择商户
      else {
        if (this.checkMenu.id === menu.id) {
          // 点击相同隐藏下拉菜单
          this.checkMenu = {};
        } else {
          this.checkMenu = menu;
        }
      }
    },
    // 点击其它隐藏下拉菜单
    outClick(e) {
      let dropRef = this.$refs.dropRef;
      //避免contains方法报错
      if (dropRef === undefined || dropRef.length === 0) return false;
      if (!dropRef.contains(e.target)) {
        this.checkMenu = {};
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

.top-nav {
  flex-grow: 1;
  .item {
    position: relative;
    cursor: pointer;
    min-width: 80px;
    margin: 0 4px;
    font-size: 18px;
    text-align: center;
    flex-shrink: 1;
    .sub-panel {
      position: absolute;
      top: 55px;
      left: 0;
      z-index: 500;
      min-width: 140px;
      width: max-content;
      max-height: 80vh;
      overflow-y: auto;
      border-radius: 8px;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
      background: #fff;
      color: #333;

      .gray {
        text-align: left;
        font-size: 14px;
        border: none;
      }
      li {
        padding: 12px 16px;
        border-radius: 3px;
        font-size: 16px;
        text-align: left;
        border-top: 1px solid @border;
        line-height: 1.5;
        &:hover:not(.gray),
        &.active {
          background: @primary;
          color: #fff;
        }
      }
    }
  }
  /*商户名称*/
  .name {
    font-size: 14px;
    padding-right: 24px;
    flex-grow: 1;
    text-align: right;
    color: #999;
  }
}

/*适配小屏*/
@media screen and (max-width: 1336px) {
  .top-nav .item {
    min-width: 60px;
  }
  .top-nav {
    .name {
      width: 180px;
      padding-right: 16px;
    }
  }
}
</style>
