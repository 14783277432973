<!--
  @name: 证件照或者拍照直接上次组件
  @author: fengyanlong
  @date:2021-05-13
  @attr: {
    //返回照片地址
    v-model:{
      type: String
    }
  }
-->

<template>
  <div :class="small ? 'pictureInput-small' : 'pictureInput'" @click="open">
    <div v-if="value" class="picture">
      <img :src="value" alt="图片显示失败" />
    </div>
    <div v-else>
      <a-icon type="camera" class="icon" />
    </div>
    <GaoPaiYiCamera
      :showInfo="showInfo"
      :devIndex="0"
      @close="showInfo = false"
      @grabImageUrl="getPicture"
    />
  </div>
</template>

<script>
import { fileUploadBase64 } from "../../api/upload";
import HardwareService from "../../common/domains/hardware-domain/hardwareService";
import GaoPaiYiCamera from "@/components/hardware/GaoPaiYiCamera";

export default {
  name: "picture-input",
  props: {
    value: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  components: { GaoPaiYiCamera },
  data() {
    return {
      showInfo: false
    };
  },
  created() {},
  methods: {
    open() {
      let that = this;
      let Hardware = localStorage.getItem("Hardware");
      if (Hardware && JSON.parse(Hardware).gaoPaiYi) {
        //开启了高拍仪 则用高拍仪识别身份证
        this.showInfo = true;
      } else {
        HardwareService.getCameraPicture(that.getPicture);
        HardwareService.openCamera();
      }
    },
    getPicture(data64) {
      fileUploadBase64({ data: data64, ext: "png" })
        .then(res => {
          this.value = res.src;
          this.showInfo = false;
          this.$emit("input", res.src);
        })
        .catch(err => {
          console.log(err);
          this.$message.error("接口错误");
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

.pictureInput {
  border: 1px solid @border;
  overflow: hidden;
  border-radius: 6px;
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  cursor: pointer;
  .picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .icon {
    font-size: 40px;
    color: @gray;
  }
}
.pictureInput-small {
  border: 1px solid @border;
  overflow: hidden;
  border-radius: 6px;
  width: 80px;
  height: 80px;
  line-height: 95px;
  text-align: center;
  cursor: pointer;
  .picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .icon {
    font-size: 40px;
    color: @gray;
  }
}
</style>
