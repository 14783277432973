/**
 * @name: 窗口售票路由
 * @description: 根据业务有两层嵌套路由
 */
export default [
  /** ============= 左侧菜单内路由 begin ============== */
  {
    path: "/home",
    // route level code-splitting 路由级代码分割
    // this generates a separate chunk (about.[hash].js) for this route 为该路由生成一个单独的块(about.[hash].js)
    // which is lazy-loaded when the route is visited. 当路由被访问时，它是惰性加载的。
    component: () =>
      import(/* webpackChunkName: "chuck-home" */ "../views/home/HomeIndex"),
    children: [
      /**
       * 左侧菜单栏 -- 门票旧版
       */
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/sell/TiketType"
          ),
        children: [
          //售票窗口里的门票列表
          {
            path: "",
            name: "homeTicketList",
            component: () =>
              import(
                /* webpackChunkName: "chuck-home" */ "../views/home/sell/TicketList"
              )
          },
          // 取单列表
          {
            path: "/home/sell/TakeOrderList",
            name: "homeTakeOrderList",
            component: () =>
              import(
                /* webpackChunkName: "chuck-home" */ "../views/home/sell/TakeOrderList"
              )
          },
          // 门票操作详情
          {
            path: "/home/sell/TicketOperateDetail",
            name: "homeSellTicketOperateDetail",
            component: () =>
              import(
                /* webpackChunkName: "chuck-home" */ "../views/home/ticket/TicketOperateDetail"
              )
          }
        ]
      },
      /**
       * 票务新版
       * */
      {
        path: "/home/sell/SellProduct",
        name: "homeSellProduct",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/sell/SellProduct"
          )
      },
      /**
       * 左侧菜单栏 -- 门票模块
       */
      // 门票列表
      {
        path: "/home/ticket/TicketList",
        name: "homeTickets",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/ticket/TicketList"
          )
      },
      /**
       * 订单 new
       */
      {
        path: "/home/orderNew/OrderList",
        name: "orderNewList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/orderNew/OrderList.vue"
          )
      },
      {
        path: "/home/orderNew/OrderDetail",
        name: "orderNewDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/orderNew/OrderDetail.vue"
          )
      },
      // 订单产品纪录列表
      {
        path: "/home/orderNew/ProductRecordList",
        name: "ProductRecordList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/orderNew/ProductRecordList.vue"
          )
      },
      // 订单产品详情
      {
        path: "/home/orderNew/ProductDetail",
        name: "ProductDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/orderNew/ProductDetail.vue"
          )
      },
      // 订单产品退款纪录
      {
        path: "/home/orderNew/RefundRecordList",
        name: "RefundRecordList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/orderNew/RefundRecordList.vue"
          )
      },
      // 订单产品退款审批
      {
        path: "/home/orderNew/RefundApprove",
        name: "RefundApprove",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/orderNew/RefundApprove.vue"
          )
      },
      /**
       * 订单
       */
      {
        path: "/home/order/OrderList",
        name: "orderList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/OrderList.vue"
          )
      },
      // 清账
      {
        path: "/home/order/SettleAccounts",
        name: "SettleAccounts",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/SettleAccounts.vue"
          )
      },
      // 明细
      {
        path: "/home/order/OrderDetailList",
        name: "OrderDetailList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/OrderDetailList.vue"
          )
      },
      // 门票
      {
        path: "/home/order/OrderTicket",
        name: "OrderTicket",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/OrderTicket.vue"
          )
      },
      // 订单详情
      {
        path: "/home/order/OrderDetail",
        name: "homeOrderDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/OrderDetail.vue"
          )
      },
      // 订单子产品详情
      {
        path: "/home/order/ProductDetail",
        name: "homeProductDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/ProductDetail.vue"
          )
      },

      // 退款订单详情
      {
        path: "/home/order/RefundDetail",
        name: "refundDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/RefundDetail.vue"
          )
      },
      // 自定义金额
      {
        path: "/home/sell/CustomAmount",
        name: "homeCustomAmount",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/sell/CustomAmount"
          )
      },
      //门票操作详情
      {
        path: "/home/ticket/TicketOperateDetail",
        name: "homeTicketOperateDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/ticket/TicketOperateDetail"
          )
      },

      //门票组合产品操作详情
      {
        path: "/home/ticket/combinationDetail",
        name: "CombinationDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/ticket/combinationDetail"
          )
      },
      //门票组合产品操作详情-基本信息
      {
        path: "/home/ticket/ticketOperatDetail/combinationTicketDetail",
        name: "CombinationTicketDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/ticket/ticketOperatDetail/combinationTicketDetail"
          )
      },

      // 售票-订单管理
      {
        path: "/home/sell/OrderManage",
        name: "homeOrderManage",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/sell/OrderManage.vue"
          )
      },
      //售票-门票操作列表
      {
        path: "/home/ticket/TicketOperate",
        name: "homeTicketOperate",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/ticket/TicketOperate"
          )
      },

      // 会员修改资料
      {
        path: "/home/member/MemberEdit",
        name: "homeMemberEdit",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/MemberEdit"
          )
      },
      // 会员充值
      {
        path: "/home/member/MemberRecharge",
        name: "homeMemberRecharge",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/MemberRecharge"
          )
      },
      // 会员特权
      {
        path: "/home/member/MemberUpgrade",
        name: "homeMemberUpgrade",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/MemberUpgrade"
          )
      },
      // 会员退余额
      {
        path: "/home/member/MemberRefundBalance",
        name: "homeMemberRefundBalance",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/MemberRefundBalance"
          )
      },
      // 会员退余额列表
      {
        path: "/home/member/MemberRefundBalanceList",
        name: "homeMemberRefundBalanceList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/MemberRefundBalanceList"
          )
      },
      // 会员优惠券
      {
        path: "/home/member/MemberCoupon",
        name: "homeMemberCoupon",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/MemberCoupon"
          )
      },
      // 会员资产变更记录
      {
        path: "/home/member/AssetChangeRecord",
        name: "homeAssetChangeRecord",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/AssetChangeRecord"
          )
      },
      // 会员积分变更记录
      {
        path: "/home/member/ScoreChangeRecord",
        name: "homeScoreChangeRecord",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/ScoreChangeRecord"
          )
      },
      // 会员成长值变更记录
      {
        path: "/home/member/GrowthChangeRecord",
        name: "homeGrowthChangeRecord",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/GrowthChangeRecord"
          )
      },
      // 会员消费记录
      {
        path: "/home/member/ConsumeRecord",
        name: "homeConsumeRecord",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/ConsumeRecord"
          )
      },
      // 会员门票信息
      {
        path: "/home/member/TicketsRecord",
        name: "homeTicketsRecord",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/member/TicketsRecord"
          )
      },
      // 登记游客信息
      {
        path: "/home/sell/RegisterTouristInfo",
        name: "homeRegisterTouristInfo",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/sell/RegisterTouristInfo"
          )
      },
      {
        path: "/home/sell/OrderInfo",
        name: "homeOrderInfo",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/sell/OrderInfo"
          )
      },
      //确认订单信息(自定义收款)
      {
        path: "/home/sell/OrderCustomerInfo",
        name: "homeOrderCustomerInfo",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/sell/OrderCustomerInfo"
          )
      },
      // 取票页面
      {
        path: "/home/ticket/GetTicket",
        name: "homeGetTicket",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/ticket/GetTicket"
          )
      },
      // 取票详情页
      {
        path: "/home/ticket/GetTicketDetail",
        name: "homeGetTicketDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/ticket/GetTicketDetail"
          )
      },
      // 快捷取票（F5），填写出游人信息
      {
        path: "/home/ticket/WriteTouristMsg",
        name: "homeWriteTouristMsg",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/ticket/WriteTouristMsg"
          )
      },
      // 重新打印门票
      {
        path: "/home/ticket/RePrint",
        name: "HomeRePrint",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/ticket/RePrint"
          )
      },
      /**
       * 快速下单
       */
      {
        path: "/home/quickPlaceOrder/placeOrder",
        name: "quickPlaceOrder",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/quickPlaceOrder/placeOrder.vue"
          )
      },
      /**
       * 快速下单v2
       */
      {
        path: "/home/quickPlaceOrderV2/placeOrder",
        name: "quickPlaceOrderV2",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/quickPlaceOrderV2/placeOrder.vue"
          )
      },
      /**
       * 飞速下单
       */
      {
        path: "/home/quickPlaceOrderFlying/placeOrder",
        name: "quickPlaceOrderFlying",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/quickPlaceOrderFlying/placeOrder.vue"
          )
      },
      /**
       * 我的订单
       */
      {
        path: "/home/order/MyOrderList",
        name: "myorderList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/MyOrderList.vue"
          )
      },
      // 明细
      {
        path: "/home/order/MyOrderDetailList",
        name: "MyOrderDetailList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/MyOrderDetailList.vue"
          )
      },
      // 门票
      {
        path: "/home/order/MyOrderTicket",
        name: "MyOrderTicket",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/home/order/MyOrderTicket.vue"
          )
      }
    ]
  }
  /** ============= 左侧菜单内路由 end ============== */
];
