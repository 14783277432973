export default {
  // 设置销售商户信息
  setSaleMerchant(state, { merchantId, merchantName }) {
    state.saleMerchantId = merchantId;
    state.saleMerchantName = merchantName;
    sessionStorage.setItem("saleMerchantId", merchantId);
    sessionStorage.setItem("saleMerchantName", merchantName);
  },
  // 设置左侧菜单
  setCurrentLeftMenuList(state, data) {
    state.currentLeftMenuList = data;
  },
  // 设置头部激活菜单
  setCurrentTopMenuKey(state, data) {
    state.currentTopMenuKey = data;
    sessionStorage.setItem("currentTopMenuKey", data);
  },
  // 设置左侧激活菜单
  setCurrentLeftMenuKey(state, data) {
    state.currentLeftMenuKey = data;
    sessionStorage.setItem("currentLeftMenuKey", data);
  },

  // 更新全局loading
  updateLoading(state, status) {
    state.loading = status;
  },
  //开启快捷键
  OpenShortcutKey(state) {
    state.shortcut_key = true;
  },
  //关闭快捷键
  CloseShortcutKey(state) {
    state.shortcut_key = false;
  },
  // 更新用户信息
  updateUser(state, user) {
    localStorage.setItem("merchantId", user.merchantId);
    state.user = user;
  },
  //更新会员信息
  UpdateMember(state, data) {
    state.member = data;
  },
  setWindowType(state, data) {
    state.windowType = data;
  },
  setmemberId(state, data) {
    state.member.id = data;
  },
  setCurrentBtnPermissionList(state, data) {
    state.currentBtnPermissionList = data;
  },
  // 更新取单按钮的状态
  updateTakeOrderBtnStatus(state, status) {
    state.isEnableTakeOrderBtn = status;
  },

  // 更新菜单展示状态
  updateMenuStatus(state, data) {
    state.showMenu = data;
  },

  // 重置state，清空历史数据
  resetState(state) {
    const data = {
      shortcut_key: true, //快捷键控制
      loading: false, //全局的loading
      user: {}, // 用户信息
      member: {
        id: ""
      }, //会员信息
      windowType: "sellTicket", //售票：sellTicket,零售：retail,项目消费：consume
      isEnableTakeOrderBtn: false, // 是否启用取单按钮

      // 选中的销售商户信息，请求时在头部传给后台
      saleMerchantId: "", // 销售商户Id
      saleMerchantName: "", // 销售商户名称

      // 动态菜单内容
      showMenu: true, // 展开菜单
      currentLeftMenuList: [],
      currentTopMenuKey: "", //高亮的顶部菜单key
      currentLeftMenuKey: "" //高亮的左侧菜单key
    };
    for (let key in state) {
      state[key] = data[key];
    }
  }
};
