/**
 * 支付抽离公共方法
 * 旧打印方法，待废弃
 */

import {
  orderTicketprintinfo,
  orderProjectpackageprintInfo,
  orderCateringPrint,
  orderRetailPrintInfo,
  orderTheatrePrintInfo,
  orderCombinationprintinfo
} from "../../api/search";
import { mapActions } from "vuex";
import { orderPrint } from "../../api/order";
import HardwareService from "../../common/domains/hardware-domain/hardwareService";

export const myMixin = {
  methods: {
    ...mapActions("order", ["setCartList"]),
    ...mapActions("common", ["updateMember"]),
    /**
     *
     * @param {String} formatId:业态id
     * @param {String} orderId:订单id(可选)
     */
    printTicket(formatId, orderId) {
      let Hardware = localStorage.getItem("Hardware");
      let setting;
      if (Hardware) {
        setting = JSON.parse(Hardware);
      }
      // 项目套餐打印
      if (
        formatId === "1365211691942277121" &&
        setting &&
        setting.printProject
      ) {
        orderProjectpackageprintInfo({
          orderId: orderId || this.propParam.orderNumber,
          templateId: setting.projectTemplate
        })
          .then(res => {
            if (res) {
              HardwareService.printTicket(JSON.stringify(res));
            }
          })
          .catch(() => {});
      }
      // 零售收银小票打印(暂时不需要窗口硬件设置中的打印配置)
      else if (formatId === "1365211692399456258") {
        orderRetailPrintInfo({
          orderId: orderId || this.propParam.orderNumber
        })
          .then(res => {
            if (res) {
              HardwareService.printSmallTicket(JSON.stringify(res));
            }
          })
          .catch(() => {});
      }
      // 剧场票(暂时不需要窗口硬件设置中的打印配置)
      else if (formatId === "1361211689463443192") {
        orderTheatrePrintInfo({
          orderId: orderId || this.propParam.orderNumber
        })
          .then(res => {
            if (res) {
              HardwareService.printTicket(JSON.stringify(res));
            }
          })
          .catch(() => {});
      }
      //默认门票打印api(加组合产品)
      else if (setting && setting.printTicket) {
        // 组合产品获取打印模板接口
        let api = "";
        if (formatId == "1459375207863967746") {
          api = orderCombinationprintinfo;
        } else {
          // 门票获取打印模板接口
          api = orderTicketprintinfo;
        }
        api({
          orderId: orderId || this.propParam.orderNumber
        })
          .then(res => {
            if (res) {
              // console.log(res);
              let flag = HardwareService.printTicket(JSON.stringify(res));
              //如果成功调用打印门票，就掉接口通知后端已经取票
              if (flag) {
                orderPrint({
                  channel: 6, //6是窗口端
                  orderId: orderId || this.propParam.orderNumber
                })
                  .then(() => {})
                  .catch(() => {});
              }
            }
          })
          .catch(() => {});
      }
    },
    paySuccess(param = {}) {
      HardwareService.speak("支付成功");
      // 餐饮下的单，调用云打印机 餐饮单品-----1435059066526212100  // 餐饮套餐-----1435059066526212101
      if (
        this.$route.query.formatId === "1435059066526212100" ||
        this.$route.query.formatId === "1435059066526212101"
      ) {
        orderCateringPrint({ orderId: this.propParam.orderNumber })
          .then(() => {})
          .catch(() => {});
      }
      // 以挂账方式支付的，根据后台接口返回结果，判断是否需要调打印功能
      if (param.ifCanPrint) {
        console.log("挂账支付，不启用打印功能");
      } else {
        // 非餐饮的订单，调本地打印机
        this.printTicket(this.propParam.formatId, this.propParam.orderNumber);
      }
      this.$router.replace("/paySuccess");
    },
    paySpeak(text) {
      const str = text || "支付成功";
      HardwareService.speak(str);
    }
  }
};
