<!-- windows安装包端：下载硬件驱动页面 -->
<template>
  <div class="download-driver">
    <p class="font-xxl">驱动文件下载</p>
    <a-divider></a-divider>

    <div class="bd round wrap">
      <div class="header bg-gray pl-2x">{{ driverPac.typeName }}</div>
      <div class="body pl-4x pr-4x pt-2x pb-2x flex-between flex-wrap">
        <div
          class="body-child mb-4x p-2x"
          v-for="(subItem, subindex) in driverPac.iotClientInstallPackageVOList"
          :key="subindex"
        >
          <a :href="subItem.fileUrl" :download="subItem.name">
            <div class="text-c top">
              <img
                :src="subItem.iconUrl ? subItem.iconUrl : defaultIcon"
                :alt="subItem.name"
              />
              <h2 class="font-l bold text-1 mt-2x">
                {{ subItem.name }}
              </h2>
            </div>
            <p class="text-l mt-2x ml-2x mb-2x gray">
              {{ subItem.detail || subItem.name }}
            </p>
            <div class="text-r gray">
              <p>Version {{ subItem.version }}</p>
              <p>
                上传时间
                {{
                  (subItem.uploadTime ? subItem.uploadTime : "2021-04-14")
                    | date
                }}
              </p>
              <p>应用大小 {{ subItem.size }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { listIotClientInstallPackage } from "@/api/iot";

export default {
  name: "DownloadDriver",
  components: {},
  props: {},
  data() {
    return {
      defaultIcon: require("@/assets/images/vipdefault.jpg"),
      driverPac: {}
    };
  },
  created() {
    listIotClientInstallPackage().then(res => {
      this.driverPac = res[0];
    });
  },
  methods: {}
};
</script>
<style lang="less" scoped>
@import "../assets/less/custom.less";

.download-driver {
  width: 1200px;
  margin: 0 auto;
}

.wrap {
  max-height: 780px;
  overflow-y: auto;
}

.header {
  height: 50px;
  line-height: 50px;
}
.body {
  &:after {
    content: "";
    width: 30%;
  }
}
.body-child {
  width: 30%;
  height: 350px;
  box-sizing: border-box;
  border: 1px solid @border;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  overflow: hidden;
  &:hover {
    border-color: @primary;
  }
  .top {
    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: @gray;
    }
  }
}
</style>
