/**
 * @name: MS-搜索接口
 * @version: 3.0
 */
import http from "../utils/fetch"; // 公共请求方法

const prefix = "/api/search"; // api前缀

/**
 *产品搜索-李小华
 */
// 【search646】窗口-会员消费明细 - http://192.168.1.50:40001/project/41/interface/api/4183
export function orderConsumedetail(data) {
  return http.get(`${prefix}/order/consumedetail`, data);
}

// 【search001】窗口售票-门票列表
export function productFrontWindowTicket(data) {
  return http.get(`${prefix}/product/front/windowticket`, data);
}

// 开通会员特权【search003】
export function productFrontMemberprivilege(data) {
  return http.get(`${prefix}/product/front/memberprivilege`, data);
}

// 窗口-零售产品【search004】
export function productFrontRetailproduct(data) {
  return http.get(`${prefix}/product/front/retailproduct`, data);
}

// 窗口-会员充值【search005】
// export function productFrontMemberrecharge(data) {
//   return http.get(`${prefix}/product/front/memberrecharge`, data);
// }

// 窗口-门票充值【search002】ok
export function productFrontRechargeticket(data) {
  return http.get(`${prefix}/product/front/rechargeticket`, data);
}
// 后台-项目列表【search009】李小华
export function productBackProject(data) {
  return http.get(`${prefix}/product/back/project`, data);
}
/**
 *订单搜索-李小华
 */
// 窗口-会员兑换币套餐【search441】 http://192.168.1.50:40001/project/41/interface/api/4216
export const productFrontpackage = data => {
  return http.get(`${prefix}/product/front/exchangecoinpackage`, data);
};
// 窗口-会员兑换券【search441】 http://192.168.1.50:40001/project/41/interface/api/4216
export const productFrontMembervoucher = data => {
  return http.get(`${prefix}/product/front/membervoucher`, data);
};
// 后台/窗口-产品退款管理【search716】 lixiaohua http://192.168.1.50:40001/project/41/interface/api/3937
export const orderRefundmanagement = data => {
  return http.get(`${prefix}/order/refundmanagement`, data);
};
// 后台/窗口-产品退款审批管理【search716】 http://192.168.1.50:40001/project/41/interface/api/3998
export const orderRefundAuditManagement = data => {
  return http.get(`${prefix}/order/refundauditmanagement`, data);
};

// 后台/窗口-销售产品记录【search717】 lixiaohua http://192.168.1.50:40001/project/41/interface/api/3949
export const orderSalesproductrecord = data => {
  return http.get(`${prefix}/order/salesproductrecord`, data);
};

// 后台/窗口-销售订单记录 http://192.168.1.50:40001/project/41/interface/api/3933
export const orderSalesorderRecord = data => {
  return http.get(`${prefix}/order/salesorderrecord`, data);
};
//系统后台-订单产品业态【search714】-李小华  http://192.168.1.50:40001/project/41/interface/api/3729
export function orderProductformats(data) {
  return http.get(`${prefix}/order/productformats`, data);
}

//系统后台-订单商品销售员search715】 -李小华 http://192.168.1.50:40001/project/41/interface/api/3730
export function orderProductsalesman(data) {
  return http.get(`${prefix}/order/productsalesman`, data);
}

// 窗口-门票操作【search039】ok
export function orderTicketOperation(data) {
  return http.get(`${prefix}/order/ticketoperation`, data);
}
// 窗口-会员门票【search042】ok
export function orderMemberTicket(data) {
  return http.get(`${prefix}/order/memberticket`, data);
}
// 窗口售票-门票订单（操作）【search042】
export function orderTicketOrder(data) {
  return http.get(`${prefix}/order/ticketorder`, data);
}
// 窗口售票-门票补差价【search042】
export function orderTicketPremium(data) {
  return http.get(`${prefix}/order/ticketpremium`, data);
}

// 窗口售票-订单列表【search042】
export function orderWindoworder(data) {
  return http.get(`${prefix}/order/windoworder`, data);
}
// 窗口-订单管理【search040】ok
export function orderUserOrder(data) {
  return http.get(`${prefix}/order/userorder`, data);
}
// 窗口/后台-项目消费记录【search049】ok
export function orderConsumerecord(data) {
  return http.get(`${prefix}/order/consumerecord`, data);
}
// 窗口-摩天轮/碰碰车【search047】ok
export function orderProjectconsume(data) {
  return http.get(`${prefix}/order/projectconsume`, data);
}
// 窗口-订单号查询门票打印【search059】ok
export function orderTicketprintinfo(data) {
  return http.get(`${prefix}/order/ticketprintinfo`, data);
}
// 窗口-订单号查询门票打印【search059】ok 2022-1-10 来自替换华哥索引库
export function orderOrdinarytaketicket(data) {
  return http.get(`${prefix}/order/ordinarytaketicket`, data);
}

// 订单号与打印模板查询项目套餐打印信息【search619】
export function orderProjectpackageprintInfo(data) {
  return http.get(`${prefix}/order/projectpackageprintInfo`, data);
}

// 窗口- 设置热销产品【search006】ok
export function productFrontHotproduct(data) {
  return http.get(`${prefix}/product/front/hotproduct`, data);
}

// 后台-活动列表(查询所有产品)【search031】ok
export function productBackMerchantproduct(data) {
  return http.get(`${prefix}/product/back/merchantproduct`, data);
}

// 窗口-会员退余额【search052】ok
export function orderMemberrefundbalance(data) {
  return http.get(`${prefix}/order/memberrefundbalance`, data);
}

// 窗口/后台-会员消费记录【search053】ok
export function orderMemberconsumerecord(data) {
  return http.get(`${prefix}/order/memberconsumerecord`, data);
}
// 窗口/后台-门票资产变更记录【search057】ok
export function orderTicketassetsstate(data) {
  return http.get(`${prefix}/order/ticketassetsstate`, data);
}
// 窗口/后台/自助机-项目套餐可玩项目【search059】ok
export function orderPackageusableProject(data) {
  return http.get(`${prefix}/order/packageusableProject`, data);
}

//窗口-门票取票【search601】ok
export function orderDoorticket(data) {
  return http.get(`${prefix}/order/doorticket`, data);
}

// 窗口 - 快捷键 - 取票查询
export function orderTaketicket(data) {
  return http.get(`${prefix}/order/taketicket`, data);
}

// 窗口-普通门票打印信息【search615】ok
export function orderOrdinaryprintinfo(data) {
  return http.get(`${prefix}/order/ordinaryprintinfo`, data);
}

// 窗口-团体票打印信息【search616】ok
export function orderGroupprintinfo(data) {
  return http.get(`${prefix}/order/groupprintinfo`, data);
}

// 窗口-取票号查询门票打印【search617】ok
export function orderAuxiliaryprintinfo(data) {
  return http.get(`${prefix}/order/auxiliaryprintinfo`, data);
}

// 窗口-团体票取票【search602】ok
export function orderGroupticket(data) {
  return http.get(`${prefix}/order/groupticket`, data);
}

//窗口-根据票号查询需要重新打印的门票【search625】ok
export function orderAgainTakeTicket(data) {
  return http.get(`${prefix}/order/againtaketicket`, data);
}
//窗口-会员资产套餐 http://192.168.1.50:40001/project/41/interface/api/3923
export function productFrontPropertyPackage(data) {
  return http.get(`${prefix}/product/front/propertypackage`, data);
}
//窗口-会员资产查询列表 http://192.168.1.50:40001/project/41/interface/api/3921
export function productFrontMemberProperty(data) {
  return http.get(`${prefix}/product/front/memberproperty`, data);
}

/**
 *窗口租赁-李小华
 */

// 窗口-补打单张门票 http://192.168.1.50:40001/project/41/interface/api/4582
export function orderReprintSingleTicket(data) {
  return http.get(`${prefix}/order/reprintsingleticket`, data);
}

// 窗口租赁-物品租赁【search141】   http://192.168.1.50:40001/project/41/interface/api/2130
export function productFrontItemsrental(data) {
  return http.get(`${prefix}/product/front/itemsrental`, data);
}

// 窗口租赁-物品归还【search621】
export const orderItemsreturned = data => {
  return http.get(`${prefix}/order/itemsreturned`, data);
};

// 窗口租赁-租赁记录【search623】
export const orderLeaserecord = data => {
  return http.get(`${prefix}/order/leaserecord`, data);
};
// 系统后台/窗口租赁-押金收取记录【search622】
export const orderDeposittakerecord = data => {
  return http.get(`${prefix}/order/deposittakerecord`, data);
};
// 后台/窗口-订单记录聚合条件 http://192.168.1.50:40001/project/41/interface/api/4324
export const orderOrderRecordCondition = data => {
  return http.get(`${prefix}/order/orderrecordcondition`, data);
};
// 后台/窗口-产品记录聚合条件 http://192.168.1.50:40001/project/41/interface/api/4348
export const orderProductRecordCondition = data => {
  return http.get(`${prefix}/order/productrecordcondition`, data);
};
// 后台/窗口-退款记录聚合条件 http://192.168.1.50:40001/project/41/interface/api/4351
export const orderRefundRecordCondition = data => {
  return http.get(`${prefix}/order/refundrecordcondition`, data);
};
/**
 *窗口零售-核销取货-李小华  【search623】 http://192.168.1.50:40001/project/41/interface/api/2436
 */
export const orderVerifyclaimgoods = data => {
  return http.get(`${prefix}/order/verifyclaimgoods`, data);
};

/**
 * 餐饮
 */
// 窗口餐饮- 桌台点餐【search413】
export const productFrontDeskorderfood = data => {
  return http.get(`${prefix}/product/front/deskorderfood`, data);
};

// 窗口餐饮- 快速点餐【search414】
export const productFrontFastorderfood = data => {
  return http.get(`${prefix}/product/front/fastorderfood`, data);
};
// 窗口餐饮-菜品规格【search419】
export const productFrontDishesspecification = data => {
  return http.get(`${prefix}/product/front/dishesspecification`, data);
};
// 窗口餐饮-套餐菜品 套餐【search420】
export const productFrontMealdishes = data => {
  return http.get(`${prefix}/product/front/mealdishes`, data);
};

// 窗口餐饮-取餐【search624】 http://192.168.1.50:40001/project/41/interface/api/2613
export const orderTakefood = data => {
  return http.get(`${prefix}/order/takefood`, data);
};

/**
 * 剧场
 */
//剧场-场地管理【search431】 http://192.168.1.50:40001/project/41/interface/api/2635
export const productBackSite = data => {
  return http.get(`${prefix}/product/back/site`, data);
};
// 窗口剧场-售剧场票(剧目列表)【search435】
export const productFrontDramagoal = data => {
  return http.get(`${prefix}/product/front/dramagoal`, data);
};
// 窗口剧场-售剧场票(场次票列表)【search436】
export const productFrontSessionbyTheater = data => {
  return http.get(`${prefix}/product/front/sessionbytheater`, data);
};

// 窗口-餐饮结账单打印【search626】-云打印-http://192.168.1.50:40001/project/41/interface/api/2654
export const orderCateringPrint = data => {
  return http.get(`${prefix}/order/cateringprint`, data);
};
// 订单号查询零售打印信息【search627】-http://192.168.1.50:40001/project/41/interface/api/3649
export const orderRetailPrintInfo = data => {
  return http.get(`${prefix}/order/retailprintinfo`, data);
};

//窗口剧场-取票或换座【search628】 -李小华  http://192.168.1.50:40001/project/41/interface/api/3655
export const orderExchangeticket = data => {
  return http.get(`${prefix}/order/exchangeticket`, data);
};
//订单号查询剧场票打印信息【search629】 http://192.168.1.50:40001/project/41/interface/api/3659
export const orderTheatrePrintInfo = data => {
  return http.get(`${prefix}/order/theatreprintinfo`, data);
};

//取剧场票打印信息【search630】
export const orderTaketheatreticket = data => {
  return http.get(`${prefix}/order/taketheatreticket`, data);
};

//窗口-组合产品列表【search438】
export const productFrontCompositeProduct = data => {
  return http.get(`${prefix}/product/front/compositeproduct`, data);
};

// 订单号查询组合产品打印信息【search631】（取全部票） http://192.168.1.50:40001/project/41/interface/api/3751
export const orderCombinationprintinfo = data => {
  return http.get(`${prefix}/order/combinationprintinfo`, data);
};

//窗口-子订单号查询组合产品(取单张门票)【search633】  http://192.168.1.50:40001/project/41/interface/api/3780
export const orderSubcombinationprintinfo = data => {
  return http.get(`${prefix}/order/subcombinationprintinfo`, data);
};

//窗口-组合产品取票列表【search632】 http://192.168.1.50:40001/project/41/interface/api/3778
export const orderCombinationticket = data => {
  return http.get(`${prefix}/order/combinationticket`, data);
};
// 窗口- 门票列表二【search107】
export const productFrontWindowTicketTwo = data => {
  return http.get(`${prefix}/product/front/timeticket`, data);
};
// 窗口- 窗口- 门票列表四(飞速下单)【search107】http://192.168.1.50:40001/project/41/interface/api/4534
export const productFrontWindowRapidticketo = data => {
  return http.get(`${prefix}/product/front/rapidticket`, data);
};
// -订单明细列表【search049】ok
export function orderOrderDetail(data) {
  return http.get(`${prefix}/order/orderdetail`, data);
}
//系统后台/窗口-门票列表【search714】 - 李小华  http://192.168.1.50:40001/project/41/interface/api/3788
export const orderTicketlist = data => {
  return http.get(`${prefix}/order/ticketlist`, data);
};

// 窗口- 交账商户汇总【search1201】- 李小华  http://192.168.1.50:40001/project/41/interface/api/3794
export const orderAccountMerchantssummary = data => {
  return http.get(`${prefix}/order/account/merchantssummary`, data);
};

//窗口- 交账产品汇总【search1202】 http://192.168.1.50:40001/project/41/interface/api/3795
export const orderAccountProductsummary = data => {
  return http.get(`${prefix}/order/account/productsummary`, data);
};
//窗口- 交账产品汇总v2 http://192.168.1.50:40001/project/41/interface/api/3798
export const orderAccountConfirmsummary = data => {
  return http.get(`${prefix}/order/account/confirmsummary`, data);
};
//主订单号查询快速下单打印信息【search1204】
export const orderQuickorderprint = data => {
  return http.get(`${prefix}/order/quickorderprint`, data);
};
//订单详情查询快速下单打印信息【search1205】
export const orderQuickdetailprint = data => {
  return http.get(`${prefix}/order/quickdetailprint`, data);
};
//订单是否能开发票【search1104】- 李小华 http://192.168.1.50:40001/project/41/interface/api/3846
export const orderCheckinvoice = data => {
  return http.get(`${prefix}/order/checkinvoice`, data);
};
// 开具蓝字发票【search1101】 http://192.168.1.50:40001/project/55/interface/api/3845
export const orderIssueblueinvoice = data => {
  return http.get(`${prefix}/order/issueblueinvoice`, data);
};

// 开具红字发票【search1102】 http://192.168.1.50:40001/project/55/interface/api/3851
export const orderIssueredinvoice = data => {
  return http.get(`${prefix}/order/issueredinvoice`, data);
};
// 窗口- 门票列表三【search108】
export const productFrontSilkyTicket = data => {
  return http.get(`${prefix}/product/front/silkyticket`, data);
};
// 窗口-组合产品列表二【search440】 http://192.168.1.50:40001/project/41/interface/api/3892
export const productFrontCombinationProduct = data => {
  return http.get(`${prefix}/product/front/combinationproduct`, data);
};
// 窗口-会员优惠券查看 http://192.168.1.50:40001/project/41/interface/api/3910
export const productFrontCouponapplyProduct = data => {
  return http.get(`${prefix}/product/front/couponapplyproduct`, data);
};
// 窗口-会员优惠券查看-李小华 http://192.168.1.50:40001/project/41/interface/api/3914
export const orderMemberProduct = data => {
  return http.get(`${prefix}/order/memberproduct`, data);
};

// 窗口- 销售记录【search635】 http://192.168.1.50:40001/project/41/interface/api/3889
export const orderSalesrecord = data => {
  return http.get(`${prefix}/order/salesrecord`, data);
};

// 窗口-批量门票打印信息【search638】 http://192.168.1.50:40001/project/41/interface/api/3909
export const orderBatchticketprint = data => {
  return http.get(`${prefix}/order/batchticketprint`, data);
};

// 窗口-票号门票打印信息【search639】 http://192.168.1.50:40001/project/41/interface/api/3912
export const orderTicketnoprint = data => {
  return http.get(`${prefix}/order/ticketnoprint`, data);
};

// 窗口-快速门票打印信息【search636】 http://192.168.1.50:40001/project/41/interface/api/3905
export const orderQuickTicketPrint = data => {
  return http.get(`${prefix}/order/quickticketprint`, data);
};
// 窗口-快速组合产品打印信息【search637】  http://192.168.1.50:40001/project/41/interface/api/3907
export const orderQuickCombinationPrint = data => {
  return http.get(`${prefix}/order/quickcombinationprint`, data);
};
// 窗口-子票号查询主票号【search643】 http://192.168.1.50:40001/project/41/interface/api/3996
export const orderSonmainauxiliaryid = data => {
  return http.get(`${prefix}/order/sonmainauxiliaryid`, data);
};
// 新版窗口 零售产品列表 可查询 http://192.168.1.50:40001/project/41/interface/api/4078
export const searchRetailGoodslist = data => {
  return http.get(`${prefix}/product/front/retailgoodslist`, data);
};
// 新版窗口 取票列表 可查询 http://192.168.1.50:40001/project/41/interface/api/4159
export const orderTaketicketslist = data => {
  return http.get(`${prefix}/order/taketicketslist`, data);
};

// 窗口-子订单取票【search647】 可查询 http://192.168.1.50:40001/project/41/interface/api/4222
export const orderSubordertaketicket = data => {
  return http.post(`${prefix}/order/subordertaketicket`, data);
};
// 核销记录
export const getVerificationrecord = data => {
  return http.get(`${prefix}/order/todayverification`, data);
};
// 窗口-游乐列表【search109】 http://192.168.1.50:40001/project/41/interface/api/3903
export const productFrontAmusement = data => {
  return http.get(`${prefix}/product/front/amusement`, data);
};
// 窗口-重打零售打印信息【search650】 http://192.168.1.50:40001/project/41/interface/api/4279
export const orderWhackretailprint = data => {
  return http.get(`${prefix}/order/whackretailprint`, data);
};
// 窗口-快速零售打印信息【search649】 http://192.168.1.50:40001/project/41/interface/api/4276
export const orderQuickretailprint = data => {
  return http.get(`${prefix}/order/quickretailprint`, data);
};
// 窗口- 聚合产品分类【search117】 http://192.168.1.50:40001/project/41/interface/api/4270
export const productFrontAggregatecategory = data => {
  return http.get(`${prefix}/product/front/aggregatecategory`, data);
};
// 窗口/小程序-剧目列表【search4304】 http://192.168.1.50:40001/project/41/interface/api/4465
export const productFrontPlayList = data => {
  return http.get(`${prefix}/product/front/playlist`, data);
};
// 窗口/小程序-售场次票【search4305】 http://192.168.1.50:40001/project/41/interface/api/4468
export const productFrontSaleSessionTicket = data => {
  return http.get(`${prefix}/product/front/salesessionticket`, data);
};
