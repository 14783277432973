import Vue from "vue";
import VueRouter from "vue-router";
// 防止 Vue 重复点击相同路由报错
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

Vue.use(VueRouter);

import Index from "@/views/Index"; //首页
import SecondScreen from "@/views/SecondScreen"; //副屏页
import DownloadDriver from "@/views/DownloadDriver"; //下载驱动页
import Test from "@/views/Test"; //测试页面
import NewPay from "@/views/NewPay"; //新版支付重构页
import PaySuccess from "@/components/pay/PaySuccess"; // 新版支付成功页面
import Api from "@/views/Api"; //接口测试页

import home from "./home"; // 窗口售票
import retail from "./retail"; // 零售
import play from "./play"; // 游乐
import lease from "./lease"; //租赁
import catering from "./catering"; // 餐饮
import cateringNew from "./catering-new"; // 餐饮
import theater from "./theater"; //剧场
import combinationProduct from "./combination-product"; // 组合产品
import account from "./account"; // 交账

import Login from "./login"; //登录路由
// 首页
const Indexs = [
  {
    path: "/",
    component: Index,
    children: [
      ...home,
      ...retail,
      ...play,
      ...lease,
      ...catering,
      ...cateringNew, // 升级版餐饮，暂时搬旧的过来
      ...theater,
      ...combinationProduct,
      ...account
    ]
  },
  { path: "/newPay", name: "NewPay", component: NewPay },
  { path: "/paySuccess", name: "PaySuccess", component: PaySuccess },
  { path: "/SecondScreen", name: "SecondScreen", component: SecondScreen },
  {
    path: "/DownloadDriver",
    name: "DownloadDriver",
    component: DownloadDriver
  },
  { path: "/test", name: "Test", component: Test, meta: { test: 1 } },
  { path: "/api", name: "Api", component: Api }
];

const router = new VueRouter({
  routes: [...Indexs, ...Login]
});
export default router;
