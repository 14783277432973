<!--
  @name: 采集人脸组件
  @author: fengyanlong
  @attr: {
    //返回人脸信息id： personId
    v-model:{
      type: String
    },
    echoimgurl: 人脸照片回显
  }
  @events: imgUrlChange 返回拍照后的人脸在线图片
-->
<template>
  <div class="faceInput" :class="{ small }" @click="openCamera">
    <div v-if="imgUrl" class="picture">
      <img :src="imgUrl" alt="图片显示失败" />
    </div>
    <div v-else>
      <div class="iconfont iconrenlianheshen icon"></div>
      <div class="tip primary" v-if="!small">点击开始采集人脸</div>
      <div class="font-s primary" v-else>点击采集人脸</div>
    </div>
    <GaoPaiYiCamera
      :showInfo="showInfo"
      @close="showInfo = false"
      @grabImageUrl="saveFace"
    />
  </div>
</template>

<script>
import { faceAnalyzeUpload, face } from "../../api/iot";
import HardwareService from "../../common/domains/hardware-domain/hardwareService";
// import CameraService from "@/common/domains/hardware-domain/cameraService";
import GaoPaiYiCamera from "@/components/hardware/GaoPaiYiCamera";
export default {
  name: "faceInput",
  props: ["value", "echoimgurl", "faceid", "small"],
  components: { GaoPaiYiCamera },
  data() {
    return {
      imgUrl: "",
      imgbase64: "",
      showInfo: false //高拍仪拍照弹窗
    };
  },
  watch: {
    echoimgurl: {
      handler(newVal) {
        if (newVal) {
          this.imgUrl = newVal;
        }
      },
      immediate: true
    }
  },
  created() {},
  methods: {
    openCamera() {
      let that = this;
      let Hardware = localStorage.getItem("Hardware");
      if (Hardware && JSON.parse(Hardware).gaoPaiYi) {
        //开启了高拍仪 则用高拍仪识别身份证
        this.showInfo = true;
      } else {
        HardwareService.getCameraPicture(that.getPicture);
        HardwareService.openCamera();
      }
    },
    getPicture(data64) {
      this.imgbase64 = `data:image/png;base64,${data64}`;
      //this.$message.success({ content: this.imgbase64, duration: 10 });
      faceAnalyzeUpload({ imageBase64: this.imgbase64 })
        .then(res => {
          //this.$message.success(JSON.stringify(res));
          this.saveFace(res.src);
        })
        .catch(err => {
          console.log(err);
        });
    },
    saveFace(url) {
      //type：1是会员，0是员工
      let postData = { imageUrl: url, type: 1 };
      //补录人脸
      if (this.faceid) {
        postData.lastPersionId = this.faceid;
      }
      face(postData)
        .then(res => {
          this.imgUrl = url;
          this.$message.success("人脸录入成功");
          this.$emit("input", res.personId);
          this.$emit("imgUrlChange", url);
          this.showInfo = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

.faceInput {
  margin: 2px 0;
  border: 1px solid @border;
  overflow: hidden;
  border-radius: 6px;
  width: 200px;
  height: 200px;
  text-align: center;
  cursor: pointer;
  .picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .icon {
    line-height: 140px;
    font-size: 90px;
    color: @gray;
  }
  .tip {
    font-size: 18px;
  }
  &.small {
    width: 80px;
    height: 80px;
    .icon {
      line-height: 45px;
      font-size: 40px;
      color: @gray;
    }
  }
}
</style>
