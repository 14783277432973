/**
 * @name:物品租赁路由
 */

export default [
  {
    path: "/lease",
    component: () =>
      import(
        // route level code-splitting 路由级代码分割
        // this generates a separate chunk (about.[hash].js) for this route 为该路由生成一个单独的块(about.[hash].js)
        // which is lazy-loaded when the route is visited. 当路由被访问时，它是惰性加载的。
        /* webpackChunkName: "chuck-lease" */ "../views/lease/LeaseIndex.vue"
      ),
    children: [
      {
        //租赁列表
        path: "",
        name: "leaseList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-lease" */ "../views/lease/LeaseList.vue"
          )
      },
      /**
       * 收取押金
       */
      {
        path: "/lease/deposit/collection",
        name: "leaseDepositCollection",
        component: () =>
          import(
            /* webpackChunkName: "chuck-lease" */ "../views/lease/deposit/DepositCollection.vue"
          )
      },
      /**
       * 退还押金
       */
      {
        path: "/lease/deposit/return",
        name: "leaseDepositReturn",
        component: () =>
          import(
            /* webpackChunkName: "chuck-lease" */ "../views/lease/deposit/DepositReturn.vue"
          )
      },
      /**
       * 押金变更记录
       */
      {
        path: "/lease/deposit/change/list",
        name: "leaseChangeList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-lease" */ "../views/lease/deposit/ChangeList.vue"
          )
      },
      /**
       * 物品归还
       */
      {
        path: "/lease/goods/return",
        name: "leaseGoodsReturn",
        component: () =>
          import(
            /* webpackChunkName: "chuck-lease" */ "../views/lease/goods/GoodsReturn.vue"
          )
      },
      /**
       * 物品归还-确认订单信息
       */
      {
        path: "/lease/goods/order/info",
        name: "leaseOrderInfo",
        component: () =>
          import(
            /* webpackChunkName: "chuck-lease" */ "../views/lease/goods/OrderInfo.vue"
          )
      },
      /**
       * 押金收取记录
       */
      {
        path: "/lease/deposit/record",
        name: "leaseDepositRecord",
        component: () =>
          import(
            /* webpackChunkName: "chuck-lease" */ "../views/lease/deposit/DepositRecord.vue"
          )
      },
      /**
       * 租赁记录
       */
      {
        path: "/lease/record",
        name: "LeaseRecord",
        component: () =>
          import(
            /* webpackChunkName: "chuck-lease" */ "../views/lease/goods/LeaseRecord.vue"
          )
      },
      /**
       * 确认租赁
       */
      {
        path: "/lease/confirm",
        name: "LeaseConfirm",
        component: () =>
          import(
            /* webpackChunkName: "chuck-lease" */ "../views/lease/LeaseConfirm.vue"
          )
      }
    ]
  }
];
