/**
 * @name:剧场路由
 */

export default [
  {
    path: "/theater",
    component: () =>
      import(
        // route level code-splitting 路由级代码分割
        // this generates a separate chunk (about.[hash].js) for this route 为该路由生成一个单独的块(about.[hash].js)
        // which is lazy-loaded when the route is visited. 当路由被访问时，它是惰性加载的。
        /* webpackChunkName: "chuck-theater" */ "../views/theater/sell/TheaterIndex.vue"
      ),
    children: [
      {
        //售剧场票
        path: "/theater/sell/SellProduct",
        name: "theaterListNew",
        component: () =>
          import(
            /* webpackChunkName: "chuck-theater" */ "../views/theater/sell/SellProduct.vue"
          )
      },
      {
        //售剧场票
        path: "/theater/TheaterList",
        name: "theaterList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-theater" */ "../views/theater/sell/TheaterList.vue"
          )
      },

      /**
       * 取票
       */
      {
        path: "/theater/pick-ticket/PickTicket",
        name: "PickTicket",
        component: () =>
          import(
            /* webpackChunkName: "chuck-theater" */ "../views/theater/pick-ticket/PickTicket.vue"
          )
      },
      /**
       * 换座
       */
      {
        path: "/theater/change-seat/ChangeSeat",
        name: "ChangeSeat",
        component: () =>
          import(
            /* webpackChunkName: "chuck-theater" */ "../views/theater/change-seat/ChangeSeat.vue"
          )
      }
    ]
  }
];
