import Vue from "vue";
import Vuex from "vuex";

import common from "./common";
// import car from "./car";
import consume from "./consume";
import retail from "./retail";
import order from "./order";
import ticketSales from "./ticketSales";
import setting from "./setting";
import system from "./system";
//import createLogger from "vuex/dist/logger";
Vue.use(Vuex);
//const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({
  modules: {
    common,
    // car,
    consume,
    retail,
    order,
    ticketSales,
    setting,
    system
  }
});
