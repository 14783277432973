<template>
  <a-config-provider :locale="locale" size="large">
    <a-spin :spinning="loading" tip="加载中...">
      <!--一级路由-->
      <router-view></router-view>
    </a-spin>
  </a-config-provider>
</template>

<script>
//设置全局组件中文
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  name: "App",
  data() {
    return {
      locale: zhCN
    };
  },

  computed: {
    // 加载中
    loading() {
      return this.$store.state.common.loading;
    }
  },

  created() {
    // if (!localStorage.getItem("token")) {
    //   this.$router.push("/login");
    // }
  },

  updated() {
    //判断是否安卓环境
    /*    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    // 弹出屏幕
    if (isAndroid) {
      alert(555)
      //如果是安卓环境，做个定时器（因为App的触发时机问题）
      let winHeight = window.innerHeight;
      /!**
       * filterInputElement: []存储聚焦时，不需弹起页面的input框class
       * 把自己页面的input框class加入该数组，或者自己页面input使用not-bounce-input-class
       *!/
      const filterInputElement = ["ant-radio-input", "not-bounce-input-class"];
      this.$nextTick(function() {
        setTimeout(() => {
          let inputs = document.getElementsByTagName("input");
          inputs.forEach(element => {
            // 不需过滤掉的input元素，聚焦时才弹起页面
            if (!filterInputElement.includes(element.className)) {
              element.onfocus = function() {
                let top = this.getBoundingClientRect().top;
                let body = document.getElementsByTagName("body");
                if (top > winHeight * 0.4) body[0].style.position = "relative";
                body[0].style.top = `-${top - winHeight * 0.4}px`;
              };
              element.onblur = function() {
                let body = document.getElementsByTagName("body");
                body[0].style.top = `-${0}px`;
              };
            }
          });
        }, 1000);
      });
    }*/
  },

  mounted() {}
};
</script>

<style lang="less">
@import "./assets/less/reset.less";
</style>
