import { message } from "ant-design-vue";
class HardwareService {
  /**
   * 初始化硬件，并返回硬件信息json字符串
   */
  static getDeviceInfo() {
    try {
      return window.test.initDevice();
    } catch (err) {
      console.warn("获取硬件信息失败");
      return [];
    }
  }
  /**
   *初始化硬件设置信息
   **/
  static initPrinter() {
    try {
      return window.test.initPrinter();
    } catch (err) {
      console.warn("初始化硬件设置信息失败");
      return [];
    }
  }
  /**
   * 选择了硬件设备后，点击确认
   */
  static choiceDevice(jsonStr) {
    try {
      window.test.choiceDevice(jsonStr);
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * 开启摄像头
   */
  static openCamera() {
    try {
      window.test.openCamera();
    } catch (error) {
      message.warning("开启失败，请检查摄像头设备");
    }
  }
  /**
   * 获取摄像头返回的图像数据
   * @param fn 绑定一个函数接收拍摄结果
   */
  static getCameraPicture(fn) {
    try {
      window.getPicture = function(res) {
        fn(res);
      };
    } catch (error) {
      console.log("获取摄像头数据失败");
    }
  }
  /**
   * 打印普通的门票模板
   * @param jsonStr
   * @returns { Boolean } // 如果成功打印，返回真
   */
  static printTicket(jsonStr) {
    try {
      window.test.printTicket(jsonStr);
      // console.log("打印", jsonStr);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * 测试打印点餐
   * @param jsonStr
   * @returns { Boolean } // 如果成功打印，返回真
   */
  static printSmallTicket(jsonStr) {
    try {
      window.test.printSmallTicket(jsonStr);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  /**
   * 零售打印
   * @param jsonStr
   * @returns { Boolean } // 如果成功打印，返回真
   */
  static printRetail(jsonStr) {
    try {
      window.test.printRetail(jsonStr);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  /**
   * 打印交账模板
   */
  static printDailyReport(jsonStr) {
    try {
      window.test.printDailyReport(jsonStr);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 语音提示
   */
  static speak(str) {
    // 如果没有开启收银播报，就不播报了
    if (!localStorage.getItem("voiceBroadcast")) return;
    try {
      window.test.speak(str);
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * 被动扫码方法
   */
  static scanCode(success, fail = () => {}) {
    try {
      // （传参数为1那么回调的js方法是payScan，0是scanCode方法）
      window.test.optScan(0);
      window.scanCode = res => {
        success(res);
      };
      return true;
    } catch (error) {
      fail(error);
      console.warn("调取被动扫码方法失败");
      return false;
    }
  }

  // 主动扫码方法
  static scanQRCode(success, fail = () => {}) {
    try {
      window.test.scanQRCode();
      window.scanCode = res => {
        success(res);
      };
      return true;
    } catch (error) {
      fail(error);
      console.warn("调取主动扫码方法失败");
      return false;
    }
  }

  // 取消扫码监听扫码方法
  static clearScanCode(fail = () => {}) {
    try {
      window.scanCode = () => {};
      return true;
    } catch (error) {
      fail(error);
      return false;
    }
  }

  // 读取身份证 信息
  static readIdCard(success = () => {}, fail = () => {}) {
    try {
      window.readIdCard = res => {
        success(res);
      };
      return true;
    } catch (error) {
      fail(error);
      return false;
    }
  }

  // 取消身份证读取监听方法
  static clearReadIdCard(fail = () => {}) {
    try {
      window.readIdCard = () => {};
      return true;
    } catch (error) {
      fail(error);
      return false;
    }
  }
}

export default HardwareService;
