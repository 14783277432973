<!-- 窗口端设置：T2副屏页面 -->
<template>
  <div class="second-screen flex-between">
    <img class="img" v-if="imgUrl" :src="imgUrl" alt="副屏图片" />
    <img
      v-if="!imgUrl"
      class="img"
      src="../assets/images/fp.jpg"
      alt="副屏图片"
    />
    <div class="cart-wrap" v-show="show">
      <ShoppingCart fullheight></ShoppingCart>
    </div>
  </div>
</template>
<script>
import ShoppingCart from "@/components/ShoppingCart";

export default {
  name: "SecondScreen",
  components: {
    ShoppingCart
  },
  props: {},
  data() {
    return {
      show: true,
      imgUrl: ""
    };
  },
  created() {
    this.imgUrl = localStorage.getItem("T2backgroundImg") || "";

    // 监听storage事件,用于T2副屏购物车组件实现数据实时更新
    window.addEventListener("storage", this.storageFn);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.storageFn);
  },
  methods: {
    storageFn(e) {
      // 副屏背景图
      if (e.key == "T2backgroundImg" && e.newValue) {
        this.imgUrl = e.newValue;
      }
      // 是否显示购物车组件
      if (e.newValue == "true" && e.key == "showcartT2") {
        this.show = true;
      } else if (e.newValue == "false" && e.key == "showcartT2") {
        // 注意e.newValue有可能是购物车数据
        this.show = false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.second-screen {
  height: 100%;
  .img {
    height: 100%;
    width: 100%;
  }
  .cart-wrap {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}
</style>
