/**
 * @name: 餐饮路由
 */

export default [
  {
    path: "/cateringNew",
    component: () =>
      import(
        /* webpackChunkName: "chuck-catering" */ "../views/catering/sell/CateringIndex.vue"
      ),
    children: [
      {
        //桌台点餐
        path: "",
        name: "TableOrderNew",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/sell/TableOrder.vue"
          )
      },
      {
        //快速点餐
        path: "/cateringNew/menuList",
        name: "MenuListNew",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/menuList.vue"
          )
      },
      {
        //取号排队
        path: "/cateringNew/TakeNumber",
        name: "TakeNumberNew",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/number/TakeNumber.vue"
          )
      },
      {
        //叫号
        path: "/cateringNew/CallNumber",
        name: "CallNumberNew",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/number/CallNumber.vue"
          )
      },
      {
        // 取餐
        path: "/cateringNew/takeFood",
        name: "TakeFoodNew",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/take/TakeFood.vue"
          )
      },
      {
        // 取餐列表
        path: "/cateringNew/TakeFoodList",
        name: "TakeFoodListNew",
        component: () =>
          import(
            /* webpackChunkName: "chuck-catering" */ "../views/catering/take/TakeFoodList.vue"
          )
      }
    ]
  }
];
