export default {
  //购物车列表,订单总价也是在这里面取
  cartList: {},
  //出游人信息
  visitorsList: [],
  //购票人信息
  purchaserMemberInfo: {},
  // 支付重构：订单折扣优惠
  discountTextList: [],
  // 支付重构：积分抵扣优惠
  integralTextList: [],
  /**
   * 支付成功后，需要告知支付处理的配置：
   * 1、logoutMember：支付完成，是否需要退出会员登录
   * 2、backUrl：支付完后，回跳的页面url
   */
  endOfPayConfig: {
    logoutMember: true,
    backUrl: "/home"
  }
};
