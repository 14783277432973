<!--
  线下支付
-->
<template>
  <div class="offline-pay">
    <!-- 非抹零方式，且开启了组合支付后的键盘 -->
    <KeyboardPro
      :id-card="false"
      v-show="propParam.combinedPayment"
      width="520"
      type="2"
      v-model="payMoney"
      @ok="doPay"
    >
      <template v-slot:input="{ confirm }">
        <a-input
          placeholder="请输入金额"
          v-model="payMoney"
          allow-clear
          size="large"
          class="prefix-input mt-1x"
          ref="scanRef"
          @pressEnter="confirm"
        >
          <span slot="prefix" class="primary">收款金额</span>
          <i
            style="color:#FFA735;"
            class="iconfont iconrenmingbiriyuan font-xxxl"
            slot="suffix"
          ></i>
        </a-input>
      </template>
    </KeyboardPro>

    <!-- 开启了抹零支付 -->
    <keyboard-pro
      v-if="payType"
      type="2"
      v-model="numbers"
      width="520"
      @ok="currentPay"
      :id-card="false"
      style="margin-top:10px;"
    >
      <template v-slot:input="{ confirm }">
        <a-input
          placeholder="请输入金额"
          v-model="numbers"
          allow-clear
          class="prefix-input"
          size="large"
          ref="scanRef"
          @pressEnter="confirm"
        >
          <span slot="prefix" class="primary">实收金额</span>
          <span slot="suffix" class="primary">元</span>
        </a-input>
        <div class="flex-between mt-1x">
          <div>
            快捷输入：
            <a-button
              type="primary"
              class="mr-x"
              v-for="item in fastList"
              :key="item"
              @click="inputFast(item)"
            >
              {{ item | money }}
            </a-button>
          </div>
          <p>
            找零：<span class="red font-l">{{ returnMoney }}</span>
          </p>
        </div>
      </template>
    </keyboard-pro>

    <a-button
      class="offline-pay-btn"
      v-show="!propParam.combinedPayment && !propParam.subPayWaySetting"
      type="primary"
      @click="offlinePaySure"
    >
      确认收款
    </a-button>
  </div>
</template>
<script>
import KeyboardPro from "@/components/common/KeyboardPro";
import { payOfflineOrder } from "../../api/order";
import { myMixin } from "./mixin";
import { fix2Reg, moneyFormat } from "../../utils/global";

const fastMoney = money => {
  let output = [];
  if (money < 1) {
    output = [1];
  } else if (money <= 5) {
    output = [5, 10, 20];
  } else if (money <= 10) {
    output = [10, 20, 50];
  } else if (money <= 20) {
    output = [20, 50, 100];
  } else if (money <= 50) {
    output = [50, 100];
  } else if (money <= 100) {
    output = [100];
  } else {
    output = [Math.ceil(money / 100) * 100];
  }
  return output;
};

export default {
  name: "OfflinePay",
  components: { KeyboardPro },
  props: {
    propParam: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mixins: [myMixin],
  data() {
    return {
      payMoney: "",
      numbers: "",
      fastList: []
    };
  },
  computed: {
    // 找零
    returnMoney() {
      const total =
        this.propParam.zeroRadio == 1
          ? this.propParam.totalFee
          : this.propParam.zeroAmount * 100;
      let out = (this.numbers * 100 - total).toFixed(2);
      if (out > 0) {
        return moneyFormat(out);
      } else {
        return 0;
      }
    },
    // 当前开启了何种抹零方式
    payType() {
      const obj = JSON.parse(this.propParam.subPayWaySetting);
      if (obj && obj.type) {
        return obj.type;
      } else {
        return "";
      }
    }
  },
  mounted() {
    // 算出快捷输入的价格
    this.fastList = fastMoney(this.propParam.totalFee);
  },
  methods: {
    // 组合支付
    doPay(val) {
      console.log(val, "--*----");
    },
    // 配置了抹零等配置的方式
    currentPay() {
      if (!this.numbers) {
        this.$message.warning("请输入实收金额");
        return;
      } else if (!fix2Reg.test(this.numbers)) {
        this.$message.warning("请输入正确的金额");
        return;
      } else if (this.numbers > 999999.99) {
        this.$message.warning("输入的金额不能大于999999.99");
        return;
      }
      const total =
        this.propParam.zeroRadio == 1
          ? this.propParam.totalFee
          : this.propParam.zeroAmount * 100;
      if (Number(this.numbers * 100) < Number(total)) {
        this.$message.warning("实收金额少于应付金额");
        return false;
      }
      this.offLinePay();
    },
    // 非组合支付：且不需要抹零的线下支付方式
    offlinePaySure() {
      this.offLinePay();
    },
    // 线下支付接口
    offLinePay() {
      payOfflineOrder({
        orderId: this.propParam.orderNumber,
        payWayCode: this.propParam.subPayModeId, // 线下支付传subPayModeId,其他支付传subPayModeCode
        payWayName: this.propParam.subPayModeName, // 支付方式名称
        payFlowNo: "", // 支付流水号
        payChannelCode: "", // 支付通道code
        payChannelName: "", // 支付通道名称
        ignoreFee:
          this.propParam.zeroRadio == 2 ? this.propParam.zeroAmount * 100 : "" // 抹零金额
      })
        .then(() => {
          this.paySuccess();
        })
        .catch(() => {});
    },
    inputFast(moeny) {
      this.numbers = moeny / 100;
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ant-input.ant-input-lg {
  font-size: 18px;
}
.offline-pay-btn {
  width: 380px;
  height: 60px;
  background: #5396fb;
  box-shadow: 0px 5px 10px 0px rgba(83, 150, 251, 0.64);
  border-radius: 30px;
  margin: 40px 0 0 60px;
}
</style>
