<!-- 
确定订单页面：进度条
-->
<template>
  <div class="progress-bar flex-center align-center">
    <div
      class="circular-wrap flex-start white"
      v-for="(item, index) in stepList"
      :key="index"
    >
      <div class="flex-col flex-between align-center">
        <span
          class="circular mb-x"
          :class="{ 'cur-step': curStep == index + 1 }"
        >
          {{ index + 1 }}
        </span>
        <span v-if="!notText">{{ item }}</span>
      </div>
      <span class="mh-1x mt-1x" v-if="index < processText.length - 1">
        - - - - - - - - - - - - - - - - - - - - -
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgressBar",
  components: {},
  props: {
    processText: {
      type: Array,
      default() {
        return ["确认订单", "支付", "订单完成"];
      }
    },
    // 当前进度，如处于第一步，传1
    curStep: {
      type: Number,
      required: true,
      default: 1
    },
    // 进度形式：不需要文字（只留1、2、3）
    notText: {
      type: Boolean,
      default: false
    },
    // 长度值（当使用不需要文字形式时，必传该属性）
    stepLen: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      stepList: []
    };
  },
  created() {
    if (!this.notText) {
      this.stepList = this.processText;
    } else {
      const len = this.stepLen;
      for (let i = 0; i < len; i++) {
        this.stepList.push(i);
      }
    }
  },
  methods: {}
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.progress-bar {
  width: 100%;
  height: 90px;
  background: url("../../assets/images/progress-bar.png") no-repeat center
    center;
  background-size: cover;
  .circular {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #fff;
    text-align: center;
    line-height: 36px;
    font-size: 22px;
    color: #fff;
    &.cur-step {
      color: @primary;
      background: #fff;
    }
  }
}
</style>
