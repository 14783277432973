<!--
  扫码支付
-->
<template>
  <KeyboardPro
    width="520"
    type="2"
    @trigger="keyBoardInput"
    @keyBack="keyBack"
    :id-card="false"
    @ok="doPay"
  >
    <template v-slot:input="{ confirm }">
      <a-input
        auto-focus
        placeholder="直接扫码或手动输入付款码"
        v-model="paymentCode"
        allow-clear
        size="large"
        class="prefix-input mt-1x"
        ref="scanRef"
        @pressEnter="confirm"
        @focus="codeFocus"
      >
        <span slot="prefix" class="primary">付款码</span>
        <a-icon type="scan" slot="suffix" class="primary" />
      </a-input>
    </template>
    <a-input
      v-show="propParam.combinedPayment"
      slot="midSlot"
      placeholder="本地收款金额，不填写默认应付金额"
      allow-clear
      size="large"
      style="margin-top: 13px;"
      class="prefix-input"
      v-model="money"
      @focus="moneyFocus"
    >
      <span slot="prefix" class="primary">收款金额</span>
      <i
        style="color:#FFA735;"
        class="iconfont iconrenmingbiriyuan font-xxxl"
        slot="suffix"
      ></i>
    </a-input>
  </KeyboardPro>
</template>
<script>
import KeyboardPro from "@/components/common/KeyboardPro";
import {
  tradePaymentInfoMicropayV2,
  tradePaymentInfoStatus
} from "@/api/order";
import { myMixin } from "./mixin";

export default {
  name: "newScan",
  components: { KeyboardPro },
  props: {
    propParam: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mixins: [myMixin],
  data() {
    return {
      paymentCode: "", // 付款码
      money: "", // 收款金额
      isFocus: false,
      loading: false, // 是否支付中
      loopTimer: null // 轮询支付定时器
    };
  },
  computed: {},
  created() {
    try {
      window.test.optScan("1"); //（传参数为1那么回调的js方法是payScan，0是scanCode方法）
      window.payScan = this.payScan; // 获取付款码
      // window.test.readEwm();
    } catch (error) {
      console.log("created：扫码支付，硬件启用失败：", error);
    }
  },
  beforeDestroy() {
    clearTimeout(this.loopTimer);
    this.loopTimer = null;
    window.payScan = null;
    try {
      window.test.optScan("0");
    } catch (error) {
      console.log("destroyed：扫码支付，硬件启用失败：", error);
    }
  },
  methods: {
    // 扫码直接支付
    payScan(res) {
      if (!this.loading) {
        // 先清空再赋值，避免扫码太快
        this.paymentCode = "";
        this.paymentCode = res;
      }
      this.doPay();
    },
    // 点击键盘确定按钮支付
    doPay() {
      if (!this.paymentCode) {
        this.$message.warning("请先输入付款码");
        this.$refs.scanRef.focus();
        return;
      } else if (!/^\d{16,24}$/gi.test(this.paymentCode)) {
        this.paySpeak("付款码有误，请重新扫码");
        this.$message.warning("付款码有误，请重新扫码");
        this.resetData();
        this.$refs.scanRef.focus();
        return;
      }
      if (this.loading) return;
      this.loading = true;
      const data = {
        orderNo: this.propParam.orderNumber,
        authCode: this.paymentCode,
        productName: ""
      };
      this.$store.commit("common/updateLoading", true);
      tradePaymentInfoMicropayV2(data, { loading: false })
        .then(res => {
          if (res === "PAYING" || res === "NOT_PAY") {
            this.getStatusLoop();
          }
          // 支付失败
          else if (res === "PAY_FAIL") {
            this.paySpeak("支付失败，请重新支付");
            this.$message.error("支付失败，请重新支付");
            this.resetData();
          }
          // 支付成功
          else {
            this.paySuccess();
            this.resetData();
          }
          // // 支付超时or订单已经失效（30分钟未支付失效）
          // // 这种场景，已经走接口的catch了，不会在这边执行
          // if (res == "CANCEL") {
          //   console.log("订单已经失效，请重新下单+++++");
          //   return;
          // }
        })
        .catch(() => {
          this.resetData();
        });
    },
    // 轮询支付状态
    getStatusLoop() {
      if (this.loopTimer) {
        clearTimeout(this.loopTimer);
      }
      this.loopTimer = setTimeout(() => {
        tradePaymentInfoStatus(
          { orderNo: this.propParam.orderNumber, time: new Date().getTime() },
          { loading: false }
        )
          .then(res => {
            if (res === "PAYING" || res === "NOT_PAY") {
              this.$message.info("等待用户支付中...");
              this.getStatusLoop();
            }
            // 支付失败
            else if (res === "PAY_FAIL") {
              this.paySpeak("支付失败，请重新支付");
              this.$message.error("支付失败，请重新支付");
              this.resetData();
            }
            // 支付成功
            else {
              this.paySuccess();
              this.resetData();
            }
          })
          .catch(() => {
            this.resetData();
          });
      }, 2000);
    },
    // 键盘输入
    keyBoardInput(val) {
      // 输入的内容放到 付款码
      if (!this.isFocus) {
        let str = this.paymentCode;
        str += String(val);
        this.paymentCode = str;
      } else {
        // 输入的内容放到 收款金额
        let str = this.money;
        str += String(val);
        this.money = str;
      }
    },
    // 键盘退格
    keyBack() {
      if (!this.isFocus && this.paymentCode) {
        this.paymentCode = this.paymentCode.substr(
          0,
          this.paymentCode.length - 1
        );
      } else if (this.isFocus && this.money) {
        this.money = this.money.substr(0, this.money.length - 1);
      }
    },
    codeFocus() {
      this.isFocus = false;
    },
    moneyFocus() {
      this.isFocus = true;
    },
    resetData() {
      clearTimeout(this.loopTimer);
      this.loopTimer = null;
      this.paymentCode = "";
      this.loading = false;
      this.$store.commit("common/updateLoading", false);
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ant-input.ant-input-lg {
  font-size: 18px;
}
</style>
