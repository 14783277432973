<template>
  <div>
    <header
      class="header flex-between align-center"
      :class="{ 'hide-menu': !showMenu }"
    >
      <!-- 左边 -->
      <top-nav></top-nav>
      <!-- 右边 -->
      <div class="right-side flex-shrink">
        <div class="item pointer" @click="lockScreen">
          <i class="iconfont iconlock mr-x"></i>
          <span>锁屏</span>
        </div>
        <div class="item pointer" @click="visibleFormSetting = true">
          <i class="iconfont iconshezhi mr-x"></i>
          <span>设置</span>
        </div>
        <a-dropdown class="item pointer" :trigger="['click']">
          <div class="flex-start align-center">
            <i class="iconfont flex-shrink"></i>
            <p class="mh-x text-1 name">
              {{ user.userName }} {{ user.phone | safeShow }}
            </p>
            <a-icon type="down" style="font-size: 16px" class="flex-shrink" />
          </div>
          <a-menu slot="overlay" @click="userClick">
            <a-menu-item :key="1">
              <a-icon type="swap" />
              切换景区</a-menu-item
            >
            <a-menu-divider />
            <a-menu-item :key="2">
              <a-icon type="edit" />
              修改密码</a-menu-item
            >
            <a-menu-divider />
            <a-menu-item :key="3">
              <a-icon type="logout" />
              退出登录</a-menu-item
            >
            <a-menu-divider />
            <a-menu-item :key="4">
              <i class="iconfont icondengji font-s mr-1x"></i>
              <span>{{ newVersion ? "回到旧版" : "体验新版" }}</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <!-- 锁屏弹窗 -->
      <div class="lock-screen" v-show="isLock">
        <div class="iconfont iconlock primary"></div>
        <div class="mt-3x user">
          <span>{{ user.userName }}</span
          ><span class="ml-2x">{{ user.account }}</span>
        </div>
        <div class="password">
          <a-input
            prefix="密码"
            type="password"
            placeholder="请输入解锁密码"
            v-model="password"
            @pressEnter="unlock"
            size="large"
            allow-clear
          >
            <span
              class="iconfont iconjinru primary font-xxl"
              slot="suffix"
              @click="unlock"
            ></span>
          </a-input>
        </div>
      </div>
    </header>
    <!-- 设置 -->
    <a-drawer
      title="设置"
      placement="right"
      :width="400"
      :closable="false"
      :visible="visibleFormSetting"
      @close="visibleFormSetting = false"
    >
      <a-form-model>
        <a-form-model-item
          class="form-model-item bg-gray-dark"
          label="硬件信息"
          :colon="false"
          v-if="currentTopMenuKey !== 'order'"
        >
          <div class="content flex-between align-center">
            <span>硬件设置</span
            ><a-button type="link" @click="openHardwareSetting">设置</a-button>
          </div>
        </a-form-model-item>
        <!--快速下单、票务展示-->
        <a-form-model-item
          class="form-model-item bg-gray-dark"
          label="页面布局"
          :colon="false"
          v-if="
            currentTopMenuKey === 'homeQuickPlaceOrder' ||
              currentTopMenuKey === 'appHome' ||
              currentTopMenuKey === 'appNewHome' ||
              currentTopMenuKey === 'appPlayNew' ||
              currentTopMenuKey === 'appCombinationNew' ||
              currentTopMenuKey === 'appRetailNew'
          "
        >
          <!--新版下单才有的配置-->
          <template v-if="newVersion">
            <div class="content flex-between align-center mb-2x">
              <span>团体下单展示</span>
              <a-form-model-item class="m-0">
                <a-switch
                  :checked="getSettings.IsShowGroupOrder"
                  @change="changeIsShowoLayout('IsShowGroupOrder')"
                />
              </a-form-model-item>
            </div>
            <div class="content flex-between align-center mb-2x">
              <span>产品分类展示</span>
              <a-form-model-item class="m-0">
                <a-switch
                  :checked="getSettings.IsShowProductClass"
                  @change="changeIsShowoLayout('IsShowProductClass')"
                />
              </a-form-model-item>
            </div>
            <!-- <div class="content flex-between align-center mb-2x">
            <span>购物车展示</span>
            <a-form-model-item class="m-0">
              <a-switch
                :checked="getSettings.IsShowCart"
                @change="changeIsShowoLayout('IsShowCart')"
              />
            </a-form-model-item>
          </div> -->
            <!-- <div class="content flex-between align-center mb-2x">
            <span>子景区打开</span>
            <a-form-model-item class="m-0">
              <a-switch
                :checked="getSettings.IsShowSubScenic"
                @change="changeIsShowoLayout('IsShowSubScenic')"
              />
            </a-form-model-item>
          </div> -->
            <div class="content flex-between align-center mb-2x">
              <span>日期打开</span>
              <a-form-model-item class="m-0" prop="product">
                <a-switch
                  :checked="getSettings.IsShowDate"
                  @change="changeIsShowoLayout('IsShowDate')"
                />
              </a-form-model-item>
            </div>
            <div class="content flex-between align-center mb-2x">
              <span>销售记录打开</span>
              <a-form-model-item class="m-0">
                <a-switch
                  :checked="getSettings.IsShowSellRecord"
                  @change="changeIsShowoLayout('IsShowSellRecord')"
                />
              </a-form-model-item>
            </div>
            <!-- <div class="content flex-between align-center mb-2x">
              <span>极速下单</span>
              <a-form-model-item class="m-0">
                <a-switch
                  :checked="getSettings.IsShowSpeedOrder"
                  @change="changeIsShowoLayout('IsShowSpeedOrder')"
                />
              </a-form-model-item>
            </div> -->
            <div class="content flex-between align-center mb-2x">
              <span>显示产品图片</span>
              <a-form-model-item class="m-0">
                <a-switch
                  :checked="getSettings.IsShowProductImg"
                  @change="changeIsShowoLayout('IsShowProductImg')"
                />
              </a-form-model-item>
            </div>
          </template>
          <div class="content flex-between align-center mb-2x" v-else>
            <span>显示产品图片</span>
            <a-form-model-item class="m-0">
              <a-switch
                :checked="isShowProductImg"
                @change="changeIsShowItem('isShowProductImg')"
              />
            </a-form-model-item>
          </div>
          <div class="content flex-between align-center">
            <div>
              热销产品
              <a-popover trigger="click">
                <div class="gray" slot="content">
                  <p>设置该用户的热销产品；</p>
                  <p>热销产品设置后，只会体现到当前用户的售票界面；</p>
                  <p>把常用的产品标记出来，加快销售速度</p>
                </div>
                <a-icon type="question-circle" />
              </a-popover>
            </div>
            <a-button type="link" @click="visibleTransferProduct = true">
              设置
            </a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item
          class="form-model-item bg-gray-dark"
          label="零售产品展示"
          :colon="false"
          v-if="
            currentTopMenuKey !== 'appOrder' &&
              currentTopMenuKey === 'appRetail'
          "
        >
          <div class="content flex-between align-center mb-2x">
            <span>显示产品图片</span>
            <a-form-model-item class="m-0" prop="product">
              <a-switch
                :checked="isShowRetailImg"
                @change="changeIsShowItem('isShowRetailImg')"
              />
            </a-form-model-item>
          </div>
          <div class="content flex-between align-center mb-2x">
            <span>显示售罄商品</span>
            <a-form-model-item class="m-0" prop="product">
              <a-switch
                :checked="isShowSoldOutItems"
                @change="changeIsShowItem('isShowSoldOutItems')"
              />
            </a-form-model-item>
          </div>
        </a-form-model-item>
        <a-form-model-item
          class="form-model-item bg-gray-dark"
          label="设置"
          :colon="false"
          v-if="currentTopMenuKey !== 'appOrder'"
        >
          <div class="content flex-between align-center mb-2x">
            <span>收银语音播报</span>
            <a-form-model-item class="m-0" prop="voiceBroadcast">
              <a-switch
                :checked="voiceBroadcast"
                @change="changeIsShowItem('voiceBroadcast')"
              />
            </a-form-model-item>
          </div>
          <div class="content flex-between align-center mb-2x">
            <span>快捷键</span>
            <a-form-model-item class="m-0" prop="shortcutKey">
              <a-switch
                :checked="shortcutKey"
                @change="changeIsShowItem('shortcutKey')"
              />
            </a-form-model-item>
          </div>
          <div
            class="content flex-between align-center mb-2x"
            v-if="!newVersion"
          >
            <span>默认收银方式</span>
            <a-button type="link" @click="visibleFormPayType = true"
              >设置</a-button
            >
          </div>
          <div v-if="!isWindow" class="content flex-between align-center mb-2x">
            <div>
              副屏图片设置
              <a-popover trigger="click">
                <div class="gray" slot="content">
                  <p>用于设置收银机副屏的广告图片；</p>
                  <p>有副屏的设备设置后才会生效</p>
                </div>
                <a-icon type="question-circle" />
              </a-popover>
            </div>
            <a-button type="link" @click="showT2bg">
              设置
            </a-button>
          </div>
        </a-form-model-item>
        <a-form-model-item
          class="form-model-item bg-gray-dark"
          label="软件信息"
          :colon="false"
        >
          <div class="content flex-between align-center">
            <div>
              软件版本
              {{
                version.versionVOList ? version.versionVOList[0].version : "-"
              }}
              <img
                class="ml-1x"
                src="../../assets/images/icon_new.png"
                alt="icon_new"
              />
            </div>
            <a-button
              v-if="version.versionVOList && version.versionVOList.length"
              type="link"
              @click="visibleSoftwareInfo = true"
            >
              查看
            </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
    <!-- 热销产品 -->
    <a-modal
      v-model="visibleTransferProduct"
      title="热销产品"
      @ok="handleHotProductOk"
      :width="600"
    >
      <hotsale-product v-model="hotProduct"></hotsale-product>
    </a-modal>
    <!-- 默认收银方式 -->
    <a-modal
      v-model="visibleFormPayType"
      title="默认收银方式"
      :width="600"
      @ok="handHardwareInfo"
    >
      <a-form-model
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        label-align="left"
        :model="payForm"
        :rules="payFormRules"
      >
        <a-form-model-item label="支付方式" prop="payType">
          <a-select size="large" class="input" v-model="payForm.payType">
            <a-select-option
              v-for="item in payTypes"
              :key="item.payFormCategory"
              :value="item.payFormCategory"
            >
              {{ item.payFormCategory == 1 ? "扫码支付" : item.payFormName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 副屏图片设置 -->
    <a-modal
      v-model="visibleFormSecondaryScreen"
      title="副屏图片设置"
      :width="600"
      @ok="bgOk"
    >
      <a-form-model
        ref="formSecondaryScreen"
        :model="formSecondaryScreen"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        label-align="left"
      >
        <a-form-model-item label="副屏图片" prop="secondaryScreenUrl">
          <single-upload
            v-model="formSecondaryScreen.secondaryScreenUrl"
            :size="3 * 1024"
            :width-list="[1520, 624]"
            :height-list="[1080, 600]"
          >
            <template slot="tip">
              <p class="mb-1x">1.建议图片尺寸为624*600或1520*1080</p>
              <p>2.最大可上传3M</p>
            </template>
          </single-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 软件信息 -->
    <a-modal
      :body-style="{
        'max-height': '460px'
      }"
      v-model="visibleSoftwareInfo"
      title="功能介绍"
      :width="800"
    >
      <section
        class="software-info bg-gray-dark"
        v-for="(item, index) in version.versionVOList"
        :key="index"
      >
        <div class="flex-between mb-2x">
          <span>版本号：{{ item.version }}</span
          ><span>{{ item.createTime | date }}</span>
        </div>
        <ul class="gray">
          <li class="mb-1x">更新内容：</li>
          <li class="mb-1x">
            {{ item.versionRemark }}
          </li>
        </ul>
      </section>
      <div class="text-c" slot="footer">
        <a-button key="back" @click="visibleSoftwareInfo = false">
          关闭
        </a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TopNav from "./TopNav.vue";
import SingleUpload from "@/components/select/SingleUpload.vue";
import { unlockTheScreen, logout } from "../../api/user";
import HotsaleProduct from "./setting/hotsale-product.vue";
import { hotProduct } from "../../api/product";
import { iotClientInstallPackageVersionList } from "../../api/iot";
import { isAndroid, phoneFormat } from "../../utils/global";
import { merchantuthPayFormWayInfo } from "../../api/pay";
import moment from "moment";
export default {
  components: {
    TopNav,
    SingleUpload,
    HotsaleProduct
  },
  name: "TopBar",
  props: {
    retailMerchant: {
      type: Object,
      default: function() {
        return {};
      }
    },
    consumeItem: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      isWindow: false, // 是否为window安装包环境
      isLock: false, // 锁屏状态
      password: "", // 密码
      visibleTransferProduct: false, // 是否显示穿梭框：产品
      visibleFormSetting: false, // 是否显示表单：设置
      visibleFormPayType: false, // 是否显示表单：收银方式设置
      visibleFormSecondaryScreen: false, // 是否显示表单：副屏设置
      visibleSoftwareInfo: false, // 是否显示软件信息
      templateList: [], //门票模板列表
      hotProduct: [], //热销产品的key
      memoryShortcutKey: false, //记忆快捷键
      //支付方式
      payTypes: [],
      payForm: {
        payType: ""
      },
      payFormRules: {
        payType: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ]
      },
      // 表单：副屏图片
      formSecondaryScreen: {
        secondaryScreenUrl: "" // 副屏图片
      },
      // 软件版本信息
      version: {},
      date: moment(), // 时间
      newVersion: localStorage.newVersion === "true" // 是否使用新版窗口， 默认true
    };
  },
  created() {
    // 更新用户信息
    if (localStorage.getItem("token")) this.$store.dispatch("common/getUser");
    // 获取软件版本信息
    this.getVersion();
    this.checkWindow();
    // 获取支付方式
    this.getPayMode();
  },
  computed: {
    ...mapGetters({
      // 页面布局
      getSettings: "setting/getSettings",

      isShowProductImg: "setting/getisShowProductImg",
      isShowRetailImg: "setting/getisShowRetailImg",
      voiceBroadcast: "setting/getvoiceBroadcast",
      shortcutKey: "setting/getshortcutKey",
      isShowCommodityStocks: "setting/getisShowCommodityStocks",
      isShowSoldOutItems: "setting/getisShowSoldOutItems",
      accountTemplateId: "setting/getaccountTemplateId",
      ticketTemplateId: "setting/getticketTemplateId",
      equipmentType: "setting/getequipmentType",
      printerModel: "setting/getprinterModel",
      printerTemplate: "setting/getprinterTemplate",
      receiptType: "setting/getreceiptType",
      receiptTemplate: "setting/getreceiptTemplate",
      handOverTemplate: "setting/gethandOverTemplate",
      handOverType: "setting/gethandOverType",
      isPrintProject: "setting/getisPrintProject",
      isPrintReceipt: "setting/getisPrintReceipt"
      // ...
    }),
    // 头部激活的currentTopMenuKey
    currentTopMenuKey() {
      return this.$store.state.common.currentTopMenuKey;
    },
    // 用户信息
    user() {
      return this.$store.state.common.user;
    },
    // 展示菜单状态
    showMenu() {
      return this.$store.state.common.showMenu;
    }
  },
  filters: {
    safeShow: phoneFormat
  },
  methods: {
    // 禁止选中前面的时间
    disabledDate(current) {
      return current && current < moment().add(-1, "day");
    },

    getPayMode() {
      merchantuthPayFormWayInfo()
        .then(res => {
          this.payTypes = res || [];
          const arr = this.payTypes.map(item => item.payFormCategory);
          // isNaN:旧版存在storage的是字符串，避免新旧版本切换的问题
          if (
            !localStorage.getItem("payType") ||
            isNaN(Number(localStorage.getItem("payType")))
          ) {
            this.payForm.payType = res[0] && res[0].payFormCategory;
          } else {
            const num = Number(localStorage.getItem("payType"));
            // 处理第一次存储的抵扣为默认支付，但下次动态返回的数据没有抵扣支付了。默认取第一个数据为默认支付
            if (arr.includes(num)) {
              this.payForm.payType = num;
            } else {
              this.payForm.payType = arr[0];
            }
          }
          localStorage.setItem("payType", this.payForm.payType);
        })
        .catch(() => {});
    },
    // 显示副屏设置弹窗
    showT2bg() {
      if (localStorage.getItem("T2backgroundImg")) {
        this.formSecondaryScreen.secondaryScreenUrl = localStorage.getItem(
          "T2backgroundImg"
        );
      }
      this.visibleFormSecondaryScreen = true;
    },
    // 副屏弹窗确定按钮
    bgOk() {
      if (!this.formSecondaryScreen.secondaryScreenUrl) {
        this.$message.warning("请先上传图片");
        return;
      }
      localStorage.setItem(
        "T2backgroundImg",
        this.formSecondaryScreen.secondaryScreenUrl
      );
      this.visibleFormSecondaryScreen = false;
    },
    // 获取软件版本信息
    getVersion() {
      iotClientInstallPackageVersionList({
        packageType: isAndroid() ? 2 : 0, // 类型：0window客户端安装包 1window驱动安装包 2android客户端安装包
        applyPlatform: "", // 适用平台  畅游宝 1  游小铺 2
        id: "" // 安装包id
      })
        .then(res => {
          this.version = res || {};
        })
        .catch(() => {});
    },
    //选择是否显示
    changeIsShowItem(name) {
      let item = this[name];
      localStorage.setItem(name, !item);
      this.$store.commit(`setting/set${name}`, !item);
    },
    // 页面布局
    changeIsShowoLayout(name) {
      let item = this.getSettings[name];
      localStorage.setItem(`${this.currentTopMenuKey + name}`, !item);
      this.$store.commit(`setting/set${this.currentTopMenuKey + name}`, !item);
    },
    //选择硬件设置的下拉
    changeHardware(val, type) {
      this.$store.commit(`setting/set${type}`, val);
    },
    //硬件设备弹窗  -  点击确认
    handHardwareInfo() {
      localStorage.setItem("payType", this.payForm.payType);
      this.visibleFormPayType = false;
    },
    ...mapActions("common", ["closeShortcutKey"]),
    // 重置vueX状态
    resetVueX() {
      this.$store.commit("common/resetState");
      this.$store.commit("consume/resetState");
      this.$store.commit("retail/resetState");
      this.$store.commit("order/resetState");
      this.$store.commit("ticketSales/resetState");
      // this.$store.commit("setting/resetState");
      // this.$store.commit("system/resetState");
    },

    // 下拉点击
    userClick({ key }) {
      switch (key) {
        case 1:
          // 重置vueX状态
          this.resetVueX();
          //切换景区逻辑
          this.$router.push("/login/selectScenic");
          break;
        case 2:
          this.$router.push("/login/ChangePassword");
          break;
        case 3:
          this.$confirm({
            title: "提示",
            content: "确定要退出登录吗？",
            onOk: () => {
              logout()
                .then(() => {
                  this.$message.success("已退出登录");
                })
                .catch(() => {})
                .finally(() => {
                  localStorage.removeItem("token");
                  // 重置vueX状态
                  this.resetVueX();
                  this.$router.push(
                    `/login?redirect=${encodeURIComponent(
                      this.$route.fullPath
                    )}`
                  );
                });
            },
            onCancel() {}
          });
          break;
        default:
          this.$confirm({
            title: "提示",
            content: `确定要切换到${
              this.newVersion ? "旧版" : "新版"
            }吗？系统将会刷新！`,
            onOk: () => {
              this.$store.commit("common/setSaleMerchant", {
                merchantId: "",
                merchantName: ""
              });
              this.$store.commit("common/setCurrentTopMenuKey", "");
              this.$store.commit("common/setCurrentLeftMenuKey", "");
              localStorage.setItem("newVersion", (!this.newVersion).toString());
              this.$router.push("/");
              location.reload();
            },
            onCancel() {}
          });
      }
    },
    //记忆快捷键开关
    memory() {
      if (this.memoryShortcutKey) {
        this.changeIsShowItem("shortcutKey");
      }
    },
    // 锁屏
    lockScreen() {
      this.memoryShortcutKey = localStorage.getItem("shortcutKey") === "true"; //记忆快捷键状态
      this.memory();
      this.isLock = true;
    },
    // 解锁屏
    unlock() {
      unlockTheScreen({ password: this.password }).then(res => {
        if (res) {
          this.$message.success("欢迎回来");
          this.password = "";
          this.isLock = false;
          this.memory();
        } else {
          this.$message.error("密码错误");
        }
      });
    },
    // 刷新路由
    refresh() {
      this.$emit("refresh", true);
    },
    //处理点击确定热销产品
    handleHotProductOk() {
      hotProduct({
        productIdList: this.hotProduct
      })
        .then(() => {
          this.visibleTransferProduct = false;
        })
        .catch(() => {});
    },
    //打开硬件设置
    openHardwareSetting() {
      this.$parent.showSetting = true;
    },
    // 判断是否为windows系统下的安装包环境
    checkWindow() {
      const u = navigator.userAgent;
      this.isWindow = u.indexOf("WOW64") > -1 || u.indexOf("WOW") > -1;
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";

.header {
  /*position: relative;*/
  z-index: 500;
  height: 64px;
  line-height: 64px;
  align-items: center; /*垂直居中*/
  box-shadow: 3px 0 9px 0 rgba(172, 200, 219, 0.46);
  background: #fff;
  transition: all 0.3s ease-in-out;
  &.hide-menu {
    overflow: hidden;
    transform: translateY(-100px);
    height: 0;
  }
  /*右侧内容*/
  .right-side {
    display: flex;
    align-items: center; /*垂直居中*/
    justify-content: space-around;
    width: 380px;
    padding: 0 12px;
    font-size: 16px;
    background: #f7f7f7;
    color: #999;
    .name {
      max-width: 188px;
    }
    .item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 0 4px;
    }
  }
  /*锁屏*/
  .lock-screen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(220, 220, 220, 0.9);
    text-align: center;
    .iconlock {
      font-size: 64px;
      margin-top: 100px;
    }
    .user {
      font-size: 24px;
    }
    .password {
      width: 300px;
      margin: 16px auto 0;
      /deep/.ant-input {
        padding-left: 60px;
        vertical-align: middle;
      }
      /deep/.ant-input-prefix {
        vertical-align: middle;
      }
    }
  }
}

.input {
  width: 200px;
}

.form-model-item {
  padding: @2x @2x @2x;
  border-radius: @x;
  .content {
    padding: 0 @2x;
    background: #fff;
    border-radius: @x;
  }
}
.product-setting-name {
  width: 400px;
}
.software-info {
  padding: @2x;
  border-radius: @x;
}

.software-info:not(:last-child) {
  margin-bottom: @2x;
}
.modeScroll {
  height: 400px;
  overflow-y: scroll;
  .modeScrollLink {
    color: blue;
  }
}

/deep/.ant-modal-body {
  overflow-y: auto;
}

/*适配小屏*/
@media screen and (max-width: 1336px) {
  .header {
    .right-side {
      width: 350px;
    }
  }

  .top-nav .item {
    min-width: 60px;
  }
}
</style>
