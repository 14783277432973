<!--
  @name: 选择省市区组件,
  @author: 邓良友,
  @attr: {
    // 省市区id[9655,9656,9659]
    v-model: {
       type: Array,
       default: []
    },
    disabled: { // 禁用
      type: Boolean,
      default: false
    },
    // 显示省市区层级：默认值2（即：显示省市区三联），值为1：只显示省市，值为0：只显示省
    level: {
      type: Number,
      default: 2
    },
    // 能否清空
    clear: {
      type: Boolean,
      default: true
    }
  },
  @event: {
   change // 返回选中的省市区id [9655,9656,9659]
   selected // 返回 {path: "ids", pathLabels: "省市区名称"}
  }
-->
<template>
  <a-cascader
    ref="cascader"
    :options="options"
    :load-data="loadData"
    :value="region"
    :allow-clear="clear"
    :disabled="disabled"
    :placeholder="
      `请选择${level === 0 ? '省份' : level === 1 ? '省市' : '省市区'}`
    "
    change-on-select
    @change="change"
    @popupVisibleChange="focus"
    v-if="(hadEcho && isEdit) || !isEdit"
  ></a-cascader>
</template>

<script>
import { chinaRegion } from "../../api/setting";

export default {
  name: "SelectAddress",
  model: {
    prop: "region",
    event: "change"
  },
  props: {
    // 省市区id:v-model
    region: {
      type: Array,
      default() {
        return [];
      }
    },
    // 能否清空
    clear: {
      type: Boolean,
      default: true
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 层级：值2显示省市区三联；值1显示省市；值0只显示省)
    level: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      options: [], // 省市区数据
      selectedObj: {}, // 选中省市区的ids和名称
      count: 0, // 递归加载回显数据的计算器
      isEdit: false, // 是否回显状态
      hadEcho: false, // 是否已加载完所有的回显数据
      hasExecuted: false, // 是否已经执行过(会员编辑身份证读卡，会连续调用省市区2次)
      changeClick: false // 选择的时候，不执行watch region
    };
  },
  watch: {
    // 监听省市区ids
    region(newVal) {
      if (newVal.length && !this.changeClick) {
        this.count = 0; // 每次先重置，避免首次回显后，二次读身份证回显的数据BUG
        this.options = []; // 每次先重置，避免首次回显后，二次读身份证回显的数据BUG
        this.hasExecuted = false;
        this.getChregionList();
      }
    }
  },
  methods: {
    // 返回选中的省市区(path: "ids"和pathLabels: "省市区名称")
    change(value, selectedOptions) {
      this.changeClick = true;
      let selectedObj = {
          path: [],
          pathLabels: []
        },
        pathArr = [],
        pathLabelArr = [];

      selectedOptions.forEach(item => {
        pathArr.push(item.value);
        pathLabelArr.push(item.label);
      });
      selectedObj = { path: pathArr, pathLabels: pathLabelArr };
      this.selectedObj = selectedObj;

      this.$emit("change", value);
      this.selected();
    },

    // 向父组件发送选中的省市区(path: "ids"和pathLabels: "省市区名称")
    selected() {
      if (this.selectedObj) {
        // 返回regionId和省市区名称
        this.$emit("selected", {
          path: this.selectedObj.path,
          pathLabels: this.selectedObj.pathLabels
        });
      }
    },

    // 级联：显示/隐藏浮层的回调
    focus(val) {
      // 不是回显数据状态，点击时，初始化省数据
      if (val && !this.region.length) {
        // console.log(111);
        this.getChregionList();
      }
    },

    // 初始化省份数据
    getChregionList(id) {
      if (this.hasExecuted) {
        return;
      }
      this.hasExecuted = true;
      chinaRegion(
        {
          parentId: id || ""
        },
        { loading: false }
      )
        .then(res => {
          res.forEach(item => {
            this.options.push({
              value: item.regionId,
              label: item.regionName,
              isLeaf: false
            });
          });
          // 要回显时
          if (this.region.length) {
            this.loadCity();
          }
        })
        .catch(() => {});
    },

    // 回显的时候：加载“市”数据
    loadCity() {
      this.isEdit = true;
      this.options.forEach(item => {
        if (item.value === this.region[0]) {
          // 根据已选省，获取其他已选
          this.loadData([item], true);
        }
      });
    },

    // 加载数据
    loadData(selectedOptions, isEcho) {
      // 已选择的项
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      // 回显情况：每拉取一次数据，计算器+1
      isEcho && (this.count += 1);

      // 后台数据接口
      chinaRegion({
        parentId: targetOption.value || ""
      })
        .then(res => {
          targetOption.loading = false;
          targetOption.children = res.map(item => {
            return {
              value: item.regionId, // 全国统一的regionId
              label: item.regionName,
              // 不回显与回显，level少一层click点击
              isLeaf: !isEcho
                ? this.region.length === this.level
                : this.region.length - this.count === 1
            };
          });
          // 赋值最新的options数据
          this.options = [...this.options];

          // 回显的情况：递归加载数据
          if (isEcho && this.region.length - this.count > 1) {
            let arr = [];
            // “市”只有多个的情况：广东省-xx市
            if (targetOption.children.length > 1) {
              // 匹配出已选的：市
              targetOption.children.forEach(item => {
                if (item.value === this.region[this.count]) {
                  arr.push(item);
                }
              });
            } else {
              // “市”只有一个的情况：北京市-市辖区
              arr = targetOption.children;
            }
            this.loadData(arr, true);
          } else {
            // 所有要回显的数据加载完成，显示级联组件
            this.hadEcho = true;
            this.hasExecuted = false;
          }
        })
        .catch(() => {});
    }
  }
};
</script>
