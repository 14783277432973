/**
 * @name: 组合产品路由
 */

export default [
  {
    path: "/combination-product",
    // /* webpackChunkName: "chuck-combinationProduct" */ "../views/combination-product/CombinationProductIndex.vue"
    // /* webpackChunkName: "chuck-combinationProduct" */ "../views/combination-product/CombinationProductIndexTwo"
    component: () =>
      import(
        /* webpackChunkName: "chuck-combinationProduct" */ "../views/combination-product/TicketType"
      ),
    children: [
      // 销售
      {
        path: "",
        name: "SaleIndex",
        component: () =>
          import(
            /* webpackChunkName: "chuck-combinationProduct" */ "../views/combination-product/sell/SaleIndex.vue"
          )
      },
      // 门票
      {
        path: "/combination-product/ticket",
        name: "combinationProductTicket",
        component: () =>
          import(
            /* webpackChunkName: "chuck-combinationProduct" */ "../views/combination-product/TicketList.vue"
          )
      },
      //取票入口
      {
        path: "/combination-product/TakeCombinationTicket",
        name: "TakeCombinationTicket",
        component: () =>
          import(
            /* webpackChunkName: "chuck-combinationProduct" */ "../views/combination-product/TakeCombinationTicket.vue"
          )
      },
      //取票列表
      {
        path: "/combination-product/TakeCombinationTicketList",
        name: "TakeCombinationTicketList",
        component: () =>
          import(
            /* webpackChunkName: "chuck-combinationProduct" */ "../views/combination-product/TakeCombinationTicketList.vue"
          )
      }
    ]
  },
  {
    path: "/combination-product/sell/SellProduct",
    component: () =>
      import(
        /* webpackChunkName: "chuck-combinationProduct" */ "../views/combination-product/sell/SellProduct"
      )
  }
];
