/**
 *登录相关Login
 */
export default [
  // 登录首页
  {
    path: "/login",
    name: "login",
    // route level code-splitting 路由级代码分割
    // this generates a separate chunk (about.[hash].js) for this route 为该路由生成一个单独的块(about.[hash].js)
    // which is lazy-loaded when the route is visited. 当路由被访问时，它是惰性加载的。
    component: () =>
      import(/* webpackChunkName: "chuck-login" */ "../views/login/Login.vue")
  },
  // 选择平台
  {
    path: "/login/selectScenic",
    name: "loginSelectScenic",
    component: () =>
      import(
        /* webpackChunkName: "chuck-login" */ "../views/login/SelectScenic.vue"
      )
  },
  // 修改密码
  {
    path: "/login/ChangePassword",
    name: "loginChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "chuck-login" */ "../views/login/ChangePassword.vue"
      )
  },
  // 重置密码
  {
    path: "/login/forgetPassword",
    name: "forgetPassword",
    component: () =>
      import(
        /* webpackChunkName: "chuck-login" */ "../views/login/forgetPassword.vue"
      )
  }
];
