/**
 * MS-产品中心接口 3.0
 */
import http from "../utils/fetch"; // 公共请求方法

const prefix = "/api/product"; // api前缀

// 【product096】获取分类列表-胡汉杰
export const productMerchantCategoryList = data => {
  return http.get(`${prefix}/product/merchant/category/list`, data);
};

// 【product105】获取分时预约库存信息-胡汉杰 http://192.168.1.50:40001/project/55/interface/api/3153
export const appointmentTimeRuleStock = data => {
  return http.get(`${prefix}/appointment/time/rule/stock`, data);
};

/*
 * 分时预约
 */
// 【product025】获取时间段列表-胡汉杰
export function appointmentTimeList() {
  return http.get(`${prefix}/appointment/time/list`);
}

// 获取分时预约库存
export function appointmentStockDate(data) {
  return http.get(`${prefix}/appointment/stock/date`, data);
}

// 获取景区无产品收银产品-胡汉杰
export function skuNoProduct(data) {
  return http.get(`${prefix}/sku/no_product`, data);
}
// 获取分时预约规则详情
export function getAppointmentTimeRule(data) {
  return http.get(`${prefix}/appointment/time/rule`, data);
}
/**
 * MS-产品中心接口 2.0
 */

/**项目类型接口**/
// 新增项目类型-张胜飞
export const addPlayItemType = data => {
  return http.add(`${prefix}/add/play/item/type`, data);
};
// 删除项目类型-张胜飞
export const deletePlayItemType = data => {
  return http.delete(`${prefix}/delete/play/item/type`, data);
};
// 最大编号和排序-张胜飞
export const selectMaxPlayItemType = data => {
  return http.get(`${prefix}/select/max/play/item/type`, data);
};
// 修改项目类型-张胜飞
export const updatePlayItemType = data => {
  return http.put(`${prefix}/update/play/item/type`, data);
};
// 查询项目类型-张胜飞
export const listPlayItemType = data => {
  return http.get(`${prefix}/list/play/item/type`, data);
};

// 查询客户端项目列表-张胜飞
export const listPlayItemByOrg = data => {
  return http.get(`${prefix}/list/play/item/by/org`, data);
};

/**
 *景区平台-门票资产相关接口
 */
// 新增门票资产类型-刘院民
export const saveConsumeType = data => {
  return http.add(`${prefix}/save/consume_type`, data);
};
// 修改门票资产类型-刘院民
export const updateConsumeType = data => {
  return http.put(`${prefix}/update/consume_type`, data);
};
// 获取门票资产类型-刘院民
export const findConsumeType = data => {
  return http.get(`${prefix}/find/consume_type`, data);
};
// 获取门票资产列表(可用于项目分成方案消费顺序)-刘院民
export const findConsumeList = data => {
  return http.get(`${prefix}/find/consume_type/list`, data);
};
// 删除门票资产类型-刘院民
export const deleteConsumeType = data => {
  return http.delete(`${prefix}/delete/consume_type`, data);
};

/**
 *景区平台-资产套餐相关接口
 */
// 新增资产套餐-刘院民
export const saveConsumeTypePackage = data => {
  return http.add(`${prefix}/save/consume_type/package`, data);
};
// 修改门票资产套餐-刘院民
export const updateConsumeTypePackage = data => {
  return http.put(`${prefix}/update/consume_type/package`, data);
};
// 获取门票资产套餐-刘院民
export const findConsumeTypePackage = data => {
  return http.get(`${prefix}/find/consume_type/package`, data);
};
// 门票资产套餐列表-刘院民
export const findConsumeTypePackageList = data => {
  return http.get(`${prefix}/find/consume_type/packages/list`, data);
};
// 资产套餐批量起/停售-刘院民
export const updateBatchConsumeTypeStatus = data => {
  return http.put(`${prefix}/update/batch/consume_type/status`, data);
};
// 修改二销产品分类-刘院民
export const updateBatchConsumeTypeProductType = data => {
  return http.put(`${prefix}/update/batch/consume_type/product/type`, data);
};

/**
 *景区平台-项目扣费分成方案
 */
// 新增按次扣费分成方案-张胜飞
export const saveTimesDeduction = data => {
  return http.add(`${prefix}/save/times/deduction`, data);
};
// 新增计时扣费分成方案-张胜飞
export const saveTimeDeduction = data => {
  return http.add(`${prefix}/save/time/deduction`, data);
};
// 新增手工扣费分成方案-张胜飞
export const saveHandDeduction = data => {
  return http.add(`${prefix}/save/hand/deduction`, data);
};
// 设置默认分成方案-张胜飞
export const updateDefaultDeduction = data => {
  return http.put(`${prefix}/update/default/deduction`, data);
};
// 获取分成方案列表-张胜飞
export const listDeduction = data => {
  return http.get(`${prefix}/list/deduction`, data);
};
// 查询指定分成方案-张胜飞
export const findDeduction = data => {
  return http.get(`${prefix}/find/deduction`, data);
};
// 修改扣费分成方案-张胜飞
export const updateDeduction = data => {
  return http.put(`${prefix}/update/deduction`, data);
};

/**
 *二销产品分类
 */
// 编辑二销产品分类-许家炜
export const consumeEditProductType = data => {
  return http.put(`${prefix}/consume/edit/product/type`, data);
};
// 获取二销产品分类-许家炜
export const consumeList = data => {
  return http.get(`${prefix}/consume/list`, data);
};
// 删除二销产品分类-许家炜
export const consumeDeleteProductType = data => {
  return http.delete(`${prefix}/consume/delete/product/type`, data);
};
// 添加二销产品分类-许家炜
export const consumeAddProductType = data => {
  return http.add(`${prefix}/consume/add/product/type`, data);
};

/**
 *零售商品库接口
 */
// 获取零售商品库列表-许家炜
export const sellerGoodsList = data => {
  return http.get(`${prefix}/seller/goods/list`, data);
};
// 添加零售商品库信息(发布商品)-许家炜
export const sellerInsertInfo = data => {
  return http.add(`${prefix}/seller/insert/info`, data);
};
// 修改零售商品库信息-许家炜
export const sellerUpdateInfo = data => {
  return http.put(`${prefix}/seller/update/info`, data);
};
// 调整二销产品分类-许家炜
export const updateSellerGoodsId = data => {
  return http.put(`${prefix}/seller/product/type`, data);
};
// 根据ID查询零售商品库信息-许家炜
export const getSellerGoodsId = data => {
  return http.get(`${prefix}/seller/get/one`, data);
};

/**
 *零售商品(商户)接口
 */
// 获取零售商品(商户)列表-许家炜
export const sellerToGoodsList = data => {
  return http.get(`${prefix}/seller/to/goods/list`, data);
};
// 发布商品(新增零售商品信息)-许家炜
export const sellerToGoodAddInfo = data => {
  return http.add(`${prefix}/seller/to/good/add/info`, data);
};
// 根据id查询零售商品(商户)信息-许家炜
export const sellerGoodsOne = data => {
  return http.get(`${prefix}/seller/goods/one`, data);
};
// 根据商品条码查询商品信息-许家炜
export const sellerGoodsSn = data => {
  return http.get(`${prefix}/seller/goods/sn`, data);
};
// 修改零售商品信息-许家炜
export const sellerToGoodsUpdateInfo = data => {
  return http.put(`${prefix}/seller/to/goods/update/info`, data);
};
// 批量停售/启售-许家炜
export const SellerToGoodsStatus = data => {
  return http.put(`${prefix}/seller_to_goods/status`, data);
};

/**
 *门票产品类型
 */
// 获取当前最大序号-邓小春
export const typeGetMaxSort = data => {
  return http.get(`${prefix}/type/get/maxsort`, data);
};
// 获取产品分类列表-邓小春
export const typeList = data => {
  return http.get(`${prefix}/type/list`, data);
};
// 获取所有产品分类列表(不分页)-邓小春
export const typeFindAll = () => {
  return http.get(`${prefix}/type/find/all`);
};
// 添加产品分类-邓小春
export const typeAdd = data => {
  return http.add(`${prefix}/type/add`, data);
};
// 编辑产品分类-邓小春
export const typeEdit = data => {
  return http.put(`${prefix}/type/edit`, data);
};
// 获取分类详情-邓小春
export const typeFindOne = data => {
  return http.get(`${prefix}/type/findone`, data);
};
// 删除产品分类-邓小春
export const typeDelete = data => {
  return http.delete(`${prefix}/type/delete`, data);
};

/**
 *门票产品接口
 */
// 根据子产品类型查询产品列表
export const findSubProductList = data => {
  return http.get(`${prefix}/find/sub_product/list`, data);
};
// 保存产品可用项目分成方案-邓小春
export const pdproductPlayItemDeductionInfoSave = data => {
  return http.put(`${prefix}/pdproduct/playitem/deductioninfo/save`, data);
};
// 分页查询产品可用项目-邓小春
export const pdProductPlayItemList = data => {
  return http.get(`${prefix}/pdproduct/playitem/list`, data);
};

// 查看产品详情-邓小春
export const pdproductDetail = data => {
  return http.get(`${prefix}/pdproduct/detail`, data);
};
// 查询子产品类型树
export const findProductTree = data => {
  return http.get(`${prefix}/find/product/tree`, data);
};
// 产品列表分页查询-邓小春
export const pdproductList = data => {
  return http.get(`${prefix}/pdproduct/list`, data);
};
// 综合套票产品信息添加-邓小春
export const pdproductPackageSave = data => {
  return http.add(`${prefix}/pdproduct_package/save`, data);
};
// 综合套票产品信息修改-邓小春
export const pdproductPackageUpdate = data => {
  return http.put(`${prefix}/pdproduct_package/update`, data);
};
// 产品信息添加-邓小春
export const pdproductSave = data => {
  return http.add(`${prefix}/pdproduct/save`, data);
};
// 产品信息修改-邓小春
export const pdproductUpdate = data => {
  return http.put(`${prefix}/pdproduct/update`, data);
};

/**
 *项目接口
 */
// 新增项目信息-张胜飞
export const savePlayItem = data => {
  return http.add(`${prefix}/save/play/item`, data);
};
// 修改项目信息-张胜飞
export const updatePlayItem = data => {
  return http.put(`${prefix}/update/play/item`, data);
};
// 变更项目状态-张胜飞
export const updatePlayItemStatus = data => {
  return http.put(`${prefix}/update/play/item/status`, data);
};
// 获取项目列表信息-张胜飞
export const listPlayItem = data => {
  return http.get(`${prefix}/list/play/item`, data);
};
// 获取项目信息-张胜飞
export const findPlayItem = data => {
  return http.get(`${prefix}/find/play/item`, data);
};
// 获取当前景区项目列表-张胜飞
export const listPlayItemOrganization = data => {
  return http.get(`${prefix}/list/play/item/organization`, data);
};

/**
 *导入接口
 */
// 下载零售商品(商户)模板-许家炜
export const reportGoodsTemplate = () => {
  return http.get(`${prefix}/report/goods/template`);
};
// 导入零售商品(商户)信息-许家炜
export const reportUploadReleaseGoods = data => {
  return http.post(`${prefix}/report/upload/release/goods`, data);
};
// 下载零售商品库导入模板-许家炜
export const reportcommoditytemplate = data => {
  return http.get(`${prefix}/report/commodity/template`, data);
};
//  导入零售商品库信息-许家炜
export const reportUploadSellerGoodsInfo = data => {
  return http.post(`${prefix}/report/upload/seller/goods/info`, data);
};

/**
 *门票游玩日历
 */
// 获取游玩日历-胡汉杰
export const calendarList = data => {
  return http.get(`${prefix}/calendar/list`, data);
};
// 配置游玩日历-胡汉杰
export const calendarConfig = data => {
  return http.post(`${prefix}/calendar/config`, data);
};

/**
 *门票价格日历
 */
// 获取价格日历-胡汉杰
export const specialPriceList = data => {
  return http.get(`${prefix}/special/price/list`, data);
};
// 设置价格-胡汉杰
export const specialPriceConfig = data => {
  return http.post(`${prefix}/special/price/config`, data);
};
// 还原为默认值-胡汉杰
export const specialPriceRestDefault = data => {
  return http.post(`${prefix}/special/price/rest/default`, data);
};

/**
 *推荐加购接口
 */
// 查询推荐加购列表-张胜飞
export const listProductRecommmendPackage = data => {
  return http.get(`${prefix}/list/product/recommend/package`, data);
};
// 修改推荐产品-张胜飞
export const updateProductRecommendPackage = data => {
  return http.put(`${prefix}/update/product/recommend/package`, data);
};
// 添加推荐加购产品-张胜飞
export const saveProductRecommendPackage = data => {
  return http.post(`${prefix}/save/product/recommend/package`, data);
};

/**
 *项目套餐接口
 */
// 二销产品id查询游玩项目-刘院民
export const findPlayItemPackageByBaseId = data => {
  return http.get(`${prefix}/find/play/item/package/by/base_id`, data);
};
// 项目获取关联的套餐-刘院民
export const playItemItemPackageItemPackage = data => {
  return http.get(`${prefix}/playitem/package/item/package`, data);
};
// 项目套餐批量停售/起售-刘院民
export const playItemPackageStatus = data => {
  return http.put(`${prefix}/update/play_item/package/status`, data);
};
// 项目套餐-套餐列表 分页 -刘资涌
export const playItemPackageListPage = data => {
  return http.get(`${prefix}/playitem/package/list/page`, data);
};
// 可用项目--获取套餐关联的项目列表 -刘资涌
export const playItemPackageCanPlays = data => {
  return http.get(`${prefix}/playitem/package/canplays`, data);
};
// 项目套餐-根据id获取项目套餐信息 -刘资涌
export const playItemPackageOne = data => {
  return http.get(`${prefix}/playitem/package/one`, data);
};
// 批量设置分类-刘院民
export const packageProductType = data => {
  return http.put(`${prefix}/package/product/type`, data);
};
// 项目套餐-修改项目套餐 -刘资涌
export const playItempackageUpdate = data => {
  return http.put(`${prefix}/playitem/package/update`, data);
};
// 项目套餐-新增项目套餐 -刘资涌
export const playItemPackageSave = data => {
  return http.add(`${prefix}/playitem/package/save`, data);
};
// 批量修改项目使用次数-刘院民
export const playItemPackageTimes = data => {
  return http.put(`${prefix}/playitem/package/times`, data);
};
// 保存套餐关联项目-刘院民
export const playItemPackageCanPlaysSave = data => {
  return http.add(`${prefix}/playitem/package/canplays/save`, data);
};
//查询项目套餐列表-刘院民(适用门票充值)
export const playItemPackageAdaptTicket = data => {
  return http.get(`${prefix}/play/item/package/adapt/ticket`, data);
};
//各终端门票列表-邓小春
export const pdproductTerminalList = data => {
  return http.get(`${prefix}/pdproduct/terminal/list`, data);
};
// 分销商分组可用产品-张胜飞
export const findProductByGroupId = data => {
  return http.get(`${prefix}/find/product/by/group/id`, data);
};
// 窗口-商户收银-项目套餐-刘院民
export const consumeProductTypeTree = data => {
  return http.get(`${prefix}/consume/product/type/tree`, data);
};

/**
 *消费类型
 */
// 查询当前景区所有消费类型-张胜飞（游乐金、喂养金）
export const listConsumeType = data => {
  return http.get(`${prefix}/list/consume/type`, data);
};

/**
 * 热销产品
 */
//保存热销产品列表-胡汉杰
export const hotProduct = data => {
  return http.post(`${prefix}/hot/product`, data);
};

//获取热销产品列表-胡汉杰
export const hotProductList = data => {
  return http.get(`${prefix}/hot/product/list`, data);
};

// 【A02010】获取租赁押金产品-胡汉杰
export const skuLeaseDeposit = data => {
  return http.get(`${prefix}/sku/lease_deposit`, data);
};

//【product114】通过商户id获取项目列表接口
export const merchantProject = data => {
  return http.get(`${prefix}/merchant/project`, data);
};
//【product035】禁启用项目-胡汉杰
export const productSaleabl = data => {
  return http.put(`${prefix}/product/saleabl`, data);
};
// 餐饮
// 分页获取分类-胡汉杰  （获取桌台类型 type =15 ） http://www.docway.net/project/1dAc1O6WB4z/1dAgIbZVyOe
export const merchantCategoryPage = data => {
  return http.get(`${prefix}/product/merchant/category/page`, data);
};

//【ck002001】桌台类型列表（包括当前排队人数）- 许家炜        http://192.168.1.50:40001/project/41/interface/api/2505
export const tableTypeList = data => {
  return http.get(`${prefix}/table/type/list`, data);
};

//【ck002002】桌台取号- 许家炜      http://192.168.1.50:40001/project/41/interface/api/2493
export const tableNumberQueue = data => {
  return http.post(`${prefix}/take/number/queue`, data);
};

//【ck002003】桌台最近十条数据- 许家炜        http://192.168.1.50:40001/project/41/interface/api/2511
export const tableNumberList = data => {
  return http.get(`${prefix}/take/number/list`, data);
};

//【ck003002】获取当前桌台类型排队倒叙列表- 许家炜     http://192.168.1.50:40001/project/41/interface/api/2529
export const tableTypeRowList = data => {
  return http.get(`${prefix}/table/type/row/list`, data);
};

//【ck003003】叫号- 许家炜     http://192.168.1.50:40001/project/41/interface/api/2547
export const useQueueInfoCallNumber = data => {
  return http.put(`${prefix}/use/queue/info/call/number`, data);
};

//【ck003004】派座/过号- 许家炜     http://192.168.1.50:40001/project/41/interface/api/2550
export const allocationPassNumber = data => {
  return http.put(`${prefix}/allocation/pass/number`, data);
};

//【ck003005】叫号左侧列表数据信息  http://192.168.1.50:40001/project/41/interface/api/2652
export const callNumberCategoryPage = data => {
  return http.get(`${prefix}/call/number/category/page`, data);
};

//【ht00703】获取场次票详情  http://192.168.1.50:40001/project/41/interface/api/2655
export const productPlaceTicketInfo = data => {
  return http.get(`${prefix}/product/place_ticket/info`, data);
};
//预约留坐接口  http://192.168.1.50:40001/project/41/interface/api/3632
export const seatReservation = data => {
  return http.update(`${prefix}/seat/reservation`, data);
};
//查询预留座位（汉杰）  http://192.168.1.50:40001/project/41/interface/api/3652
export const seatBook = data => {
  return http.get(`${prefix}/seat/book`, data);
};
//取消预留坐位接口 http://192.168.1.50:40001/project/41/interface/api/3650
export const cancelSeatReservation = data => {
  return http.update(`${prefix}/cancel/seat/reservation`, data);
};
//获取所有子景区产品列表  http://192.168.1.50:40001/project/41/interface/api/3735
export const subscenicProducts = data => {
  return http.get(`${prefix}/subscenic/products`, data);
};
//产品信息 http://192.168.1.50:40001/project/55/interface/api/3178
export const spuInfo = data => {
  return http.get(`${prefix}/product/spu/info`, data);
};
//获取补差价产品 http://192.168.1.50:40001/project/41/interface/api/3773
export const skuDifference = data => {
  return http.get(`${prefix}/sku/difference`, data);
};
//获取选座列表接口 http://192.168.1.50:40001/project/41/interface/api/4480
export const placeSeatList = data => {
  return http.post(`${prefix}/place/seat/list`, data);
};
