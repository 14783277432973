import store from "../store";
export const basePath = "https://devops.cyb.52qmp.cn";
// export const platformPath = "http://cyb.52qmp.cn/platform";

// 电话包括座机正则
export const telReg = /(^\d{10,12}$)|((^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$))|(^[0-9]{3,4}[-][0-9]{7,8})/;

// 手机号正则匹配
export const phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;

// 保留2位小数的正则（适用于验证金额）
export const fix2Reg = /^(([1-9][0-9]*)|[0]|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;

// 正整数匹配(不包含0)
export const numberReg = /^[1-9]\d*$/;

// 0或正整数（含0）
export const integerReg = /^([1-9]\d*|[0]{1,1})$/;

// 匹配英文字母或数字
export const enOrNumberReg = /^[a-zA-Z0-9]+$/;

// 车牌号正则
export const licensePlate = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;

// 邮箱正则
export const regEmail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

// 验证车牌号
export const checkLicensePlate = (rule, value, callback) => {
  if (value && !licensePlate.test(value)) {
    callback(new Error("车牌号格式不对"));
  } else {
    callback();
  }
};

//  验证邮箱的正则表达式
export const checkEmail = (rule, value, callback) => {
  if (regEmail.test(value)) {
    // 合法的邮箱
    return callback();
  }
  callback(new Error("请输入合法的邮箱"));
};

// 验证座机和手机号
export const checkTel = (rule, value, callback) => {
  if (value && !telReg.test(value)) {
    callback(
      new Error(
        "座机号为10~12位数字（如020-8338888），手机号为11位数字，不包含空格和特殊字符"
      )
    );
  } else {
    callback();
  }
};

// 验证手机号
export const checkPhone = (rule, value, callback) => {
  if (value && !phoneReg.test(value)) {
    callback(new Error("手机号码为11位数字，不包含空格和特殊字符"));
  } else {
    callback();
  }
};

// 正向匹配合法字符-input
export const checkNormal = (rule, value, callback) => {
  let testReg = /^(?!_)(?!.*?_$)[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
  if (value !== "" && !testReg.test(value)) {
    callback(
      new Error("只能包含汉字、数字、字母和下划线，不能以下划线开头和结尾")
    );
  } else {
    callback();
  }
};

// 正向匹配合法字符-textarea(含常规标点符号)
export const checkText = (rule, value, callback) => {
  let testReg = /^[a-zA-Z0-9\u4e00-\u9fa5`~!@%^*()_\\/\-+=<>?:"{}|,.;'[\]·！￥¥（）—《》？：“”【】、；‘，。\n\s]+$/;
  if (!value || testReg.test(value)) {
    callback();
  } else {
    callback(new Error("不能以空格开头且不能包含表情符、$、&、#等特殊符号"));
  }
};

// 匹配数字，只能为0以上的自然数(包含小数)
export const checkNumber = (rule, value, callback) => {
  const NumReg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
  !NumReg.test(value) ? callback(new Error("请输入0以上的数字")) : callback();
};
// 匹配充值金额，0以上的，两位小数内的
export const RechargeMoney = (rule, value, callback) => {
  !fix2Reg.test(value)
    ? callback(new Error("请输入数字，最多保留两位小数"))
    : value > 99999999.99
    ? callback(new Error("输入的金额不能大于99999999.99"))
    : value <= 0
    ? callback(new Error("输入的金额不能小于或等于0"))
    : callback();
};

// 匹配金额，0以上的，两位小数内的
export const checkMoney = (rule, value, callback) => {
  !fix2Reg.test(value)
    ? callback(new Error("请输入数字，最多保留两位小数"))
    : value > 99999999.99
    ? callback(new Error("输入的金额不能大于99999999.99"))
    : callback();
};

// 匹配数字，只能为0或正整数（含0）
export const checkIntegerNumber = (rule, value, callback) => {
  !value || integerReg.test(value)
    ? callback()
    : callback(new Error("请输入0 或 正整数"));
};

// 匹配英文字母或数字
export const checkEnOrNumber = (rule, value, callback) => {
  if (value && !enOrNumberReg.test(value)) {
    callback(new Error("仅支持英文或数字的组合"));
  } else {
    callback();
  }
};

/**
 * @name: 日期时间格式化,
 * @author: 易远胜
 * @param: {Number,String} date-时间戳或字符串时间2020-12-12 12:12:12
 * @param: {String} format-YYYY-MM-DD、YYYY-MM、YYYY-MM-DD hh:mm:ss、YYYY-MM-DD hh:mm、hh:mm:ss、hh:mm 或week(星期几)-不区分大小写
 * @return {String} 时间 2020-12-12 12:12:12 默认返回YYYY-MM-DD
 * **/
export function dateFormat(date, format) {
  if (!date) {
    return "";
  }
  let dateReg = /^-?[0-9]\d*$/;
  if (dateReg.test(date)) {
    date = new Date(parseInt(date));
  } else if (typeof date === "string" && "Invalid Date" != new Date(date)) {
    date = new Date(Date.parse(date.replace(/-/g, "/")));
  } else {
    return date;
  }
  function two(val) {
    if (val < 10) val = "0" + val;
    return val;
  }
  let year = date.getFullYear();
  let month = two(date.getMonth() + 1);
  let day = two(date.getDate());
  let hour = two(date.getHours());
  let minute = two(date.getMinutes());
  let second = two(date.getSeconds());
  let week = date.getDay();

  let reg1 = /^YYYY-MM-DD$/gi;
  let reg2 = /^YYYY-MM$/gi;
  let reg3 = /^YYYY-MM-DD hh:mm:ss$/gi;
  let reg4 = /^YYYY-MM-DD hh:mm$/gi;
  let reg5 = /^hh:mm:ss$/gi;
  let reg6 = /^hh:mm$/gi;
  let reg7 = /^week$/gi;
  if (!format) return `${year}-${month}-${day}`;
  if (reg1.test(format)) {
    return `${year}-${month}-${day}`;
  } else if (reg2.test(format)) {
    return `${year}-${month}`;
  } else if (reg3.test(format)) {
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  } else if (reg4.test(format)) {
    return `${year}-${month}-${day} ${hour}:${minute}`;
  } else if (reg5.test(format)) {
    return `${hour}:${minute}:${second}`;
  } else if (reg6.test(format)) {
    return `${hour}:${minute}`;
  } else if (reg7.test(format)) {
    const weeks = {
      0: "星期天",
      1: "星期一",
      2: "星期二",
      3: "星期三",
      4: "星期四",
      5: "星期五",
      6: "星期六"
    };
    return `${weeks[week]}`;
  } else {
    return "";
  }
}

/**
 * @name: 手机号加****，安全展示,
 * @author: 易远胜
 * @param: [String, Number] value-需要处理的手机号
 * @return String 如 138****8591
 * **/
export const phoneFormat = value => {
  if (value && value.length === 11) {
    value = value.substr(0, 3) + "****" + value.substr(7);
  }
  return value;
};

/**
 * @name: 金钱格式化,
 * @author: 易远胜
 * @param: [String, Number] value-需要格式化的价格
 * @param: {String} unit-单位，默认¥
 * @return String 如 ¥ 888
 * **/
export const moneyFormat = (value, unit = "¥") => {
  let money = Number(value);
  if (isNaN(money)) return value;
  money = money / 100; // 统一的分转为元
  // 分开小数和整数
  let valueArray = money.toString().split(".");
  // 整数部分
  let intStr = valueArray[0].toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  // =2表示数据有小数位
  if (valueArray.length === 2) {
    let float = valueArray[1]; //小数部分
    if (float.length === 1) {
      // 补0,实际上用不着
      money = `${intStr}.${valueArray[1]}0`;
    } else {
      money = `${intStr}.${valueArray[1]}`;
    }
  } else {
    money = intStr + ".00";
  }
  return `${unit} ${money}`;
};

/**
 * @name: 验证身份证*
 * @author: 冯炎龙
 * @params: { Number } idcard 身份证号码
 * @return { String } ErrorMessage || ""
 * */
export function isIdcard(idcard) {
  if (!idcard) return "身份证号码为空";
  var Errors = new Array(
    "", // 验证通过!
    "身份证号码位数不对!",
    "身份证号码出生日期超出范围或含有非法字符!",
    "身份证号码校验错误!",
    "身份证地区非法!"
  );
  var area = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    83: "台湾", // 特殊身份证
    91: "国外"
  };

  var Y, JYM;
  var S, M, ereg;
  var idcard_array = new Array();
  idcard_array = idcard.split("");
  // 地区检验
  if (area[parseInt(idcard.substr(0, 2))] == null) return Errors[4];
  // 身份号码位数及格式检验
  switch (idcard.length) {
    // 18位身份号码检测
    case 18:
      if (
        parseInt(idcard.substr(6, 4)) % 4 == 0 ||
        (parseInt(idcard.substr(6, 4)) % 100 == 0 &&
          parseInt(idcard.substr(6, 4)) % 4 == 0)
      ) {
        ereg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/; //闰年出生日期的合法性正则表达式
      } else {
        ereg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/; //平年出生日期的合法性正则表达式
      }
      if (ereg.test(idcard)) {
        // 测试出生日期的合法性
        // 计算校验位
        S =
          (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7 +
          (parseInt(idcard_array[1]) + parseInt(idcard_array[11])) * 9 +
          (parseInt(idcard_array[2]) + parseInt(idcard_array[12])) * 10 +
          (parseInt(idcard_array[3]) + parseInt(idcard_array[13])) * 5 +
          (parseInt(idcard_array[4]) + parseInt(idcard_array[14])) * 8 +
          (parseInt(idcard_array[5]) + parseInt(idcard_array[15])) * 4 +
          (parseInt(idcard_array[6]) + parseInt(idcard_array[16])) * 2 +
          parseInt(idcard_array[7]) * 1 +
          parseInt(idcard_array[8]) * 6 +
          parseInt(idcard_array[9]) * 3;
        Y = S % 11;
        M = "F";
        JYM = "10X98765432";
        M = JYM.substr(Y, 1); // 判断校验位
        if (M == idcard_array[17]) return Errors[0];
        // 检测ID的校验位
        else return Errors[3];
      } else return Errors[2];
    // break;
    default:
      return Errors[1];
    // break;
  }
}

// 身份证的验证规则
export const checkIdCard = (rule, value, callback) => {
  if (value) {
    if (isIdcard(value)) {
      callback(new Error('身份证为18位数字(最后一位可以是"X")'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

//自动聚焦 传入 that = this 当前实例   ref= ref的name
export const changFouce = (that, ref) => {
  that.$nextTick(() => {
    that.$refs[ref] && that.$refs[ref].focus();
  });
};
export const outFouce = (that, ref) => {
  that.$nextTick(() => {
    that.$refs[ref] && that.$refs[ref].blur();
  });
};

//快捷键，绑定键盘事件
/**
 *
 * @param {enevt} e 原生的属性
 * @param {Boolean} isDefaultKeydown 是否阻止原生事件
 * @param {Function} callback 阻止后的事件回调
 * @param {string} type   retail=零售
 */
export const onkeydown = (e, callback, type) => {
  if (store.getters["system/getSYSTEM_INPUT_IS_DEFAULT_DOWN"]) return;
  // 键盘按下，处理相关事件
  var ev = window.event || e;
  var code = ev.keyCode || ev.which;
  let codeListF = [
    //F1-F7  + 回车
    112,
    113,
    114,
    115,
    116,
    117,
    118,
    13
  ];
  //键盘0-9事件
  let codeList = [
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105
  ];
  let arr = type === "retail" ? codeListF : [...codeList, ...codeListF];
  if (arr.some(item => item === code)) {
    if (ev.preventDefault) {
      ev.preventDefault();
    } else {
      ev.keyCode = 0;
      ev.returnValue = false;
    }
    callback(code);
  }
};

/**
 * @name 判断是否为Android端
 * @returns {Boolean} 返回布尔值，true为安卓端
 */
export function isAndroid() {
  const u = navigator.userAgent;
  const isAndroid =
    u.indexOf("Android") > -1 ||
    u.indexOf("Linux") > -1 ||
    u.indexOf("Adr") > -1;
  return isAndroid;
}

/**
 *@name: 把列表转换成树结构/级联
 *@date: 2021-08-27
 *@author: 易远胜
 *@params: { Array } list //需要转换的列表
 *@return: { Array } tree //转换后的列表（树结构/级联）
 */

export function listToTree(list) {
  // 先把列表分成两份，首级和其它级别
  const tree = []; // 一级
  const children = []; // 其它级别
  list.forEach(item => {
    if (item.parentId === 0) tree.push(item);
    else children.push(item);
  });

  classify(tree);
  // 从第一级逐级递归分类
  function classify(arr) {
    arr.forEach(item => {
      item.children = [];
      children.forEach(temp => {
        if (item.id === temp.parentId) {
          item.children.push(temp);
        }
      });
      // 如果有子级，则往下递归收集子集合
      if (item.children.length) classify(item.children);
      // 否则删除子级
      else delete item.children;
    });
  }
  return tree;
}
/**
 *@name: 订单详情---产品列表九大操作显示与否对照表
 *@date: 2022-03-30
 *@author: cjg 文档各业态功能清单 https://doc.weixin.qq.com/doc/w3_AC0AUwZcADsCip9Ju2lRs6yFw9h6W?scode=AD4AfAefAA8Q0di4RmAC0AUwZcADs
 *@params: { Array } status // 产品对应的状态
 *@return: { Array } orderBusinessType //产品的业务类型
 **/
// 业务类型： 1销售（20210701产品要求正常下单改为销售） 2自定义收款 3加购 4充值 5会员特权 6取票 7补办 8,"赠送" 9,"补差价" 10,"团体购票"
// 订单状态： //1未支付，2已取消，3已支付，4未激活，5未使用，6已使用，7待发货，8待收货，9已完成，10已过期，11已补办 12已退款 13 退款中 14退款审核中 15退款失败 16"部分退款" 17"强制退款" 18,"部分使用
export function checkAuthorityBtn(
  row,
  orderStatus = "status",
  orderBusinessType = "orderBusinessType"
) {
  // 以下订单状态全部禁用
  if (
    row[orderStatus] == 1 || // 未支付
    row[orderStatus] == 2 || // 已取消
    row[orderStatus] == 11 || // 已补办
    row[orderStatus] == 12 || // 已退款
    row[orderStatus] == 13 || // 退款中
    row[orderStatus] == 14 || // 退款审核中
    row[orderStatus] == 17 || // 强制退款
    row[orderStatus] == 18 // 部分使用
  ) {
    return [];
  }
  // 如果业务类型为会员特权，补办，补差价时全部禁用
  else if (
    row[orderBusinessType] == 5 || // 会员特权
    row[orderBusinessType] == 7 || // 补办
    row[orderBusinessType] == 9 // 补差价
  ) {
    return [];
  }
  // 如果未使用，有人脸，无分时预约
  else if (
    row[orderStatus] == 5 &&
    row.userInfoConvert &&
    row.userInfoConvert.faceId &&
    !row.appointmentPeriodStartTime &&
    !row.appointmentPeriodEndTime
  ) {
    // 并且业务类型为销售，加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return ["取票", "补录人脸", "补办", "核销", "退款", "强制核销"];
    } else if (row[orderBusinessType] == 4) {
      // 并且业务类型为充值（购买会员资产）类型，只有退款
      return ["退款"];
    } else {
      return [];
    }
  }
  // 如果未使用，有人脸，有分时预约
  else if (
    row[orderStatus] == 5 &&
    row.userInfoConvert &&
    row.userInfoConvert.faceId &&
    row.appointmentPeriodStartTime &&
    row.appointmentPeriodEndTime
  ) {
    // 并且业务类型为销售，加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return [
        "取票",
        "补录人脸",
        "补办",
        "修改预约",
        "核销",
        "退款",
        "强制核销"
      ];
    } else if (row[orderBusinessType] == 4) {
      // 并且业务类型为充值（购买会员资产）类型，只有退款
      return ["退款"];
    } else {
      return [];
    }
  }
  // 如果未使用，无人脸，无分时预约
  else if (
    row[orderStatus] == 5 &&
    (!row.userInfoConvert || !row.userInfoConvert.faceId) &&
    !row.appointmentPeriodStartTime &&
    !row.appointmentPeriodEndTime
  ) {
    // 并且业务类型为销售，加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return ["取票", "补办", "核销", "退款", "强制核销"];
    } else if (row[orderBusinessType] == 4) {
      // 并且业务类型为充值（购买会员资产）类型，只有退款
      return ["退款"];
    } else {
      return [];
    }
  }
  // 如果未使用，无人脸，有分时预约
  else if (
    row[orderStatus] == 5 &&
    (!row.userInfoConvert || !row.userInfoConvert.faceId) &&
    row.appointmentPeriodStartTime &&
    row.appointmentPeriodEndTime
  ) {
    // 并且业务类型为销售，加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return ["取票", "补办", "修改预约", "核销", "退款", "强制核销"];
    } else if (row[orderBusinessType] == 4) {
      // 并且业务类型为充值（购买会员资产）类型，只有退款
      return ["退款"];
    } else {
      return [];
    }
  }
  // 如果未激活，有人脸，有分时预约
  else if (
    row[orderStatus] == 4 &&
    row.userInfoConvert &&
    row.userInfoConvert.faceId &&
    row.appointmentPeriodStartTime &&
    row.appointmentPeriodEndTime
  ) {
    // 并且业务类型为销售，加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return [
        "取票",
        "补录人脸",
        "强制核销",
        "补办",
        "激活",
        "修改预约",
        "退款"
      ];
    } else {
      return [];
    }
  }
  // 如果未激活，有人脸，无分时预约
  else if (
    row[orderStatus] == 4 &&
    row.userInfoConvert &&
    row.userInfoConvert.faceId &&
    !row.appointmentPeriodStartTime &&
    !row.appointmentPeriodEndTime
  ) {
    // 并且业务类型为销售，加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return ["取票", "补录人脸", "强制核销", "补办", "激活", "退款"];
    } else {
      return [];
    }
  }
  // 如果未激活，无人脸，有分时预约
  else if (
    row[orderStatus] == 4 &&
    (!row.userInfoConvert || !row.userInfoConvert.faceId) &&
    row.appointmentPeriodStartTime &&
    row.appointmentPeriodEndTime
  ) {
    // 并且业务类型为销售，加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return ["取票", "强制核销", "补办", "激活", "修改预约", "退款"];
    } else {
      return [];
    }
  }
  // 如果未激活，无人脸，无分时预约
  else if (
    row[orderStatus] == 4 &&
    (!row.userInfoConvert || !row.userInfoConvert.faceId) &&
    !row.appointmentPeriodStartTime &&
    !row.appointmentPeriodEndTime
  ) {
    // 并且业务类型为销售，加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return ["取票", "强制核销", "补办", "激活", "退款"];
    } else {
      return [];
    }
  }
  // 如果已使用
  else if (row[orderStatus] == 6) {
    // 并且业务类型为销售，自定义收款,加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 2 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return ["强制退款", "核销"];
    } else {
      return [];
    }
  }
  // 如果已过期
  else if (row[orderStatus] == 10) {
    // 并且业务类型为销售,加购，团体购票时 有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 10
    ) {
      return ["强制核销", "强制退款"];
    } else {
      return [];
    }
  }
  // 如果退款失败
  else if (row[orderStatus] == 15) {
    // 并且业务类型为销售,加购，充值，团体购票时，有以下按钮权限
    if (
      row[orderBusinessType] == 1 ||
      row[orderBusinessType] == 3 ||
      row[orderBusinessType] == 4 ||
      row[orderBusinessType] == 10
    ) {
      return ["退款"];
    }
    // 如果为 ‘自定义收款’ 业务类型
    else if (row[orderBusinessType] == 2) {
      return ["强制退款"];
    } else {
      return [];
    }
  } else {
    return [];
  }
}
