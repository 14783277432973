/**
 *设置
 */
let getEveryConfigFn = (state, typeStr) => {
  let obj = {};
  if (typeStr === "get") {
    for (const key in state) {
      obj[`${typeStr}${key}`] = s => {
        return s[key];
      };
    }
  } else {
    for (const key in state) {
      obj[`${typeStr}${key}`] = (s, params) => {
        s[key] = params;
      };
    }
  }
  return obj;
};
/**
 * 由于切换景区和退出登录需要清空数据，避免污染
 * 增删state的内容时要同时维护mutations内的resetState的方法
 * */
const state = {
  doingAddPurchase: false //是否在选择加购产品
};
const mutations = {
  // 重置state，清空历史数据
  resetState(state) {
    const data = {
      doingAddPurchase: false
    };
    for (let key in state) {
      state[key] = data[key];
    }
  }
};
export default {
  name: "ticketSales",
  namespaced: true,
  state,
  getters: getEveryConfigFn(state, "get"),
  mutations: { ...getEveryConfigFn(state, "set"), ...mutations }
};
