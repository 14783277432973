<!--
  @name: 高拍仪
  @author: chenshiti
  @date:2022-01-14

  @events: grabImageUrl 返回拍照后的图片url
  @events: readIDCard   返回识别后的身份证信息
-->
<template>
  <a-modal
    title="高拍仪"
    :visible="showInfo"
    :body-style="{
      'max-height': '500px'
    }"
    :z-index="1002"
    :width="600"
    :mask-closable="false"
    :keyboard="false"
    @cancel="close"
  >
    <div>
      <div class="faceInput" @click="openCamera">
        <div v-if="imgUrl" class="picture">
          <img :src="imgUrl" alt="图片显示失败" />
        </div>
        <div v-else @click="openCamera(0)">
          <div class="iconfont iconrenlianheshen icon"></div>
          <div class="tip primary">打开摄像头</div>
        </div>
      </div>
      <div class="mt-2x">
        <a-button
          size="large"
          type="primary"
          class="ml-2x"
          ghost
          @click="openCamera(0)"
          >打开主摄像头</a-button
        >
        <a-button
          size="large"
          type="primary"
          class="ml-2x"
          ghost
          @click="openCamera(1)"
          >打开副摄像头</a-button
        >
        <a-button
          size="large"
          type="primary"
          class="ml-2x"
          @click="grabImageBase64()"
          >拍照</a-button
        >
        <a-button
          size="large"
          type="primary"
          class="ml-2x"
          ghost
          @click="readIDCard"
          >识别身份证</a-button
        >
      </div>
      <!-- 测试预览信息展示 -->
      <div v-if="iDCardInfo" class="mt-2x">
        <p>耗时：{{ iDCardInfo.readiDCardTime / 1000 }}秒</p>
        <p>身份证号：{{ iDCardInfo.cardID }}</p>
        <p>姓名：{{ iDCardInfo.name }}</p>
        <p>性别：{{ iDCardInfo.sex }}</p>
        <p>民族：{{ iDCardInfo.nationCode }}</p>
        <p>生日：{{ iDCardInfo.birthday }}</p>
        <p>地址：{{ iDCardInfo.address }}</p>
      </div>
      <div v-if="grabImageUrl.length" class="mt-2x">
        <a-upload
          action=""
          list-type="picture-card"
          :file-list="grabImageUrl"
          @preview="handlePreview"
          @change="handleChangeImg"
        >
        </a-upload>
        <a-modal
          :visible="previewVisible"
          :footer="null"
          :z-index="1003"
          @cancel="previewVisible = false"
        >
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { faceAnalyzeUpload } from "../../api/iot";
import { fileUploadBase64 } from "../../api/upload";
import CameraService from "../../common/domains/hardware-domain/cameraService";

export default {
  name: "cameraService",
  props: {
    //是否展示预览信息弹框
    showInfo: {
      type: Boolean,
      default: false
    },
    //0 主 1副
    devIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      imgUrl: "", //实时预览
      iDCardInfo: "", //身份证识别信息
      grabImageUrl: [], //拍照后图片
      previewVisible: false, //开启大图
      previewImage: "", //大图
      readiDCardTime: 0,
      photo: ""
    };
  },
  watch: {
    showInfo: {
      handler(newVal) {
        if (newVal) {
          this.openCamera(this.devIndex);
        }
      }
    }
  },
  created() {
    let Hardware = localStorage.getItem("Hardware");
    if (Hardware && JSON.parse(Hardware).gaoPaiYi) {
      CameraService.init(); //初始化
    }
  },
  methods: {
    /**
     * 打开摄像头
     * devIndex 0 主 1副
     */
    openCamera(devIndex) {
      this.devIndex = devIndex;
      this.imgUrl = CameraService.myOpenCamera(devIndex);
    },
    /**
     * 识别身份证
     */
    readIDCard() {
      let startTime = new Date().getTime();
      CameraService.myReadIDCard().then(res => {
        this.iDCardInfo = res.IDCardInfo;
        this.iDCardInfo.photo = res.IDCardInfo.photoBase64;
        this.iDCardInfo.readiDCardTime = new Date().getTime() - startTime;
        this.$emit("readIDCard", this.iDCardInfo); //身份证信息
      });
    },
    /**
     *拍照
     */
    grabImageBase64() {
      CameraService.grabImageBase64(this.devIndex).then(res => {
        //0 主 1副
        if (this.devIndex) {
          this.getPicture(res.photoBase64);
        } else {
          this.$emit("grabImageUrl", res.photoBase64); //副直接返回base64
        }
      });
    },
    getFacePicture(data64) {
      // fileUploadBase64({ data: `${data64}`, ext: "png" })
      faceAnalyzeUpload({ imageBase64: `data:image/png;base64,${data64}` })
        .then(res => {
          res.url = res.src;
          res.uid = this.grabImageUrl.length;
          res.status = "done";
          res.name = "--";
          this.grabImageUrl = [...this.grabImageUrl, ...[res]];
          this.$emit("grabImageUrl", res.src); //拍好的照片列表
        })
        .catch(err => {
          this.$message.warning(err.message);
          // console.log(err);
        });
    },
    getPicture(data64) {
      // fileUploadBase64({ data: `${data64}`, ext: "png" })
      fileUploadBase64({ data: `${data64}`, ext: "png" })
        .then(res => {
          res.url = res.src;
          res.uid = this.grabImageUrl.length;
          res.status = "done";
          res.name = "--";
          this.grabImageUrl = [...this.grabImageUrl, ...[res]];
          this.$emit("grabImageUrl", res.src); //拍好的照片列表
        })
        .catch(err => {
          this.$message.warning(err.message);
          // console.log(err);
        });
    },
    handlePreview(val) {
      this.previewImage = val.src;
      this.previewVisible = true;
    },
    handleChangeImg(val) {
      this.grabImageUrl = val.fileList;
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

.faceInput {
  border: 1px solid @border;
  overflow: hidden;
  border-radius: 6px;
  width: 300px;
  height: 300px;
  text-align: center;
  cursor: pointer;
  margin-left: 120px;
  .picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .icon {
    line-height: 140px;
    font-size: 90px;
    color: @gray;
  }
  .tip {
    font-size: 18px;
  }
}
</style>
