<template>
  <a-modal
    :title="tit"
    :visible="vis"
    :body-style="{
      'max-height': '460px'
    }"
    :z-index="1001"
    :width="600"
    :mask-closable="false"
    :keyboard="false"
    @cancel="close"
  >
    <a-form-model
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      label-align="left"
      ref="myForm"
    >
      <!-- 公共设置 标题 -->
      <a-form-model-item
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        label=""
      >
        <div @click="collapseClick(1)" class="title-pannel-wrap ph-2x pv-1x">
          <div class="left title-pannel">公共设置</div>
          <div class="right pointer">
            <a-icon
              class="icon-transition"
              :class="{ 'rotate-icon': !collapseList[0] }"
              type="down"
            />
          </div>
        </div>
      </a-form-model-item>
      <!-- 公共设置 wrap -->
      <a-form-model-item
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        label=""
        v-show="collapseList[0]"
      >
        <a-form-model-item class="pl-2x" label="收银小票" :colon="false">
          <a-switch v-model="form.cashierReceipt" />
          <span class="gray ml-1x">
            {{ form.cashierReceipt ? "打印" : "不打印" }}
          </span>
        </a-form-model-item>
        <a-form-model-item
          label="收银小票打印模板"
          :colon="false"
          class="pl-2x"
          prop="receiptTemplate"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="[
            {
              required: form.cashierReceipt ? true : false,
              message: '请选择',
              trigger: 'change'
            }
          ]"
        >
          <a-select placeholder="请选择" v-model="form.receiptTemplate">
            <a-select-option
              :value="son.id"
              v-for="(son, index) in receiptTemplateList"
              :key="index"
            >
              {{ son.templateName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="交账打印模板"
          :colon="false"
          class="pl-2x"
          prop="accountforTemplate"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-select placeholder="请选择" v-model="form.accountforTemplate">
            <a-select-option
              :value="son.id"
              v-for="(son, index) in accountforTemplateList"
              :key="index"
            >
              {{ son.templateName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item class="pl-2x" label="启用高拍仪" :colon="false">
          <a-switch v-model="form.gaoPaiYi" @change="changGaoPaiYi" />
          <span class="gray ml-1x">
            {{ form.gaoPaiYi ? "启用" : "关闭" }}
          </span>
          <a-button type="link" v-if="form.gaoPaiYi" @click="showInfo = true"
            >测试</a-button
          >
        </a-form-model-item> -->

        <a-form-model-item
          :label="item.labelName"
          :colon="false"
          class="pl-2x"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          v-for="item in publicSettingOptions"
          :key="item.key"
          :prop="item.key"
          :rules="[
            {
              required: item.items.length > 0,
              message: '请选择',
              trigger: 'change'
            }
          ]"
        >
          <a-select
            placeholder="请选择"
            v-model="form[item.key]"
            @change="e => handleSelectChange(item.key, e)"
          >
            <a-select-option
              :value="son"
              v-for="(son, index) in item.items"
              :key="index"
            >
              {{ son }}
            </a-select-option>
          </a-select>
          <router-link
            v-if="item.key == 'idCardNames' && isWindow"
            tag="a"
            target="_blank"
            to="/DownloadDriver"
          >
            驱动下载
          </router-link>
        </a-form-model-item>
      </a-form-model-item>

      <!-- 门票 设置 -->
      <a-form-model-item
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        label=""
      >
        <div @click="collapseClick(2)" class="title-pannel-wrap ph-2x pv-1x">
          <div class="left title-pannel">门票</div>
          <div class="right pointer">
            <a-icon
              class="icon-transition"
              :class="{ 'rotate-icon': !collapseList[1] }"
              type="down"
            />
          </div>
        </div>
      </a-form-model-item>
      <!-- 门票设置 wrap -->
      <a-form-model-item
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        label=""
        v-show="collapseList[1]"
      >
        <a-form-model-item class="pl-2x" label="打印门票" :colon="false">
          <a-switch v-model="form.printTicket" />
          <span class="gray ml-1x">
            {{ form.printTicket ? "打印" : "不打印" }}
          </span>
        </a-form-model-item>
        <a-form-model-item
          :label="item.labelName"
          :colon="false"
          class="pl-2x"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          v-for="item in ticketSettingOptions"
          :key="item.key"
          :prop="item.key"
          :rules="[
            {
              required: item.items.length > 0,
              message: '请选择',
              trigger: 'change'
            }
          ]"
        >
          <a-select
            placeholder="请选择"
            v-model="form[item.key]"
            @change="e => handleSelectChange(item.key, e)"
          >
            <a-select-option
              :value="son"
              v-for="(son, index) in item.items"
              :key="index"
            >
              {{ son }}
            </a-select-option>
          </a-select>
          <router-link
            v-if="item.key == 'printerNames' && isWindow"
            tag="a"
            target="_blank"
            to="/DownloadDriver"
          >
            驱动下载
          </router-link>
        </a-form-model-item>
      </a-form-model-item>
      <!-- 游乐 设置 -->
      <a-form-model-item
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        label=""
      >
        <div @click="collapseClick(3)" class="title-pannel-wrap ph-2x pv-1x">
          <div class="left title-pannel">游乐</div>
          <div class="right pointer">
            <a-icon
              class="icon-transition"
              :class="{ 'rotate-icon': !collapseList[2] }"
              type="down"
            />
          </div>
        </div>
      </a-form-model-item>
      <!-- 游乐设置 wrap -->
      <a-form-model-item
        :label-col="{ span: 0 }"
        :wrapper-col="{ span: 24 }"
        label=""
        v-show="collapseList[2]"
      >
        <a-form-model-item class="pl-2x" label="打印项目套餐" :colon="false">
          <a-switch v-model="form.printProject" />
          <span class="gray ml-1x">
            {{ form.printProject ? "打印" : "不打印" }}
          </span>
        </a-form-model-item>
        <a-form-model-item
          label="项目套餐打印模板"
          :colon="false"
          class="pl-2x"
          prop="projectTemplate"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="[
            {
              required: form.printProject ? true : false,
              message: '请选择',
              trigger: 'change'
            }
          ]"
        >
          <a-select placeholder="请选择" v-model="form.projectTemplate">
            <a-select-option
              :value="son.id"
              v-for="(son, index) in projectTemplateList"
              :key="index"
            >
              {{ son.templateName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 和维源确认：未有叫号小票打印模板，先隐藏 -->
        <!-- <a-form-model-item
          label="叫号小票打印模板"
          :colon="false"
          class="pl-2x"
          prop="callTemplate"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-select placeholder="请选择" v-model="form.callTemplate">
            <a-select-option
              :value="son.id"
              v-for="(son, index) in callTemplateList"
              :key="index"
            >
              {{ son.templateName }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item
          :label="item.labelName"
          :colon="false"
          class="pl-2x"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          v-for="item in amusementSettingOptions"
          :key="item.key"
          :prop="item.key"
          :rules="[
            {
              required: item.items.length > 0,
              message: '请选择',
              trigger: 'change'
            }
          ]"
        >
          <a-select
            placeholder="请选择"
            v-model="form[item.key]"
            @change="e => handleSelectChange(item.key, e)"
          >
            <a-select-option
              :value="son"
              v-for="(son, index) in item.items"
              :key="index"
            >
              {{ son }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model-item>
    </a-form-model>
    <div slot="footer" class="flex-center">
      <a-button type="primary" @click="handleOk">提交</a-button>
      <a-button @click="close">取消</a-button>
    </div>
    <GaoPaiYiCamera :showInfo="showInfo" @close="showInfo = false" />
  </a-modal>
</template>

<script>
import HardwareService from "../../common/domains/hardware-domain/hardwareService";
import CameraService from "../../common/domains/hardware-domain/cameraService";
import { getPrintTemplateList } from "../../api/setting";
import GaoPaiYiCamera from "@/components/hardware/GaoPaiYiCamera";

export default {
  name: "InitSetting",
  props: {
    vis: {
      type: Boolean,
      default: true
    },
    tit: {
      type: String,
      default: "硬件初始化设置"
    }
  },
  components: { GaoPaiYiCamera },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      publicSettingOptions: [], // 后端返回的硬件数据(公共设置)
      ticketSettingOptions: [], // 后端返回的硬件数据(门票设置)
      amusementSettingOptions: [], // 后端返回的硬件数据(游乐设置)
      isWindow: false, // 是否为window安装包环境

      collapseList: [true, true, true], // 折叠面板，展示与收缩
      receiptTemplateList: [], // 收银小票打印模板数据
      accountforTemplateList: [], // 交账打印机模板数据
      projectTemplateList: [], // 项目套餐打印模板数据
      callTemplateList: [], // 叫号小票打印模板数据
      form: {
        cashierReceipt: true, // 收银小票 switch
        receiptTemplate: "", // 收银小票打印模板
        accountforTemplate: "", // 交账打印机模板
        projectTemplate: "", // 项目套餐打印模板
        callTemplate: "", // 叫号小票打印模板
        printTicket: true, // 打印门票 switch
        printProject: true, // 打印项目套餐 switch
        gaoPaiYi: false //高拍仪
      },
      rules: {
        accountforTemplate: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ],
        callTemplate: [
          {
            required: true,
            message: "请选择",
            trigger: "change"
          }
        ]
      },
      showInfo: false, //高拍仪弹窗
      timer: null
    };
  },
  watch: {
    vis(v) {
      if (v) {
        this.getDevice();
      }
    }
  },
  created() {
    this.checkWindow();
    this.getPrintTemp(2); // 获取收银小票打印模板
    this.getPrintTemp(3); // 获取交账打印模板
    this.getPrintTemp(5); // 项目套餐打印模板
    this.getPrintTemp(4); // 叫号小票打印模板
    let Hardware = localStorage.getItem("Hardware");
    if (Hardware) {
      this.form = JSON.parse(Hardware);
    }
    let time = setTimeout(() => {
      this.getDevice();
      clearTimeout(time);
      time = null;
    }, 1000);
  },
  methods: {
    getDevice() {
      this.ticketSettingOptions = [];
      this.amusementSettingOptions = [];
      this.publicSettingOptions = [];
      let allOptions = HardwareService.getDeviceInfo();
      if (allOptions.length) {
        let me = this;
        const arr = JSON.parse(allOptions);
        /**
         * 从所有数据中，筛选出：门票设置、游乐设置
         */
        const playIndex = [];
        arr.forEach((item, index) => {
          // 1、门票设置
          if (item.key == "printerNames") {
            me.ticketSettingOptions.push(item);
            playIndex.push(index);
          } else if (
            item.key == "playItemPackagePrintNames" ||
            item.key == "callSmallPrintNames" ||
            item.key == "consumeRecordPrinterNames"
          ) {
            // 游乐设置
            me.amusementSettingOptions.push(item);
            playIndex.push(index);
          }
        });
        // 剔除门票设置、游乐设置数据，留下公共设置（注意点：每剔除一个元素，索引值变更，所以需要：item - index）
        playIndex.forEach((item, index) => {
          arr.splice(item - index, 1);
        });
        // 剔除门票、游乐设置后，剩余公共设置
        me.publicSettingOptions = arr;
      }
    },
    //高拍仪开启/关闭
    changGaoPaiYi(val) {
      if (val) {
        CameraService.init().catch(() => {
          //监测是否开启高拍仪软件 未开启
          this.form.gaoPaiYi = false;
        });
      }
    },
    handleOk() {
      this.$refs.myForm.validate(valid => {
        if (!valid) {
          this.$message.warning("填写信息校验不通过，请检查");
          return;
        }
        this.form.gaoPaiYi = false; //关闭前端高拍仪
        let setting = JSON.stringify(this.form);
        HardwareService.choiceDevice(JSON.stringify(this.form));
        localStorage.setItem("Hardware", setting);
        this.$emit("update:vis", false);
        this.$message.success("硬件初始化完成");
      });
    },
    handleSelectChange(key, value) {
      this.form[key] = value;
    },
    // 关闭弹窗
    close() {
      this.$emit("update:vis", false);
    },
    // 获取打印模板
    getPrintTemp(type) {
      getPrintTemplateList({
        templateType: type // 模板类型：1门票，2小票，3交账，4叫号，5项目套餐，6优惠券
      })
        .then(res => {
          switch (type) {
            case 2:
              this.receiptTemplateList = res || [];
              break;
            case 3:
              this.accountforTemplateList = res || [];
              break;
            case 5:
              this.projectTemplateList = res || [];
              break;
            case 4:
              this.callTemplateList = res || [];
              break;
            default:
              break;
          }
        })
        .catch(() => {});
    },
    // 折叠面板
    collapseClick(index) {
      const arr = JSON.parse(JSON.stringify(this.collapseList));
      arr[index - 1] = !arr[index - 1];
      this.collapseList = arr;
    },
    // 判断是否为windows系统下的安装包环境
    checkWindow() {
      const u = navigator.userAgent;
      this.isWindow = u.indexOf("WOW64") > -1 || u.indexOf("WOW") > -1;
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

.title-pannel-wrap {
  box-shadow: 0px 4px 8px 0px rgba(200, 200, 200, 0.5);
  height: 40px;
  display: flex;
  justify-content: space-between;
  .title-pannel {
    border-left: 5px solid @primary;
    padding-left: 8px;
    height: 100%;
    font-size: 16px;
  }
  .right,
  .left {
    line-height: 26px;
  }
}

.icon-transition {
  transition: all 0.5s;
}
.rotate-icon {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

/deep/.ant-modal-body {
  overflow-y: auto;
}
</style>
