import Vue from "vue";
import { antd } from "./load-files/antd";
import App from "./App.vue";
import "./assets/icons/iconfont/iconfont.css";
import "./assets/less/common.less";
import router from "./router";
import store from "./store";
import { dateFormat, moneyFormat } from "./utils/global";
import Bus from "./utils/bus";
import permissionDirective from "./directive/permission";
import inputFocue from "./directive/inputfocue";
// 窗口的安卓包和exe包调试用（console）
// import VConsole from "vconsole/dist/vconsole.min.js";
// if (process.env.NODE_ENV === "development") {
//   new VConsole();
//   localStorage.setItem("vConsole_switch_x", 500);
//   localStorage.setItem("vConsole_switch_y", 100);
// }

Vue.config.productionTip = false;
Vue.use(Bus);
// 根据屏幕高度 动态切换表格的可视高度
Vue.prototype.$tableHeight = (...rest) => {
  let windowHeight = document.documentElement.clientHeight;
  rest.forEach(item => (windowHeight -= item));
  return windowHeight;
};

//自定义权限指令
Vue.directive("permission", permissionDirective);
Vue.directive("inputfocus", inputFocue());
antd(Vue);

// 日期格式化过滤器
Vue.filter("date", (value, format) => {
  return dateFormat(value, format);
});
// 金钱格式化过滤器
Vue.filter("money", (value, unit) => {
  return moneyFormat(value, unit);
});

const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

export default vm;
