<!--
  @name: 热销产品设置
  @author: fengyanlong
  @date:2021-05-20
-->
<template>
  <div>
    <a-transfer
      :data-source="ticketList"
      :titles="['全部产品', '热销产品']"
      :target-keys="targetKeys"
      :selected-keys="selectedKeys"
      :render="item => item.title"
      @change="handleChange"
      @selectChange="handleSelectChange"
    />
  </div>
</template>

<script>
import { productFrontHotproduct } from "@/api/search";
import { hotProductList } from "../../../api/product";
import moment from "moment";

export default {
  name: "hotsale-product",
  props: {
    value: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      moment, // 初始化moment
      ticketList: [],
      targetKeys: [],
      selectedKeys: []
    };
  },
  created() {
    //获取所有产品
    this.getTicketList();
    //获取已选中的热销产品
    this.getHotProduct();
  },
  methods: {
    // 获取门票列表
    getTicketList() {
      productFrontHotproduct()
        .then(res => {
          if (res && res.length) {
            this.ticketList = res.map(item => {
              return { key: item.id, title: item.name };
            });
          }
        })
        .catch(() => {});
    },
    getHotProduct() {
      hotProductList()
        .then(res => {
          this.targetKeys = res.map(item => {
            return item.productId;
          });
        })
        .catch(() => {});
    },
    handleChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
      // console.log("targetKeys: ", this.targetKeys);

      this.$emit("input", this.targetKeys);
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    }
  }
};
</script>

<style lang="less" scoped></style>
