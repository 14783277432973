/**
 * 当input框被选中时，禁止快捷键使用
 * 当input框失去焦点时，允许快捷键使用
 */
import store from "../store";

export default function() {
  function focusEvent() {
    store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
  }
  function blurEvent() {
    store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
  }
  return {
    // 绑定
    inserted(el) {
      let target = el.getElementsByTagName("input")[0] || el;
      if (!target) return;
      target.addEventListener("focus", focusEvent, false);
      target.addEventListener("blur", blurEvent, false);
    },
    // 解绑
    unbind(el) {
      let target = el.getElementsByTagName("input")[0] || el;
      if (!target) return;
      target.removeEventListener("focus", focusEvent, false);
      target.removeEventListener("blur", blurEvent, false);
    }
  };
}
