<!--
  @name: 带格子的密码输入框
  @author: fengyanlong
  @date:2021-05-27
  @props: {
    value: 绑定组件v-model
    cellNumber: 格子个数，默认6
  }
  @events: {
    confirm:viod 回车事件触发
  }
  v-model:value
-->

<template>
  <div
    class="cell-password"
    id="hello"
    :style="{ width: 40 * cellNumber + 60 + 'px' }"
  >
    <div class="cover">
      <b v-for="item in cellNumber" :key="item"></b>
    </div>
    <input
      class="password"
      type="password"
      :maxlength="cellNumber"
      v-model="password"
      @focus="clear"
      @input="setPassword"
      autocomplete="off"
      :style="{ width: 40 * cellNumber + 60 + 'px' }"
    />
    <div class="cc" :style="{ left: 40 * cellNumber + 'px' }"></div>
  </div>
</template>

<script>
export default {
  name: "cell-password",
  props: {
    value: {
      type: String,
      default: ""
    },
    cellNumber: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      password: ""
    };
  },
  created() {},
  methods: {
    setPassword() {
      let p = this.password;
      this.$emit("input", p);
    },
    clear() {
      this.password = "";
    }
  }
};
</script>

<style lang="less" scoped>
.cell-password {
  width: 300px;
  height: 40px;
  position: relative;
  .password {
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 15px;
    font-size: 30px;
    letter-spacing: 30px;
    padding-right: 10px;
    height: 40px;
    width: 300px;
    outline: none;
    border: none;
  }
  .cover {
    position: absolute;
    border: 1px solid #ccc;
    border-radius: 4px;
    pointer-events: none;
    height: 42px;
    box-sizing: border-box;
    left: 0;
    top: 0;
    z-index: 2;
    b {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-right: 1px solid #ccc;
      &:last-child {
        border-right: none;
      }
    }
  }
  .cc {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 240px;
    top: -5px;
    background: #ffffff;
  }
}
</style>
