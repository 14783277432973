/**
 * 支付中心 API
 **/
import http from "../utils/fetch";
const prefix = "/api/pay";

// 获取景区支付开关-陈铭
export const switchStatus = () => {
  return http.get(`${prefix}/payment/org/switch/status`);
};
// 获取标记收款列表-胡汉杰
export const receiptsMethodList = data => {
  return http.get(`${prefix}/payment/org/receipts/method/list`, data);
};
// 支付时查询支付方式列表【通用接口】
export const merchantuthPayFormWayInfo = data => {
  return http.get(`${prefix}/merchant/auth/pay/form/way/info`, data);
};

//订单查询条件-支付方式列表接口  - 刘院民  http://192.168.1.50:40001/project/41/interface/api/3692
export const payWayForOrderQuery = data => {
  return http.get(`${prefix}/pay/way/for/order/query`, data);
};

//【pay018】根据支付方式查询支付通道列表 不分页 - 许家炜  http://192.168.1.50:40001/project/41/interface/api/3679
export const findPayMerchantChannelSettingAll = data => {
  return http.get(`${prefix}/find/pay/merchant/channel/setting/all`, data);
};
