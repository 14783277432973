export default {
  /*** 直接获取的属性值 */
  totalPrice(state) {
    return state.cartList.totalPrice;
  },
  cartList(state) {
    return state.cartList;
  },
  visitorsList(state) {
    return state.visitorsList.filter(item => {
      //needVisitorInfo判断是否需要填游客信息
      return item.needVisitorInfo;
    });
  },
  purchaserMemberInfo(state) {
    return state.purchaserMemberInfo;
  },

  // 计算选择优惠后，应付金额
  shouldPayMoney(state) {
    let amount = 0;
    const arr = [...state.discountTextList, ...state.integralTextList];
    if (arr.length) {
      amount = arr.reduce((pre, cur) => {
        return pre + cur.amount;
      }, 0);
    }
    const money = state.cartList.totalPrice - amount;
    return money;
  },

  // 支付重构：选择优惠 - 订单折扣
  discountTextList(state) {
    return state.discountTextList;
    /**
     * discountTextList里存储的数据：
     * 1、name：优惠类型的名称
     * 2、amount：该优惠类型，优惠的金额
     * 3、merchantDiscountStr: 订单折扣里的类型：（整单折扣 A）（会员折扣 B）（优惠券 C）（营销活动 D）
     * 4、discountId：整单折扣id
     * 5、customAmount：整单折扣使用自定义金额
     * 6、settingType：配置类型：1整单减价 2整单折扣
     * 7、couponNo：优惠卷号
     * 8、marketingId：优惠活动id,优惠券id
     * 9、marketingMobile：营销活动的手机号
     * 10、idCardNo：营销活动的身份证号
     */
  },
  // 支付重构：选择优惠 - 积分抵扣
  integralTextList(state) {
    return state.integralTextList;
    /**
     * integralTextList里存储的数据：
     * 1、name：优惠类型名称
     * 2、integralPayNum：会员积分抵扣数量
     * 3、amount：积分抵扣的金额
     */
  },
  // 支付完成后，需要告知支付页面的操作配置
  endOfPayConfig(state) {
    return state.endOfPayConfig;
  }
};
