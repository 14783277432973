<template>
  <div class="test">
    <h2 class="title">窗口端接口测试页</h2>
    <a-divider />
    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" class="mt-3x">
      <a-form-item label="请求方法">
        <a-select default-value="get" class="item-width" @change="handleChange">
          <a-select-option value="get">
            get
          </a-select-option>
          <a-select-option value="add">
            add
          </a-select-option>
          <a-select-option value="put">
            put
          </a-select-option>
          <a-select-option value="delete">
            delete
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="请求URL">
        <a-input v-model="apiUrl" class="item-width" allow-clear />
      </a-form-item>
      <a-form-item label="请求参数">
        <a-textarea
          v-model="apiOption"
          placeholder="请输入json格式"
          :auto-size="{ minRows: 5, maxRows: 15 }"
        />
      </a-form-item>
      <a-form-item label="返回数据">
        <div class="review">
          <pre>{{ response }}</pre>
        </div>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button type="primary" @click="testApi" size="large">
          测试
        </a-button>
        <a-button @click="reset" size="large" class="ml-3x">重置</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { basePath } from "../utils/global";
import $http from "../utils/fetch";
export default {
  name: "",
  data() {
    return {
      apiMethod: "get",
      apiUrl: "/api/",
      apiOption: "",
      response: ""
    };
  },
  created() {},
  methods: {
    handleChange(value) {
      this.apiMethod = value;
    },
    reset() {
      this.apiMethod = "get";
      this.apiUrl = "/api/";
      this.apiOption = "";
      this.response = "";
    },
    testApi() {
      let data = "";
      try {
        data = JSON.parse(this.apiOption);
      } catch {
        this.$message.warning("json格式错误，请检查");
        return false;
      }
      if (this.apiMethod == "get") {
        $http.get(basePath + this.apiUrl, data).then(res => {
          this.response = JSON.stringify(res, null, 4);
        });
      } else if (this.apiMethod == "add") {
        $http.add(basePath + this.apiUrl, data).then(res => {
          this.response = JSON.stringify(res);
        });
      } else if (this.apiMethod == "put") {
        $http.put(basePath + this.apiUrl, data).then(res => {
          this.response = JSON.stringify(res);
        });
      } else if (this.apiMethod == "delete") {
        $http.delete(basePath + this.apiUrl, data).then(res => {
          this.response = JSON.stringify(res);
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.test {
  width: 100%;
  padding-top: 20px;
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  .ant-form label {
    font-size: 18px;
  }
  .ant-input {
    font-size: 18px;
  }
  .ant-select {
    font-size: 18px;
  }
  .item-width {
    width: 400px;
  }
  .review {
    height: 400px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background: #f7f7f7;
    border-radius: 4px;
    pre {
      line-height: 24px;
    }
  }
}
</style>
