<!--
  @name: 公共的查询会员页面，可以在没有会员时显示这个，用来查询并更新全局的会员id
  @author: fengyanlong
  @date:2021-04-14
  @event:{
    //会员查询成功返回的一个事件
    success:{
        type:Function,
        default:null
    }
  }
-->
<template>
  <div>
    <keyboard-pro
      v-model="numbers"
      @ok="searchMember"
      @change="isMember = true"
      class="keyword"
    >
      <template v-slot:input="{ confirm }">
        <a-form-model autocomplete="off">
          <a-input-search
            v-inputfocus
            ref="fouceRef"
            size="large"
            placeholder="请输入会员凭证"
            v-model="numbers"
            allow-clear
            @pressEnter="confirm()"
          >
            <a-icon slot="prefix" type="search" class="primary" />
            <a-button slot="enterButton">
              <a-icon type="scan" class="primary" />
            </a-button>
          </a-input-search>
        </a-form-model>
      </template>
      <div v-if="!isMember" slot="midSlot">
        <span class="red font-l">{{ this.numbers }}还不是会员，</span>
        <a-button type="link" size="large" @click="memberAdd">
          快速办理
        </a-button>
      </div>
    </keyboard-pro>
  </div>
</template>

<script>
import { orderOneMemberInfo } from "@/api/member.js"; // MS-会员接口
import { mapActions } from "vuex";
import { changFouce } from "@/utils/global";
import KeyboardPro from "../common/KeyboardPro.vue";

export default {
  name: "findMember",
  data() {
    return {
      isMember: true, // 是否为会员
      numbers: "",
      ready: false
    };
  },
  components: {
    KeyboardPro
  },
  created() {
    changFouce(this, "fouceRef");
  },
  methods: {
    //自动聚焦
    ...mapActions("common", ["updateMember"]),
    // 搜索会员
    searchMember() {
      if (this.numbers.length === 0) {
        this.$message.warning("请输入会员凭证");
        return false;
      }
      //客户端会一直调这个 先注释
      orderOneMemberInfo({ evidence: this.numbers })
        .then(res => {
          if (res && res.id) {
            this.updateMember(res);
            // 传参：this.numbers是给会员余额支付判断是否为扫会员码的场景
            this.$emit("success", res.id, this.numbers);
            // 由于该方法会一直被触发，所以清空numbers防止接口频繁请求
            this.numbers = "";
          } else {
            this.isMember = false;
          }
        })
        .catch(() => {});
    },
    // 新增会员
    memberAdd() {
      this.$router.push({
        path: "/home/member/MemberEdit",
        query: { mobile: this.numbers, pageType: "add" }
      });
    }
  }
};
</script>

<style lang="less" scoped>
/*键盘  */
.keyword {
  margin: 32px auto;
}
</style>
