/**
 * @name 游乐路由
 */

export default [
  {
    path: "/play",
    component: () =>
      import(
        /* webpackChunkName: "chuck-play" */ "../views/play/PlayIndex.vue"
      ),
    children: [
      /**
       *门票充值
       */
      //门票查询
      {
        path: "/play/recharge/ticketSearch",
        name: "playTicketSearch",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/retail/recharge/TicketSearch.vue"
          )
      },
      //门票充值
      {
        path: "/play/recharge/ticketRecharge",
        name: "playTicketRecharge",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/retail/recharge/TicketRecharge.vue"
          )
      },
      /**
       *项目套餐
       */
      //套餐列表
      {
        path: "/play/package/projectPackage",
        name: "playProjectPackage",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/retail/package/ProjectPackage.vue"
          )
      },
      /**
       * 项目消费
       */
      {
        path: "/play/consume/index",
        name: "playConsumeIndex",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/play/deduction/DeductionIndex.vue"
          )
      },
      /**
       *消费记录
       */
      // 记录列表
      {
        path: "/play/record/RecordList",
        name: "playRecord",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/play/record/RecordList.vue"
          )
      },
      /**
       *消费汇总
       */
      // 当日消费汇总
      {
        path: "/play/summary/SummaryToday",
        name: "consumeSummaryToday",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/play/summary/SummaryToday.vue"
          )
      },
      // 查看消费汇总
      {
        path: "/play/summary/SummaryTotal",
        name: "consumeSummaryTotal",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/play/summary/SummaryTotal.vue"
          )
      },
      // 结束计时
      {
        path: "/play/deduction/DeductionTimingOff",
        name: "playDeductionTimingOff",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/play/deduction/DeductionTimingOff.vue"
          )
      },
      // 未结算记录
      {
        path: "/play/deduction/DeductionTimingRecord",
        name: "playeDeductionTimingRecord",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/play/deduction/DeductionTimingRecord.vue"
          )
      },
      // 销售
      {
        path: "/play/sell/SellProduct",
        name: "playeSellProduct",
        component: () =>
          import(
            /* webpackChunkName: "chuck-play" */ "../views/play/sell/SellProduct.vue"
          )
      }
    ]
  }
];
