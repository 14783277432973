/**
 * @name: 【新版order】ms-order
 *@date:2021-04-12
 *@version:3.0
 *@description:新表模块
 */
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/order`; //服务前缀

/**
 *门票
 */
//order003-查询门票详情-云玉峰
export const findTicketDetail = data => {
  return http.get(`${prefix}/find/ticket/detail`, data);
};
//order004-查询门票账号概况-云玉峰
export const findTicketAssetsList = data => {
  return http.get(`${prefix}/find/ticket/assets/list`, data);
};
//order005-查询项目套餐概况-云玉峰
export const findTicketPlayItemPackagePage = data => {
  return http.get(`${prefix}/find/ticket/playitempackage/page`, data);
};
//order006-查询综合套票子产品(分页)-云玉峰
export const findTicketPackageProductPage = data => {
  return http.get(`${prefix}/find/ticket/package/product/page`, data);
};
//order007-查询产品详情信息-云玉峰
export const findOrderDetailProductDetail = data => {
  return http.get(`${prefix}/find/order/detail/product/detail`, data);
};
// order008-查询门票补办记录(分页)-云玉峰
export const findTicketReissuePage = data => {
  return http.get(`${prefix}/find/ticket/reissue/page`, data);
};
//order009-查询快票通行服务基础信息-云玉峰
export const findTicketFastInfo = data => {
  return http.get(`${prefix}/find/ticket/fast/info`, data);
};
// order009-查询快票通行服务赠送产品(分页)-云玉峰
export const findTicketFastProductPage = data => {
  return http.get(`${prefix}/find/ticket/fast/product/page`, data);
};
//order010-查询卡腕带押金信息-云玉峰
export const findTicketDepositInfo = data => {
  return http.get(`${prefix}/find/ticket/deposit/info`, data);
};
// order011-查询推荐加购记录-云玉峰
export const findTicketAddProductPage = data => {
  return http.get(`${prefix}/find/ticket/add/product/page`, data);
};
// order012-查询门票充值记录(分页)-云玉峰
export const findTicketRechargeProductPage = data => {
  return http.get(`${prefix}/find/ticket/recharge/product/page`, data);
};
// order013-查询门票核销记录(分页)-云玉峰
export const findTicketWriteOffInfoPage = data => {
  return http.get(`${prefix}/find/ticket/writeoff/info/page`, data);
};

/**
 *门票操作
 */
// order018-取票查询-云玉峰
export const findTicketPreprintInfo = data => {
  return http.get(`${prefix}/find/ticket/preprint/info`, data);
};
// order018-批量取票查询-云玉峰
export const findTicketPreprintBatchInfo = data => {
  return http.post(`${prefix}/find/ticket/preprint/batch/info`, data);
};
// order019-取票-云玉峰
export const ticketPrint = data => {
  return http.post(`${prefix}/ticket/print`, data);
};
// order042-取票(团体票)-云玉峰
export const ticketGroupPrint = data => {
  return http.post(`${prefix}/ticket/group/print`, data);
};
// 暂无编号-门票操作记录列表-刘院民
export const ticketOperateRecordShowPages = data => {
  return http.get(`${prefix}/ticket/operate/record/show/pages`, data);
};
// order024-门票改签-刘院民、order027-延长门票有效期-刘院民
export const ticketChangingOperation = data => {
  return http.put(`${prefix}/ticket/changing/operation`, data);
};
// 新版订单 专门延长修改预约时间 -http://192.168.1.50:40001/project/41/interface/api/3995
export const ticketValidTime = data => {
  return http.post(`${prefix}/ticket/valid/time`, data);
};
// 新版订单 修改预约 -http://192.168.1.50:40001/project/41/interface/api/3995
export const ticketChanging = data => {
  return http.post(`${prefix}/ticket/changing`, data);
};

// new  门票激活-刘院民 http://192.168.1.50:40001/project/41/interface/api/3976
export const ticketActivite = data => {
  return http.post(`${prefix}/ticket/activite`, data);
};
// order020-门票激活查询-云玉峰
export const findTicketActivateInfo = data => {
  return http.get(`${prefix}/find/ticket/activate/info`, data);
};
// order020-门票激活-云玉峰
export const ticketActivate = data => {
  return http.post(`${prefix}/ticket/activate`, data);
};
// order029-补录人脸-云玉峰
export const ticketActivateFace = data => {
  return http.post(`${prefix}/ticket/activate/face`, data);
};
// new -补录人脸-云玉峰 http://192.168.1.50:40001/project/41/interface/api/3977
export const additionalRecordingFace = data => {
  return http.post(`${prefix}/additional/recording/face`, data);
};
// order021-门票补办查询-云玉峰
export const findTicketReissueInfo = data => {
  return http.get(`${prefix}/find/ticket/reissue/info`, data);
};
// order021-产品补办前查询-云玉峰 new-http://192.168.1.50:40001/project/41/interface/api/4002
export const productReissueCheck = data => {
  return http.get(`${prefix}/product/reissue/check`, data);
};
/**
 *订单
 */
// 组合产品详情-许家炜 http://192.168.1.50:40001/project/41/interface/api/3952
export const orderDetailSubProductDetailsInfo = data => {
  return http.get(`${prefix}/order/detail/sub/product/details/info`, data);
};
// order031-订单详情-云玉峰
export const findOrderDetailInfo = data => {
  return http.get(`${prefix}/find/order/detail/info`, data);
};
//order032-订单详情中子产品详情-云玉峰
export const findSuborderDetailInfo = data => {
  return http.get(`${prefix}/find/suborder/detail/info`, data);
};
// order025-订单退款校验-云玉峰
export const refundCheck = data => {
  return http.post(`${prefix}/refund/check`, data);
};
// order026-订单退款-云玉峰
export const refund = data => {
  return http.post(`${prefix}/refund`, data);
};
// order038-订单详情退款记录(无分页)-云玉峰
export const findOrderRefundRecord = data => {
  return http.get(`${prefix}/find/order/refund/record`, data);
};
// order040-退款记录详情-云玉峰
export const findOrderRefundDetailInfo = data => {
  return http.get(`${prefix}/find/order/refund/detail/info`, data);
};
// order035-退款重试-云玉峰
export const findOrderRefundRetry = data => {
  return http.put(`${prefix}/refund/retry?refundId=${data.refundId}`);
};

// 窗口和游小铺打印pdf凭证出行 -刘院民
export const orderDocuments = data => {
  return http.post(`${prefix}/order/documents`, data);
};
// order037-新版本---退款审批-云玉峰 http://192.168.1.50:40001/project/41/interface/api/3988
export const refundRecordAudit = data => {
  return http.put(`${prefix}/refund/record/audit`, data);
};

/**
 * 交账
 */
//需要交账的用户信息 -云玉峰
export const getHandOverUserInfo = data => {
  return http.get(`${prefix}/find/handover/account/clock/in`, data);
};
//需要交账的列表 -云玉峰
export const getHandOverDetail = data => {
  return http.get(`${prefix}/handover/account/query/summarydata`, data);
};

//历史交账记录列表 -云玉峰  // return http.get(`${prefix}/find/handover/account/page`, data);  旧版本的弃用，暂留地址
//历史交账记录列表（新版）- 刘院民  http://192.168.1.50:40001/project/41/interface/api/3803
export const orderHandoverMainInfoPage = data => {
  return http.get(`${prefix}/order/handover/main/info/page`, data);
};

//历史交账记录详情 -云玉峰  return http.get(`${prefix}/find/handover/account/detail/info`, data); 旧版本的弃用，暂留地址，旧的是商户汇总和产品汇总在同一个接口

//获取交账详情-商户汇总【窗口新版】- 刘院民  http://192.168.1.50:40001/project/41/interface/api/3801
export const orderHandoverMainInfo = data => {
  return http.get(`${prefix}/order/handover/main/info`, data);
};

//查询交账详情信息 - 产品汇总【窗口新版】（新版）- 刘院民  http://192.168.1.50:40001/project/41/interface/api/3802
export const orderHandoverAccountInfo = data => {
  return http.get(`${prefix}/order/handover/account/info`, data);
};

//确认交账 -云玉峰
export function submitHandOver(data) {
  return http.post(`${prefix}/handover/account/save/summarydata`, data);
}

/**
 *核销
 */
// 新版订单--  order039-产品核销(后台)-云玉峰http://192.168.1.50:40001/project/41/interface/api/3982
export const productWriteOff = data => {
  return http.post(`${prefix}/product/writeoff`, data);
};
// 新版订单--   order039-产品强制核销(后台)-云玉峰http://192.168.1.50:40001/project/41/interface/api/3983
export const productWriteOffForce = data => {
  return http.post(`${prefix}/product/writeoff/force`, data);
};
// order039-门票核销(后台)-云玉峰
export const apiOrderTicketCheckCode = data => {
  return http.post(`${prefix}/ticket/check`, data);
};
//order039-门票批量核销(后台)-云玉峰
export const apiOrderTicketCheckArray = data => {
  return http.post(`${prefix}/ticket/check`, data);
};
//order048-取消核销(后台)-云玉峰
export const apiOrderTicketUncheck = data => {
  return http.post(`${prefix}/ticket/uncheck`, data);
};

//最新反核销接口 -云玉峰 http://192.168.1.50:40001/project/41/interface/api/4009
export const productTicketUncheck = data => {
  return http.post(`${prefix}/product/uncheck`, data);
};
// 零售 - 核销取货 -云玉峰 http://www.docway.net/project/1dfzzggTMh7/1hudBNokfx2
export const tickPickup = data => {
  return http.post(`${prefix}/ticket/pickup`, data);
};
// 兑换批量核销(新版票务)-云玉峰 http://192.168.1.50:40001/project/41/interface/api/4336
export const productWriteOffStrictBatch = data => {
  return http.post(`${prefix}/product/writeoff/strict/batch`, data);
};

/**
 * 项目核销
 */
// 项目核销接口-陈铭
export function itemWriteoff(data, config) {
  return http.post(`${prefix}/item/writeoff`, data, config);
}
//获取项目消费记录详情
export const getItemWriteOff = data => {
  return http.get(`${prefix}/itemwriteoff`, data);
};
//查看全部未结算记录
export const itemwriteoffUnfinsised = data => {
  return http.get(`${prefix}/itemwriteoff/unfinsised`, data);
};
//确认离场
export const itemwriteoffMadefinished = data => {
  return http.post(`${prefix}/itemwriteoff/madefinished`, data);
};
//确认清场
export const itemwriteoffAllmadefinished = data => {
  return http.post(`${prefix}/itemwriteoff/allmadefinished`, data);
};
/**
 *  @name: MS-畅游宝订单中心接口
 *  @version: 2.0
 *  @description: 待删除 =======
 **/

/**
 *订单接口
 */
// 新版-订单详情-云玉峰 http://192.168.1.50:40001/project/41/interface/api/3939
export const orderDetailInfoNew = data => {
  return http.get(`${prefix}/order/detail/info`, data);
};

// 新版订单  整单（退单)校验查询-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3967
export const refundOrderCheck = data => {
  return http.post(`${prefix}/refund/order/check`, data);
};
// 新版订单  整单（退单)-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3968
export const refundOrder = data => {
  return http.post(`${prefix}/refund/order`, data);
};
// 新版订单  整单强制退款（退单)-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3974
export const refundOrderForce = data => {
  return http.post(`${prefix}/refund/order/force`, data);
};
// 新版订单  产品退款校验查询-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3965
export const refundProductCheck = data => {
  return http.post(`${prefix}/refund/product/check`, data);
};
// 新版订单  产品退款-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3966
export const refundProduct = data => {
  return http.post(`${prefix}/refund/product`, data);
};
// 新版订单  强制产品退款-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3972
export const refundProductForce = data => {
  return http.post(`${prefix}/refund/product/force`, data);
};
// 新版订单  二级产品订单退款校验查询-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3970
export const refundSuborderCheck = data => {
  return http.post(`${prefix}/refund/suborder/check`, data);
};
// 新版订单  二级产品订单退款-云玉峰 - http://192.168.1.50:40001/project/41/interface/api/3971
export const refundSuborder = data => {
  return http.post(`${prefix}/refund/suborder`, data);
};

//订单列表分页查询-刘院民
export const findOrderPageList = data => {
  return http.get(`${prefix}/find/order/page/list`, data);
};
//订单详情查询接口-刘院民
export const findOrderDetail = data => {
  return http.get(`${prefix}/find/order_detail`, data);
};
//订单子产品详情查询-刘院民
export const findSubitemDetailById = data => {
  return http.get(`${prefix}/find/sub_item_detail/by/id`, data);
};
//订单下单创建订单-暂时未测通
export const createOrder = data => {
  return http.post(`${prefix}/create/order`, data);
};
//线下现金支付订单-刘院民
export const savePayOrder = data => {
  return http.post(`${prefix}/save/payOrder`, data);
};
//【综合套票和门票】订单详情-通知发送记录 -刘资涌
export const orderNotificationRecordList = data => {
  return http.get(`${prefix}/order/notification/record/list`, data);
};

//-订单下单后直接出票打印-云玉峰
export const orderPrint = data => {
  return http.post(`${prefix}/order/print`, data);
};
/**
 *退款接口
 */
// 推荐校验可退款金额信息-云玉峰
export const findRefundPrice = data => {
  return http.post(`${prefix}/find/refund/price`, data);
};
// 退款记录分页查询-云玉峰
export const findOrderRefundPageList = data => {
  return http.get(`${prefix}/find/order/refund/page/list`, data);
};
// 退款记录详情查询-云玉峰
export const findOrderRefundDetail = data => {
  return http.get(`${prefix}/find/order/refund/detail`, data);
};
// 窗口门票订单列表查询-云玉峰
export const findOrderTicketList = data => {
  return http.get(`${prefix}/find/order/ticket/list`, data);
};
// 根据订单号查询门票列表-云玉峰
export const findTicketList = data => {
  return http.get(`${prefix}/find/ticket/list`, data);
};

/**
 * 发票接口
 */

//查询分页发票申请列表-张胜飞
export const listInvoiceRecordProvider = data => {
  return http.get(`${prefix}/list/invoice/record/provider`, data);
};
//申请发票-张胜飞
export const saveInvoiceRecord = data => {
  return http.post(`${prefix}/save/invoice/record`, data);
};
//审核发票-张胜飞
export const updateInvoiceRecord = data => {
  return http.put(`${prefix}/update/invoice/record`, data);
};
//查询分页发票待申请列表-张胜飞
export const listInvoiceRecordPlatform = data => {
  return http.get(`${prefix}/list/invoice/record/platform`, data);
};

/**
 * 发票抬头接口
 **/

//新增发票抬头-张胜飞
export const saveTaxpayer = data => {
  return http.add(`${prefix}/save/taxpayer`, data);
};
//编辑发票抬头-张胜飞
export const updateTaxpayer = data => {
  return http.put(`${prefix}/update/taxpayer`, data);
};
//发票抬头查询-张胜飞
export const listTaxpayer = data => {
  return http.get(`${prefix}/list/taxpayer`, data);
};
//查询指定抬头-张胜飞
export const listTaxpayerById = data => {
  return http.post(`${prefix}/list/taxpayer/by/id`, data);
};
//删除发票抬头-张胜飞
export const deleteTaxpayer = data => {
  return http.delete(`${prefix}/delete/taxpayer`, data);
};
//设置默认发票抬头-张胜飞
export const updateDefault = data => {
  return http.put(`${prefix}/update/default`, data);
};

/**
 *订单支付
 */
//付款码支付-胡汉杰
export const tradePaymentInfoMicropay = (data, config) => {
  return http.get(`${prefix}/trade/payment/info/micropay`, data, config);
};
//付款码支付-玉峰 （支付重构版本）
export const tradePaymentInfoMicropayV2 = (data, config) => {
  return http.get(`${prefix}/trade/payment/info/micropay/v2`, data, config);
};
//order036-订单线下支付-云玉峰
export const payOfflineOrder = data => {
  return http.post(`${prefix}/pay/offline/order`, data);
};
// 订单-抵扣支付
export const payDeductionOrder = data => {
  return http.post(`${prefix}/pay/deduction/order`, data);
};
// 订单-押金支付
export const payCashpledgeOrder = data => {
  return http.post(`${prefix}/pay/cashpledge/order`, data);
};
// 订单-挂账支付
export const payOncreditOrder = data => {
  return http.post(`${prefix}/pay/oncredit/order`, data);
};
//获取支付状态-胡汉杰
export const tradePaymentInfoStatus = (data, config) => {
  return http.get(`${prefix}/trade/payment/info/status`, data, config);
};

/**
 * 门票接口
 */
// 门票附加产品列表-云玉峰
export const findTicketAdditionalList = data => {
  return http.get(`${prefix}/find/ticket/additional/list`, data);
};
// 门票附加产品详情-云玉峰
export const findTicketAdditionalDetail = data => {
  return http.get(`${prefix}/find/ticket/additional/detail`, data);
};
// 门票详情查询-云玉峰
export const findTicketDetailInfo = data => {
  return http.get(`${prefix}/find/ticket/detail/info`, data);
};
// 门票综合套票子产品列表-云玉峰
export const findTicketPackageList = data => {
  return http.get(`${prefix}/find/ticket/package/list`, data);
};
// 门票快票赠送子产品列表-云玉峰
export const findTicketFastList = data => {
  return http.get(`${prefix}/find/ticket/fast/list`, data);
};
// 门票推荐加购子产品列表-云玉峰
export const findTicketRecommendList = data => {
  return http.get(`${prefix}/find/ticket/recommend/list`, data);
};
// 门票充值加购子产品列表-云玉峰
export const findTicketRechargeList = data => {
  return http.get(`${prefix}/find/ticket/recharge/list`, data);
};
// 门票子产品详情-项目套餐-云玉峰
export const findTicketPlayItemPackageDetail = data => {
  return http.get(`${prefix}/find/ticket/playitempackage/detail`, data);
};
// 门票子产品详情-资产套餐-云玉峰
export const findTicketConsumetypepackageDetail = data => {
  return http.get(`${prefix}/find/ticket/consumetypepackage/detail`, data);
};
// 门票子产品详情-门票-云玉峰
export const findTicketSubTicketDetail = data => {
  return http.get(`${prefix}/find/ticket/subticket/detail`, data);
};
// 门票子产品详情-零售、酒店、普通、其它-云玉峰
export const findTicketAdditionalitemCommonDetail = data => {
  return http.get(`${prefix}/find/ticket/additionalitem/common/detail`, data);
};
// 门票账户产品详情-项目套餐详情-云玉峰
export const findTicketWalletPlayItemPackageDetail = data => {
  return http.get(`${prefix}/find/ticketwallet/playitempackage/detail`, data);
};
// 门票腕带信息查询-云玉峰
export const findTicketVoucherInfo = data => {
  return http.get(`${prefix}/find/ticket/voucher/info`, data);
};
// 门票补办历史记录查询-云玉峰
export const findTicketRebindList = data => {
  return http.get(`${prefix}/find/ticket/rebind/list`, data);
};
// 取票查询-云玉峰
export const findTicketPrintList = data => {
  return http.get(`${prefix}/find/ticket/print/list`, data);
};
// 取票记录保存-云玉峰
export const ticketPrinted = data => {
  return http.get(`${prefix}/ticket/printed`, data);
};
// 门票充值接口-开发中
export const ticketRecharge = data => {
  return http.post(`${prefix}/ticket/recharge`, data);
};
// 推荐加购|门票充值记录列表-刘院民
export const listTicketRecharge = data => {
  return http.get(`${prefix}/list/ticket/recharge`, data);
};
// 门票充值记录详情-刘院民
export const findTicketRechargePackage = data => {
  return http.get(`${prefix}/find/ticket/recharge/package`, data);
};
// 门票账户概况查询-刘院民
export const findTicketRechargeOverview = data => {
  return http.get(`${prefix}/find/ticket/recharge/overview`, data);
};
// 快票通行信息查询-刘院民
export const fastTicketList = data => {
  return http.get(`${prefix}/fast/ticket/list`, data);
};
// 票号/卡或腕带号查询门票信息-刘院民
export const findTicketInfo = data => {
  return http.get(`${prefix}/find/ticket/info`, data);
};

/**
 * 门票核销
 */
// 门票核销接口-邓小春
export const ticketCheck = data => {
  return http.update(`${prefix}/ticket/check`, data);
};
// 门票核销记录列表接口-邓小春
export const pageListCheckRecord = data => {
  return http.get(`${prefix}/page/list/checkrecord`, data);
};
// 门票核销记录列表接口(不分页)-邓小春
export const listCheckRecord = data => {
  return http.get(`${prefix}/list/all/checkrecord`, data);
};
// 门票强制核销接口-邓小春
export const ticketForceCheck = data => {
  return http.update(`${prefix}/ticket/force/check`, data);
};

// 门票批量核销接口-邓小春
export const batchTicketCheck = data => {
  return http.update(`${prefix}/batch/ticket/check`, data);
};

// 门票批量强制核销接口-邓小春
export const batchTicketForceCheck = data => {
  return http.update(`${prefix}/batch/ticket/force/check`, data);
};

// 通过码号（订单号、手机号、票号）查询门票列表-邓小春
export const listTicketBycode = data => {
  return http.get(`${prefix}/list/ticket/bycode`, data);
};

/** 会员充值 */
// 会员充值 -刘资涌
export const apiOrderCybCreateOrder = data => {
  return http.post(`${prefix}/create/order`, data);
};

/**
 *订单记录修改接口
 */
// 修改订单中预约时段-张胜飞
export const updateChangeRecord = data => {
  return http.put(`${prefix}/update/change/record`, data);
};
// 订单修改记录-张胜飞
export const listChangeRecord = data => {
  return http.get(`${prefix}/list/change/record`, data);
};

/**
 *特殊操作
 */
// 门票订单列表分页查询-邓小春
export const pageListTicket = data => {
  return http.get(`${prefix}/page/list/ticket`, data);
};
// 门票强制退款-邓小春
export const specialForceRefund = data => {
  return http.post(`${prefix}/special/force/refund`, data);
};
// 门票反核销接口-邓小春
export const ticketUncheck = data => {
  return http.post(`${prefix}/ticket/uncheck`, data);
};

/**
 *项目消费
 */
// 项目消费接口-邓小春
export const playItemConsume = data => {
  return http.post(`${prefix}/playitem/consume`, data);
};
// 门票的项目消费记录接口-邓小春
export const listTicketPlayItemConsumeRecord = data => {
  return http.get(`${prefix}/list/ticket/playitem/consumerecord`, data);
};
// 终端项目消费记录接口-张胜飞
export const listPlayItemConsumeRecord = data => {
  return http.get(`${prefix}/list/play/item/consume/record`, data);
};
// 终端项目消费记录接口-张胜飞
export const findPlayItemConsumeRecord = data => {
  return http.get(`${prefix}/find/play/item/consume/record`, data);
};
// 项目核销基础信息汇总-张胜飞
export const findPlayItemConsumeStatistics = data => {
  return http.get(`${prefix}/find/play/item/consume/statistics`, data);
};
// 项目汇总详情-张胜飞-张胜飞
export const listPlayItemConsumeRecordTotal = data => {
  return http.get(`${prefix}/list/play/item/consume/record/total`, data);
};
// 本日接待人次-陈铭
export const getItemwriteoffCount = data => {
  return http.get(`${prefix}/itemwriteoff/count`, data);
};
// 消费类型汇总-陈铭
export const getItemwriteoffConsumesummary = data => {
  return http.get(`${prefix}/itemwriteoff/consumesummary`, data);
};
// 项目套餐消费汇总-陈铭
export const getItemwriteoffSynthesizeitemsummary = data => {
  return http.get(`${prefix}/itemwriteoff/synthesizeitemsummary`, data);
};
// order101-消费汇总人次列表-张胜飞
export const listOrderItemCollectBaseInfo = data => {
  return http.get(`${prefix}/list/order/item/collect/base/info`, data);
};
// order102-消费类型汇总-张胜飞
export const listOrderItemConsumeTypeCollectInfo = data => {
  return http.get(`${prefix}/list/order/item/consume/type/collect/info`, data);
};
// order-103-项目套餐消费汇总-张胜飞
export const listOrderItemPackageConsumeCollectInfo = data => {
  return http.get(
    `${prefix}/list/order/item/package/consume/collect/info`,
    data
  );
};

/**
 *项目反充操作
 */
// 新增项目反充记录-张胜飞
export const savePlayItemReverseRecord = data => {
  return http.post(`${prefix}/save/play/item/reverse/record`, data);
};

/**
 * 零售端 - 交账
 */
// 确认交账-获取销售汇总-陈铭
export const handoverAccountSales = data => {
  return http.get(`${prefix}/handover/account/sales`, data);
};
// 设置员工上班时间-陈铭
export const handoverAccountClockIn = data => {
  return http.post(`${prefix}/handover/account/clock/in`, data);
};
// 获取员工上下班时间-陈铭
export const getHandoverAccountClockIn = data => {
  return http.get(`${prefix}/handover/account/clock/in`, data);
};

//申请反充  -陈铭
export const itemwriteOffBack = data => {
  return http.post(`${prefix}/itemwriteoff/back`, data);
};

// 批量项目核销 -陈铭
export const postWriteoffs = data => {
  return http.post(`${prefix}/item/writeoffs`, data);
};

/**
 *租赁
 */
// 押金变更详情
export const orderProductLeaseDepostitChangeRecord = data => {
  return http.get(`${prefix}/order/product/lease/depostit/change/record`, data);
};

/*租赁 */
// 【A02002】通过租赁凭证获取押金信息(胡汉杰)   http://192.168.1.50:40001/project/41/interface/api/2139
export const getLeaseDeposit = data => {
  return http.get(`${prefix}/lease/deposit`, data);
};

//【A02004】确定租赁 生成物品租赁记录 (许家炜)   http://192.168.1.50:40001/project/41/interface/api/2094
export const determineProductLeaseInfo = data => {
  return http.post(`${prefix}/determine/product/lease/info`, data);
};

// 通过项目消费编号获取会员信息-胡汉杰
export const itemConsumerMember = data => {
  return http.get(`${prefix}/item/consumer/member`, data);
};

//通过票号查询门票名称
export const orderDetailProductcategory = data => {
  return http.get(`${prefix}/order/detail/productcategory`, data);
};
//场次票换座接口 http://192.168.1.50:40001/project/55/interface/api/3661
export const changeSeat = data => {
  return http.post(`${prefix}//change/seat`, data);
};

//场次票取票后调用此接口改订单状态的  -云玉峰
export const ticketTake = data => {
  return http.post(`${prefix}/ticket/take`, data);
};
//快速下单获取售票记录
export const productSnList = data => {
  return http.get(`${prefix}/product/sn/list`, data);
};
//快速下单获取售票记录 http://192.168.1.50:40001/project/41/interface/api/3895
export const createOrderCart = data => {
  return http.post(`${prefix}/create/order/cart`, data);
};
// 【order128】极速下单 http://192.168.1.50:40001/project/41/interface/api/3891
export const createQuickOrder = data => {
  return http.post(`${prefix}/create/quick/order`, data);
};

//订单产品列表
export const findOrderTicketVoucher = data => {
  return http.get(`${prefix}/find/order/ticket/voucher`, data);
};
//根据社保卡获取游客信息 许家炜 http://192.168.1.50:40001/project/41/interface/api/3962
export const socialSecurityCardGetTouristInfoWindow = data => {
  return http.get(
    `${prefix}/social/security/card/get/tourist/info/window`,
    data
  );
};
//查询产品的自定义打印规则前需要展示的信息 http://192.168.1.50:40001/project/41/interface/api/4189
export const findDefindPrintInfo = data => {
  return http.get(`${prefix}/find/defind/print/info`, data);
};
// 批量保存产品自定义规则修改后的信息 http://192.168.1.50:40001/project/41/interface/api/4192
export const saveDefindPrintInfo = data => {
  return http.post(`${prefix}/save/defind/print/info`, data);
};
//查询相关产品已核销列表 http://192.168.1.50:40001/project/41/interface/api/3947
export const quickOrderProductWrittenOffList = data => {
  return http.get(`${prefix}/quick/order/product/written/off/list`, data);
};
//查询相关产品押金金额 http://192.168.1.50:40001/project/41/interface/api/3926
export const quickOrderProductDepositAmount = data => {
  return http.post(`${prefix}/quick/order/product/deposit/amount`, data);
};
//修改游乐快速下单核销数据的状态 http://192.168.1.50:40001/project/41/interface/api/3934
export const updateQuickOrderWriteOffStatus = data => {
  return http.put(`${prefix}/update/quick/order/write/off/status`, data);
};
//产品消费计时结束退款 http://192.168.1.50:40001/project/41/interface/api/4273
export const timingFinishRefund = (data, config) => {
  return http.put(`${prefix}/timing/finish/refund`, data, config);
};
//兑换券支付 http://192.168.1.50:40001/project/41/interface/api/4369
export const payCouponOrder = data => {
  return http.post(`${prefix}/pay/coupon/order`, data);
};
//清账查询 http://192.168.1.50:40001/project/41/interface/api/4387
export const findOncreditOrderInfo = data => {
  return http.get(`${prefix}/find/oncredit/order/info`, data);
};
//清账 http://192.168.1.50:40001/project/41/interface/api/4384
export const settleOncreditOrder = data => {
  return http.post(`${prefix}/settle/oncredit/order`, data);
};
//清账查询 http://192.168.1.50:40001/project/41/interface/api/4417
export const findOncreditOrderList = data => {
  return http.get(`${prefix}/find/oncredit/order/list`, data);
};
