<!--
  @name: 单图片上传组件
  @author: 邓良友

  @attr: {
    // 图片地址
    imageUrl: {
      type: String,
      default: ""
    },
    // 上传的格式提示
    tip: {
      type: String,
      default: "仅支持jpg/png格式，小于2M"
    },
    // 默认2M
    size: {
      type: Number,
      default() {
        return 2;
      }
    },
    // 接受的文件类型
    accept: {
      type: String,
      default: ".jpg,.png"
    },
    // 限制的图片宽度，默认不限制
    width: {
      type: Number,
      default() {
        return 0;
      }
    },
    // 限制的图片高度，默认不限制
    height: {
      type: Number,
      default() {
        return 0;
      }
    },
    // 限制多种规格图片宽度，默认不限制。如同时限制1920宽，又限制600宽，则传入[1920,600]
    widthList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 限制多种规格图片高度,默认不限制
    heightList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 缩略图预览宽度
    previewWidth: {
      type: String,
      default: "100px"
    },
    // 缩略图预览高度
    previewHeight: {
      type: String,
      default: "100px"
    },
  }
  // 插槽
  @slot: {
    tip: "上传提示"
  }
  @event: {
    change: "上传图片成功"
  }
-->
<template>
  <div class="flex-start">
    <a-upload
      name="file"
      list-type="picture-card"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :customRequest="uploadImage"
      :accept="accept"
      @change="handleChange"
      @preview="handlePreview"
    >
      <div v-if="fileList.length === 0">
        <a-icon type="plus" />
        <div class="ant-upload-text">点击上传</div>
      </div>
    </a-upload>
    <div class="ml-2x" style="line-height: 1.5">
      <slot name="tip">{{ tip }}</slot>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img class="res" :src="previewImage" alt="加载中" />
    </a-modal>
  </div>
</template>
<script>
import { fileUpload } from "@/api/upload"; // MS-文件上传接口

export default {
  // 自定义组件的双向数据绑定
  model: {
    prop: "imageUrl",
    event: "change"
  },
  props: {
    // 图片地址
    imageUrl: {
      type: String,
      default: ""
    },
    // 上传的格式提示
    tip: {
      type: String,
      default: "仅支持JPG/PNG格式，小于2M"
    },
    // 默认2M
    size: {
      type: Number,
      default() {
        return 2;
      }
    },
    // 接受的文件类型
    accept: {
      type: String,
      default: ".jpg,.png"
    },
    // 限制的图片宽度，默认不限制
    width: {
      type: Number,
      default() {
        return 0;
      }
    },
    // 限制的图片高度，默认不限制
    height: {
      type: Number,
      default() {
        return 0;
      }
    },
    // 限制多种规格图片宽度，默认不限制。如同时限制1920宽，又限制600宽，则传入[1920,600]
    widthList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 限制多种规格图片高度,默认不限制
    heightList: {
      type: Array,
      default() {
        return [];
      }
    },
    // 缩略图预览宽度
    previewWidth: {
      type: String,
      default: "100px"
    },
    // 缩略图预览高度
    previewHeight: {
      type: String,
      default: "100px"
    }
  },
  data() {
    return {
      previewVisible: false, // 是否显示预览图
      previewImage: "", // 预览图图片地址
      fileList: [] // 文件列表
    };
  },
  // 监听图片地址变化
  watch: {
    imageUrl: {
      handler(newVal) {
        this.previewImage = newVal;
        if (this.previewImage === "") {
          this.fileList = [];
        } else if (this.previewImage) {
          this.fileList = [
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: this.previewImage
            }
          ];
        }
      },
      immediate: true
    }
  },
  methods: {
    // 图片变更
    handleChange({ fileList }) {
      this.fileList = fileList;
      if (this.fileList.length === 0) {
        this.$emit("change", "");
      }
    },
    // 图片预览
    async handlePreview(file) {
      this.previewImage = file.url;
      this.previewVisible = true;
    },
    // 图片取消上传
    handleCancel() {
      this.previewVisible = false;
    },
    // 上传前
    beforeUpload(file) {
      const that = this;
      that.fileList = []; // 每次上传，先清空上次fileList，以达到覆盖上传
      const reader = new FileReader(); // 创建文件读取对象
      reader.readAsDataURL(file); // 所读取文件的内容
      return new Promise((resolve, reject) => {
        reader.onload = function() {
          const image = new Image(); // 创建图像对象
          image.src = this.result;
          image.onload = function() {
            if (that.width > 0 && this.width != that.width) {
              that.$message.error(
                `上传的图片尺寸不符，规格为 ${that.width}x${that.height}`
              );
              reject();
            } else if (that.height > 0 && this.height != that.height) {
              that.$message.error(
                `上传的图片尺寸不符，规格为 ${that.width}x${that.height}`
              );
              reject();
            }
            if (that.widthList.length || that.heightList.length) {
              if (
                !that.widthList.includes(this.width) ||
                !that.heightList.includes(this.height)
              ) {
                that.$message.error("上传的图片尺寸不符");
                reject();
              }
            }
            resolve();
          };
        };
        // 限制图片上传的类型
        const acceptType = file.name
          .substring(file.name.lastIndexOf("."))
          .toLowerCase();
        const isAcceptType = this.accept.split(",").includes(acceptType);
        if (!isAcceptType) {
          that.$message.error(`上传的图片格式只能是 ${this.accept}格式！`);
          reject();
        }
        // 限制图片上传的大小
        let fileSize = that.size, // 图片大小
          msg = ""; // 提示语
        if (fileSize > 10) {
          msg = fileSize / 1024 + "M";
        } else {
          msg = fileSize + "M";
          fileSize = fileSize * 1024;
        }
        if (file.size / 1024 > fileSize) {
          that.$message.error(`上传的图片大小不能超过 ${msg}！`);
          reject();
        }
      });
    },
    // 上传图片
    uploadImage(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "open-d": localStorage.getItem("token")
        }
      };
      fileUpload(formData, config)
        .then(res => {
          this.previewImage = res.src;
          this.$emit("change", res.src);
          this.fileList = [
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: this.previewImage
            }
          ];
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";
/deep/.ant-upload-picture-card-wrapper {
  width: auto;
}

/deep/.ant-upload-list-picture-card-container,
/deep/.ant-upload-select-picture-card {
  width: 128px;
  height: 128px;
  .ant-upload-list-item {
    width: 128px;
    height: 128px;
  }
}

/deep/.ant-upload-select-picture-card {
  i {
    font-size: 32px;
    color: @gray;
  }
  .ant-upload-text {
    margin-top: 8px;
  }
}
</style>
