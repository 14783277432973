/**
 *@name: 商户收银路由
 */
export default [
  {
    path: "/retail",
    component: () =>
      import(
        /* webpackChunkName: "chuck-retail" */ "../views/retail/RetailIndex.vue"
      ),
    children: [
      /**
       *零售
       */
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "chuck-retail" */ "../views/retail/sell/SellGoods.vue"
          ),
        children: [
          //零售商品列表
          {
            path: "",
            name: "retailSellerList",
            component: () =>
              import(
                /* webpackChunkName: "chuck-retail" */ "../views/retail/sell/GoodsList.vue"
              )
          }
        ]
      },

      /**
       *核销取货
       */
      //核销取货-门票查询
      {
        path: "/retail/take/pickTicketSearch",
        name: "PickTicketSearch",
        component: () =>
          import(
            /* webpackChunkName: "chuck-retail" */ "../views/retail/take/PickTicketSearch.vue"
          )
      },
      //核销取货列表
      {
        path: "/retail/take/pickUp",
        name: "PickUp",
        component: () =>
          import(
            /* webpackChunkName: "chuck-retail" */ "../views/retail/take/PickUp.vue"
          )
      },

      // 零售-标准版
      {
        path: "/retail/sell/SellProduct",
        name: "SellProduct",
        component: () =>
          import(
            /* webpackChunkName: "chuck-retail" */ "../views/retail/sell/SellProduct.vue"
          )
      }
    ]
  }
];
