import store from "../store";
/**
 * @description 判断当前权限是否存在
 */
function hasPermission(currentPermission) {
  const currentBtnPermissionList =
    store.getters["common/getCurrentBtnPermission"];
  if (!currentBtnPermissionList || !currentBtnPermissionList.length) {
    return true;
  }
  return currentBtnPermissionList.some(item => item === currentPermission);
}

export default {
  inserted(el, binding) {
    if (!hasPermission(binding.value)) {
      // el.parentNode.removeChild(el);
    }
  }
};
