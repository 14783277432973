/**
 * MS-购物车接口 3.0
 */
import http from "../utils/fetch"; // 公共请求方法

const prefix = "/api/cart"; // api前缀

// 【cart001】添加产品到购物车-刘资涌
export function addShoppingCart(data) {
  return http.add(`${prefix}/shopping/cart`, data);
}

// 【cart002】更新购物车-刘资涌
export function updateShoppingCart(data) {
  return http.put(`${prefix}/shopping/cart`, data);
}

// 【cart005】挂单购物车列表 -刘资涌
export function shoppingCartList(data) {
  return http.get(`${prefix}/shopping/cart/list`, data);
}

// 【cart006】挂单 -刘资涌
export function pauseCart(data) {
  return http.put(`${prefix}/pause/cart`, data);
}

//【cart007】取单中的删除/整单取消/更改游玩日期 -刘资涌
export const deleteOrCancel = data => {
  return http.delete(`${prefix}/delete/or/cancel`, data);
};
// 【cart001】门票充值，查询票号并生成购物车 -刘资涌
export const ticketRecharge = data => {
  return http.get(`${prefix}/recharge`, data);
};
// 门票充值 下单
export const manyProduct = data => {
  return http.post(`${prefix}/many/product`, data);
};

// 【cart001】展示单个购物车商品 / 取单 -刘资涌
export function shoppingCart(data) {
  return http.get(`${prefix}/shopping/cart`, data, { loading: false });
}

// 取单 -刘资涌
export function takeOut(data) {
  return http.get(`${prefix}/take/out`, data);
}
// 【cart001】取票和补办 -刘资涌
export function getTicketOrReissue(data) {
  return http.post(`${prefix}/get/ticket/or/reissue`, data);
}

//点击加购推荐产品之后展示的列表 -刘资涌
export function addPurchaseProduct(data) {
  return http.get(`${prefix}/add/purchase/product`, data);
}

//登记信息并结账按钮 -刘资涌
export function registerSettleAccounts(data) {
  return http.get(`${prefix}/register/settle/accounts`, data);
}

//校验并保存前端传来的出游人信息 -刘资涌
export function visitorFieldInfo(data) {
  return http.add(`${prefix}/visitor/field/info`, data);
}

//保存前端传来的优惠信息 -刘资涌
export function discountInfoCalculate(data) {
  return http.post(`${prefix}/discount/info/calculate`, data);
}

// 添加多个产品，创建购物车  -资涌     -YApi
export const createCart = data => {
  return http.post(`${prefix}/create/cart`, data);
};

// 批量取票 - 有押金走下单接口 刘资涌  -YApi
export const batchGetTicket = data => {
  return http.post(`${prefix}/batch/get/ticket`, data);
};

// 租赁归还物品-刘资涌
export const returnProduct = data => {
  return http.post(`${prefix}/return/product`, data);
};

// 通过商户id查询所有的购物车 刘资涌  -YApi
export const merchantAllCart = data => {
  return http.get(`${prefix}/merchant/all/cart`, data);
};
// order021-产品补办前查询-云玉峰 new-http://192.168.1.50:40001/project/41/interface/api/4004
export const commonReissue = data => {
  return http.post(`${prefix}/common/reissue`, data);
};
/**
 * 餐饮购物车接口  3.0
 */
// 添加购物车-刘资勇
export const foodCart = data => {
  return http.add(`${prefix}/food/cart`, data);
};
// 往已有购物车中添加新产品和数量加减-刘资勇
export const moreProduct = data => {
  return http.add(`${prefix}/more/product`, data);
};
// 查询购物车详情 通过购物车id或者区域桌台id，展示已出单和未出单-刘资勇
export const foodCartDetail = (data, config) => {
  return http.get(`${prefix}/food/cart/detail`, data, config);
};
// 清空购物车 只清空已出单或者未出单-刘资勇
export const emptyCart = data => {
  return http.post(`${prefix}/empty/cart`, data);
};
// 结账接口 - 窗口端专用 -刘资勇
export const settleAccounts = data => {
  return http.post(`${prefix}/settle/accounts`, data);
};
// 套餐重新选择 -刘资勇
export const reselectSet = data => {
  return http.post(`${prefix}/reselect/set`, data);
};
// 清桌 -刘资勇
export const clearTable = data => {
  return http.put(`${prefix}/clear/table`, data);
};
// 重新打印 -刘资勇
export const printAgain = data => {
  return http.post(`${prefix}/print/again`, data);
};

//窗口 修改门票的销售价
export const changePrice = data => {
  return http.put(`${prefix}/change/price`, data);
};

/**
 * 剧院购物车接口  3.0
 */
// 小程序和窗口端 查看所有锁座了的座位 -刘资勇
export const theaterSeatLock = data => {
  return http.get(`${prefix}/theater/seat/lock`, data);
};
// 小程序和窗口端 添加剧院购物车 -刘资勇
export const theaterCart = data => {
  return http.add(`${prefix}/theater/cart`, data);
};
// 小程序和窗口端 添加新剧场票、重新选座、删除  -刘资勇
export const theaterMore = data => {
  return http.post(`${prefix}/theater/more`, data);
};
// 窗口端 详情接口 -刘资勇
export const theaterDetail = data => {
  return http.get(`${prefix}/theater/detail`, data);
};
// 窗口端 结账接口  -刘资勇
export const theaterSettleAccounts = data => {
  return http.post(`${prefix}/theater/settle/accounts`, data);
};
// 窗口端 添加加购产品
export const theaterAddBuy = data => {
  return http.add(`${prefix}/theater/add/buy`, data);
};

// 窗口修改打印规则 - 刘资涌  http://192.168.1.50:40001/project/41/interface/api/3651
export const cartChangePrint = data => {
  return http.put(`${prefix}/change/print`, data);
};

//窗口修改打印规则回显  - 刘资涌   http://192.168.1.50:40001/project/41/interface/api/3684
export const cartChangePrintInfo = data => {
  return http.get(`${prefix}/change/print`, data);
};
// 窗口端 快速下单-刘资勇
export const fastOrder = data => {
  return http.add(`${prefix}/fast/order`, data);
};

// 窗口端 组合产品添加购物车-刘资勇
export const multiCart = data => {
  return http.post(`${prefix}/multi/cart`, data);
};
// 窗口端 更新购物车-刘资勇
export const moreMultiCart = data => {
  return http.post(`${prefix}/more/multi/cart`, data);
};
// 窗口端-删除产品-刘资勇
export const deleteMultiCart = data => {
  return http.post(`${prefix}/delete/multi/cart`, data);
};
// 窗口端快速下单 购物车-下单二合一-刘资勇
export const cbsFastOrder = data => {
  return http.post(`${prefix}/cbs/fast/order`, data);
};
// 加购 更新 购物车 刘资涌
export const addBuyUpdateCartText = data => {
  return http.post(`${prefix}/small/application/add/buy`, data);
};
//组合产品 小程序 会返回合并出游人的购物车详情
export const getBuyCartText = data => {
  return http.get(`${prefix}/small/application/multi/cart`, data);
};
//判断游客购买限制
export const visitorBuyLimit = data => {
  return http.post(`${prefix}/visitor/buy/limit`, data);
};
//惠民政策，通过目标产品找到多个特惠产品让前端选择
export const selectDiscountSku = data => {
  return http.get(`${prefix}/select/discount/sku`, data);
};
//票务 - 小程序多个游客 http://192.168.1.50:40001/project/41/interface/api/4147
export const ticketCommonlyVisitiorBatch = data => {
  return http.post(`${prefix}/ticket/commonly/visitor/batch`, data);
};
//组合产品 - 小程序多个游客 http://192.168.1.50:40001/project/41/interface/api/4147
export const combinationVisitiorBatch = data => {
  return http.post(`${prefix}/combination/commonly/visitor/batch`, data);
};
//添加票务产品到购物车
export const cartTicket = data => {
  return http.post(`${prefix}/ticket`, data);
};
//更新产品到购物车
export const ticketMore = data => {
  return http.post(`${prefix}/ticket/more`, data);
};
//修改使用日期 http://192.168.1.50:40001/project/41/interface/api/3898
export const ticketDate = data => {
  return http.post(`${prefix}/ticket/date`, data);
};
//更新购物车时段 http://192.168.1.50:40001/project/41/interface/api/3899
export const ticketAppointmentDate = data => {
  return http.post(`${prefix}/ticket/appointment/date`, data);
};
//更新出游人信息 http://192.168.1.50:40001/project/41/interface/api/3900
export const ticketTraveller = data => {
  return http.post(`${prefix}/ticket/traveller`, data);
};
//修改加购数量 http://192.168.1.50:40001/project/41/interface/api/3901
export const postTicketAddBuy = data => {
  return http.post(`${prefix}/ticket/add/buy`, data);
};
//更新快票开关 http://192.168.1.50:40001/project/41/interface/api/3908
export const ticketFast = data => {
  return http.post(`${prefix}/ticket/fast`, data);
};
//查询加购产品列表 http://192.168.1.50:40001/project/41/interface/api/3916
export const getTicketAddBuy = data => {
  return http.get(`${prefix}/ticket/add/buy`, data);
};
//查询加购产品列表 http://192.168.1.50:40001/project/41/interface/api/3916
export const ticketGroupGuide = data => {
  return http.post(`${prefix}/ticket/group/guide`, data);
};
//修改产品的有效期 http://192.168.1.50:40001/project/41/interface/api/3920
export const ticketSpecialInfo = data => {
  return http.post(`${prefix}/ticket/special/info`, data);
};
//挂单 http://192.168.1.50:40001/project/41/interface/api/3931
export const commonPauseCart = data => {
  return http.post(`${prefix}/common/pause/cart`, data);
};
// 取单 http://192.168.1.50:40001/project/41/interface/api/3930
export const commonTakeOut = data => {
  return http.get(`${prefix}/common/take/out`, data);
};
// 取单列表 展示购物车商品 列表 （不展示当前正在展示的） http://192.168.1.50:40001/project/41/interface/api/3929
export const commonCartList = data => {
  return http.get(`${prefix}/common/cart/list`, data);
};
// 删除整个购物车 http://192.168.1.50:40001/project/41/interface/api/3932
export const commonDeleteCart = data => {
  return http.post(`${prefix}/common/delete/cart`, data);
};
// 无产品收银（直接收款和补差价） http://192.168.1.50:40001/project/41/interface/api/3941
export const commonNoProduct = data => {
  return http.post(`${prefix}/common/no/product`, data);
};
// 修改打印规则 http://192.168.1.50:40001/project/41/interface/api/3945
export const commonPrintWay = data => {
  return http.post(`${prefix}/common/print/way`, data);
};
// 修改打印规则产品列表接口 http://192.168.1.50:40001/project/41/interface/api/3944
export const commonPrintProductList = data => {
  return http.get(`${prefix}/common/print/product/List`, data);
};
// 新的会员充值 http://192.168.1.50:40001/project/41/interface/api/3951
export const memberRecharge = data => {
  return http.post(`${prefix}/member/recharge`, data);
};
// 修改会员或者会员密码 http://192.168.1.50:40001/project/41/interface/api/3948
export const commonMember = data => {
  return http.post(`${prefix}/common/member`, data);
};
// 保存修改优惠信息 http://192.168.1.50:40001/project/41/interface/api/3954
export const commonDiscount = data => {
  return http.post(`${prefix}/common/discount`, data);
};
// 窗口端新版修改销售价 http://192.168.1.50:40001/project/41/interface/api/3938
export const commonChangePrice = data => {
  return http.post(`${prefix}/common/change/price`, data);
};
// 组合产品 - 添加购物车，参数和票务一样 http://192.168.1.50:40001/project/41/interface/api/3986
export const combination = data => {
  return http.post(`${prefix}/combination`, data);
};
// 组合产品 - 增加新产品和改数量 http://192.168.1.50:40001/project/41/interface/api/3987
export const combinationMore = data => {
  return http.post(`${prefix}/combination/more`, data);
};
// 组合产品 - 修改使用日期 http://192.168.1.50:40001/project/41/interface/api/3989
export const combinationDate = data => {
  return http.post(`${prefix}/combination/date`, data);
};
// 组合产品 - 修改选择时段 http://192.168.1.50:40001/project/41/interface/api/3990
export const combinationAppointmentDate = data => {
  return http.post(`${prefix}/combination/appointment/date`, data);
};
// 组合产品 - 组合产品 - 打开或关闭快票 http://192.168.1.50:40001/project/41/interface/api/3991
export const combinationFast = data => {
  return http.post(`${prefix}/combination/fast`, data);
};
// 组合产品 - 修改单个出游人的单个信息 http://192.168.1.50:40001/project/41/interface/api/3992
export const combinationTraveller = data => {
  return http.post(`${prefix}/combination/traveller`, data);
};
// 组合产品 - 修改加购产品的数量 http://192.168.1.50:40001/project/41/interface/api/3993
export const combinationAddBuy = data => {
  return http.post(`${prefix}/combination/add/buy`, data);
};
// 门票激活接口，查询门票需要填写的出游人信息  http://192.168.1.50:40001/project/41/interface/api/3997
export function activateTravellerInfo(data) {
  return http.get(`${prefix}/activate/traveller/info`, data);
}
// 新版 零售加入购物车
export function addRetailCart(data) {
  return http.post(`${prefix}/retail`, data);
}
// 新版 零售更新购物车
export function updateRetailCart(data) {
  return http.post(`${prefix}/retail/more`, data);
}

//新版-零售 -更新出游人信息 http://192.168.1.50:40001/project/41/interface/api/4126
export const retailTraveller = data => {
  return http.post(`${prefix}/retail/traveller`, data);
};
//新版-零售 -更新价格信息 http://192.168.1.50:40001/project/41/interface/api/3938
export const retailChangePrice = data => {
  return http.post(`${prefix}/common/change/price`, data);
};
//票务和组合 - 批量给产品添加加购 http://192.168.1.50:40001/project/41/interface/api/4237
export const commonAddBuyBatch = data => {
  return http.post(`${prefix}/common/add/buy/batch`, data);
};
//游乐 - 添加购物车 http://192.168.1.50:40001/project/41/interface/api/4162
export const cartPlay = data => {
  return http.post(`${prefix}/play`, data);
};
//游乐 - 更新购物车 http://192.168.1.50:40001/project/41/interface/api/4165
export const playMore = data => {
  return http.post(`${prefix}/play/more`, data);
};
//游乐 - 修改使用日期 http://192.168.1.50:40001/project/41/interface/api/4168
export const playDate = data => {
  return http.post(`${prefix}/play/date`, data);
};
//游乐 - 修改选择时段 http://192.168.1.50:40001/project/41/interface/api/4171
export const playAppointmentDate = data => {
  return http.post(`${prefix}/play/appointment/date`, data);
};
//游乐 - 修改单个出游人的单个信息 http://192.168.1.50:40001/project/41/interface/api/4177
export const playTraveller = data => {
  return http.post(`${prefix}/play/traveller`, data);
};
// 会员特权支付
export const privilegeOrder = data => {
  return http.post(`${prefix}/member/privilege`, data);
};
// 通用查询购物车详情-刘资涌 http://192.168.1.50:40001/project/41/interface/api/3984
export const cartCommonDetail = data => {
  return http.get(`${prefix}/common/detail`, data);
};
// 剧院 - 增加购物车-刘资涌 http://192.168.1.50:40001/project/41/interface/api/3984
export const cartTheater = data => {
  return http.post(`${prefix}/theater`, data);
};
