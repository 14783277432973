/**
 *设置
 */
import store from "../index";

let getEveryConfigFn = (state, typeStr) => {
  let obj = {};
  if (typeStr === "get") {
    for (const key in state) {
      obj[`${typeStr}${key}`] = s => {
        return s[key];
      };
    }
  } else {
    for (const key in state) {
      obj[`${typeStr}${key}`] = (s, params) => {
        s[key] = params;
      };
    }
  }
  return obj;
};

const state = {
  userIdentity: 0, //用户身份  0=售票窗口  1=商户  2=项目

  //票务- 页面布局
  appNewHomeIsShowGroupOrder:
    localStorage.getItem("appNewHomeIsShowGroupOrder") === "true", //票务 -  团体下单展示
  appNewHomeIsShowProductClass:
    localStorage.getItem("appNewHomeIsShowProductClass") === "true", //票务 -  产品分类展示
  appNewHomeIsShowCart: localStorage.getItem("appNewHomeIsShowCart") === "true", //票务 -  购物车展示
  appNewHomeIsShowSubScenic:
    localStorage.getItem("appNewHomeIsShowSubScenic") === "true", //票务 -  是否展示子景区
  appNewHomeIsShowDate: localStorage.getItem("appNewHomeIsShowDate") === "true", //票务 -  日期打开
  appNewHomeIsShowSellRecord:
    localStorage.getItem("appNewHomeIsShowSellRecord") === "true", //票务 -  销售记录打开
  appNewHomeIsShowProductImg:
    localStorage.getItem("appNewHomeIsShowProductImg") !== "false", //票务 - 是否显示产品图片
  appNewHomeIsShowSpeedOrder:
    localStorage.getItem("appNewHomeIsShowSpeedOrder") !== "false", //票务 - 是否显示极速下单
  appNewHomePrintType: localStorage.getItem("appNewHomePrintType") || 1, //票务 -  打印方式
  // 产品有效期设置
  appNewHomeProductValidityRule:
    JSON.parse(localStorage.getItem("appNewHomeProductValidityRule")) || [], // 每个产品单独设置的有效期
  appNewHomeProductTakeTicket:
    JSON.parse(localStorage.getItem("appNewHomeProductTakeTicket")) || [], // 每个产品的取票操作
  appNewHomeIsDeleteCart:
    localStorage.getItem("appNewHomeIsDeleteCart") !== "false", //票务 - 是否下单后清除购物车
  appNewHomeTicketOrderFastKey: JSON.parse(
    localStorage.getItem("appNewHomeTicketOrderFastKey") ||
      '{"buy":[112,113,114,115,116,117],"del":[118,119,120,121,122,123]}'
  ), // 票务-快捷键

  //组合- 页面布局
  appCombinationNewIsShowGroupOrder:
    localStorage.getItem("appCombinationNewIsShowGroupOrder") === "true", //组合 -  团体下单展示
  appCombinationNewIsShowProductClass:
    localStorage.getItem("appCombinationNewIsShowProductClass") === "true", //组合 -  产品分类展示
  appCombinationNewIsShowCart:
    localStorage.getItem("appCombinationNewIsShowCart") === "true", //组合 -  购物车展示
  appCombinationNewIsShowSubScenic:
    localStorage.getItem("appCombinationNewIsShowSubScenic") === "true", //组合 -  是否展示子景区
  appCombinationNewIsShowDate:
    localStorage.getItem("appCombinationNewIsShowDate") === "true", //组合 -  日期打开
  appCombinationNewIsShowSellRecord:
    localStorage.getItem("appCombinationNewIsShowSellRecord") === "true", //组合 -  销售记录打开
  appCombinationNewIsShowProductImg:
    localStorage.getItem("appCombinationNewIsShowProductImg") !== "false", //组合 - 是否显示产品图片
  appCombinationNewIsShowSpeedOrder:
    localStorage.getItem("appCombinationNewIsShowSpeedOrder") !== "false", //组合 - 是否显示极速下单
  appCombinationNewPrintType:
    localStorage.getItem("appCombinationNewPrintType") || 1, //组合 -  打印方式
  // 产品有效期设置
  appCombinationNewProductValidityRule:
    JSON.parse(localStorage.getItem("appCombinationNewProductValidityRule")) ||
    [], // 组合-每个产品单独设置的有效期
  appCombinationNewProductTakeTicket:
    JSON.parse(localStorage.getItem("appCombinationNewProductTakeTicket")) ||
    [], // 组合-每个产品的取票操作
  appCombinationNewIsDeleteCart:
    localStorage.getItem("appCombinationNewIsDeleteCart") !== "false", //组合 - 是否下单后清除购物车
  appCombinationNewTicketOrderFastKey: JSON.parse(
    localStorage.getItem("appCombinationNewTicketOrderFastKey") ||
      '{"buy":[112,113,114,115,116,117],"del":[118,119,120,121,122,123]}'
  ), // 组合-快捷键

  //游乐- 页面布局
  appPlayNewIsShowGroupOrder:
    localStorage.getItem("appPlayNewIsShowGroupOrder") === "true", //游乐 -  团体下单展示
  appPlayNewIsShowProductClass:
    localStorage.getItem("appPlayNewIsShowProductClass") === "true", //游乐 -  产品分类展示
  appPlayNewIsShowCart: localStorage.getItem("appPlayNewIsShowCart") === "true", //游乐 -  购物车展示
  appPlayNewIsShowSubScenic:
    localStorage.getItem("appPlayNewIsShowSubScenic") === "true", //游乐 -  是否展示子景区
  appPlayNewIsShowDate: localStorage.getItem("appPlayNewIsShowDate") === "true", //游乐 -  日期打开
  appPlayNewIsShowSellRecord:
    localStorage.getItem("appPlayNewIsShowSellRecord") === "true", //游乐 -  销售记录打开
  appPlayNewIsShowProductImg:
    localStorage.getItem("appPlayNewIsShowProductImg") !== "false", //游乐 - 是否显示产品图片
  appPlayNewIsShowSpeedOrder:
    localStorage.getItem("appPlayNewIsShowSpeedOrder") !== "false", //游乐 - 是否显示极速下单
  appPlayNewPrintType: localStorage.getItem("appPlayNewPrintType") || 1, //游乐 -  打印方式
  // 产品有效期设置
  appPlayNewProductValidityRule:
    JSON.parse(localStorage.getItem("appPlayNewProductValidityRule")) || [], // 游乐-每个产品单独设置的有效期
  appPlayNewProductTakeTicket:
    JSON.parse(localStorage.getItem("appPlayNewProductTakeTicket")) || [], // 游乐-每个产品的取票操作
  appPlayNewIsDeleteCart:
    localStorage.getItem("appPlayNewIsDeleteCart") !== "false", //游乐 - 是否下单后清除购物车
  appPlayNewTicketOrderFastKey: JSON.parse(
    localStorage.getItem("appPlayNewTicketOrderFastKey") ||
      '{"buy":[112,113,114,115,116,117],"del":[118,119,120,121,122,123]}'
  ), // 游乐-快捷键

  //零售- 页面布局
  appRetailNewIsShowGroupOrder:
    localStorage.getItem("appRetailNewIsShowGroupOrder") === "true", //零售 -  团体下单展示
  appRetailNewIsShowProductClass:
    localStorage.getItem("appRetailNewIsShowProductClass") === "true", //零售 -  产品分类展示
  appRetailNewIsShowCart:
    localStorage.getItem("appRetailNewIsShowCart") === "true", //零售 -  购物车展示
  appRetailNewIsShowSubScenic:
    localStorage.getItem("appRetailNewIsShowSubScenic") === "true", //零售 -  是否展示子景区
  appRetailNewIsShowDate:
    localStorage.getItem("appRetailNewIsShowDate") === "true", //零售 -  日期打开
  appRetailNewIsShowSellRecord:
    localStorage.getItem("appRetailNewIsShowSellRecord") === "true", //零售 -  销售记录打开
  appRetailNewIsShowProductImg:
    localStorage.getItem("appRetailNewIsShowProductImg") !== "false", //零售 - 是否显示产品图片
  appRetailNewIsShowSpeedOrder:
    localStorage.getItem("appRetailNewIsShowSpeedOrder") !== "false", //零售 - 是否显示极速下单
  appRetailNewPrintType: localStorage.getItem("appRetailNewPrintType") || 1, //零售 -  打印方式
  // 产品有效期设置
  appRetailNewProductValidityRule:
    JSON.parse(localStorage.getItem("appRetailNewProductValidityRule")) || [], // 零售-每个产品单独设置的有效期
  appRetailNewProductTakeTicket:
    JSON.parse(localStorage.getItem("appRetailNewProductTakeTicket")) || [], // 零售-每个产品的取票操作
  appRetailNewIsDeleteCart:
    localStorage.getItem("appRetailNewIsDeleteCart") !== "false", //零售 - 是否下单后清除购物车
  appRetailNewTicketOrderFastKey: JSON.parse(
    localStorage.getItem("appRetailNewTicketOrderFastKey") ||
      '{"buy":[112,113,114,115,116,117],"del":[118,119,120,121,122,123]}'
  ), // 零售-快捷键

  // 剧场 - 页面布局
  appTheaterNewIsShowGroupOrder:
    localStorage.getItem(" appTheaterNewIsShowGroupOrder") === "true", //剧场 -  团体下单展示
  appTheaterNewIsShowProductClass:
    localStorage.getItem(" appTheaterNewIsShowProductClass") === "true", //剧场 -  产品分类展示
  appTheaterNewIsShowSpeedOrder:
    localStorage.getItem("appTheaterNewIsShowSpeedOrder") !== "false", //剧场 - 是否显示极速下单
  // 产品有效期设置
  appTheaterNewProductValidityRule:
    JSON.parse(localStorage.getItem("appTheaterNewProductValidityRule")) || [], // 剧场-每个产品单独设置的有效期
  appTheaterNewProductTakeTicket:
    JSON.parse(localStorage.getItem("appTheaterNewProductTakeTicket")) || [], // 剧场-每个产品的取票操作
  appTheaterNewTicketOrderFastKey: JSON.parse(
    localStorage.getItem("appTheaterNewTicketOrderFastKey") ||
      "[112, 113, 114, 115, 116, 117]"
  ), // 剧场-快捷键

  // 旧版是否展示图片
  isShowProductImg: localStorage.getItem("isShowProductImg") !== "false",

  isShowRetailImg: localStorage.getItem("isShowRetailImg") === "true", //零售 - 是否显示零售产品图片
  ticketTemplateId: localStorage.getItem("ticketTemplateId"), //门票打印机 - 打印模板id
  accountTemplateId: localStorage.getItem("accountTemplateId"), //交账打印 - 打印模板id
  voiceBroadcast: localStorage.getItem("voiceBroadcast") === "true", //是否开启语音播报
  shortcutKey: localStorage.getItem("shortcutKey") === "true", //是否开启快捷键

  isShowCommodityStocks:
    localStorage.getItem("isShowCommodityStocks") === "true", //商户 - 是否显示商品库存
  isShowSoldOutItems: localStorage.getItem("isShowSoldOutItems") === "true", //商户 - 是否显示售罄商品
  //非零售的配置
  equipmentType: localStorage.getItem("equipmentType"), //设备类型
  printerModel: localStorage.getItem("printerModel"), //打印机型号
  printerTemplate: localStorage.getItem("printerTemplate"), //打印机模板
  isPrintProject: localStorage.getItem("isPrintProject") === "true", //商户 - 是否打印项目套餐
  receiptType: localStorage.getItem("receiptType"), //小票打印机
  receiptTemplate: localStorage.getItem("receiptTemplate"), //小票打印机打印模板
  isPrintReceipt: localStorage.getItem("isPrintReceipt") === "true", //商户 - 是否打印小票
  handOverTemplate: localStorage.getItem("handOverTemplate"), //交账打印模板
  handOverType: localStorage.getItem("handOverType") //交账打印机类型
};

const mutation = {
  // 重置state，需要清空的历史数据 (设置信息不需要重置)
  /*  resetState(state) {
    const data = {
      userIdentity: 0, //用户身份  0=售票窗口  1=商户  2=项目

      ticketTemplateId: localStorage.getItem("ticketTemplateId"), //门票打印机 - 打印模板id
      accountTemplateId: localStorage.getItem("accountTemplateId"), //交账打印 - 打印模板id
      payType: localStorage.getItem("payType"), //支付方式
      voiceBroadcast: localStorage.getItem("voiceBroadcast") === "true", //是否开启语音播报
      shortcutKey: localStorage.getItem("shortcutKey") === "true", //是否开启快捷键

      isShowCommodityStocks:
        localStorage.getItem("isShowCommodityStocks") === "true", //商户 - 是否显示商品库存
      isShowSoldOutItems: localStorage.getItem("isShowSoldOutItems") === "true", //商户 - 是否显示售罄商品
      //非零售的配置
      equipmentType: localStorage.getItem("equipmentType"), //设备类型
      printerModel: localStorage.getItem("printerModel"), //打印机型号
      printerTemplate: localStorage.getItem("printerTemplate"), //打印机模板
      isPrintProject: localStorage.getItem("isPrintProject") === "true", //商户 - 是否打印项目套餐
      receiptType: localStorage.getItem("receiptType"), //小票打印机
      receiptTemplate: localStorage.getItem("receiptTemplate"), //小票打印机打印模板
      isPrintReceipt: localStorage.getItem("isPrintReceipt") === "true", //商户 - 是否打印小票
      handOverTemplate: localStorage.getItem("handOverTemplate"), //交账打印模板
      handOverType: localStorage.getItem("handOverType") //交账打印机类型
    };
    for (let key in state) {
      state[key] = data[key];
    }
  }*/
};

const getters = {
  getSettings() {
    const currentTopMenuKey = store.state.common.currentTopMenuKey;
    return {
      IsShowGroupOrder: state[`${currentTopMenuKey}IsShowGroupOrder`],
      IsShowProductClass: state[`${currentTopMenuKey}IsShowProductClass`],
      IsShowCart: state[`${currentTopMenuKey}IsShowCart`],
      IsShowSubScenic: state[`${currentTopMenuKey}IsShowSubScenic`],
      IsShowDate: state[`${currentTopMenuKey}IsShowDate`],
      IsShowSellRecord: state[`${currentTopMenuKey}IsShowSellRecord`],
      IsShowProductImg: state[`${currentTopMenuKey}IsShowProductImg`],
      IsShowSpeedOrder: state[`${currentTopMenuKey}IsShowSpeedOrder`],
      PrintType: state[`${currentTopMenuKey}PrintType`],
      ProductValidityRule: state[`${currentTopMenuKey}ProductValidityRule`],
      ProductTakeTicket: state[`${currentTopMenuKey}ProductTakeTicket`],
      IsDeleteCart: state[`${currentTopMenuKey}IsDeleteCart`],
      TicketOrderFastKey: state[`${currentTopMenuKey}TicketOrderFastKey`]
    };
  }
};

export default {
  name: "setting",
  namespaced: true,
  state,
  getters: { ...getEveryConfigFn(state, "get"), ...getters },
  mutations: { ...getEveryConfigFn(state, "set"), ...mutation }
};
