export default {
  // 更新游玩项目
  updatePlayItem(state, data) {
    state.playItem = data;
  },
  // 重置state，清空历史数据
  resetState(state) {
    const data = {
      playItem: {} // 游玩项目
    };
    for (let key in state) {
      state[key] = data[key];
    }
  }
};
