// import { message } from "ant-design-vue";
import axios from "axios";
const prefix = "http://127.0.0.1:38088"; // api前缀
function myAxios(type, path, params) {
  return new Promise((resolve, reject) => {
    axios[type](`${prefix}${path}`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        //请求失败回调;
        // message.warning("检测高拍仪开启失败，请检查开启设备软件");
        reject(error);
      });
  });
}

class CameraService {
  /**
   * 初始化硬件，打开串口
   */
  static init() {
    //打开串口
    // const params = {
    //   port: "0",
    //   baud: "115200",
    //   parity: "0",
    //   databits: "8",
    //   stopbits: "0"
    // };
    // return myAxios("post", "/serialport=initserialport", params).catch(() => {
    //   message.warning("检测高拍仪开启失败，请检查开启设备软件");
    // });
  }
  //关闭串口
  static closeSerialport() {
    var params = { port: "0" };
    myAxios("post", "/serialport=deinitserialport", params);
  }

  //拍照
  static grabImageBase64(devIndex = 1) {
    let params =
      '{"filepath":"base64","rotate":"' +
      0 +
      '","camidx":"' +
      devIndex +
      '","cutpage":"' +
      "0" +
      '"}';
    return myAxios("post", "/video=grabimage", params);
  }

  //识别身份证
  static myReadIDCard() {
    return myAxios("post", "/card=idcard", "");
  }

  // 打开摄像头
  static myOpenCamera(devIndex) {
    //devIndex 0 主 1副
    return `${prefix}/video=stream&camidx=${devIndex}`;
  }
}
export default CameraService;
