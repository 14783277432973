<!--
  挂账支付
-->
<template>
  <KeyboardPro
    :id-card="false"
    width="520"
    type="2"
    @trigger="keyBoardInput"
    @keyBack="keyBack"
    @ok="doPay"
  >
    <template v-slot:input="{ confirm }">
      <a-input
        v-show="propParam.combinedPayment"
        placeholder="请输入金额"
        v-model="money"
        allow-clear
        size="large"
        class="prefix-input mt-1x"
        ref="scanRef"
        @pressEnter="confirm"
        @focus="codeFocus"
      >
        <span slot="prefix" class="primary">挂账金额:</span>
        <i
          style="color:#FFA735;"
          class="iconfont iconrenmingbiriyuan font-xxxl"
          slot="suffix"
        ></i>
      </a-input>
    </template>
    <a-input
      slot="midSlot"
      placeholder="请输入卡号"
      allow-clear
      size="large"
      style="margin-top: 13px;"
      class="prefix-input"
      v-model="cardNum"
      @focus="cardFocus"
    >
      <span slot="prefix" class="primary">挂账卡号:</span>
    </a-input>
  </KeyboardPro>
</template>
<script>
import KeyboardPro from "@/components/common/KeyboardPro";
import { payOncreditOrder } from "@/api/order";
import { myMixin } from "./mixin";

export default {
  name: "newScan",
  components: { KeyboardPro },
  props: {
    config: {
      type: Object,
      default() {
        return {};
      }
    },
    propParam: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mixins: [myMixin],
  data() {
    return {
      money: "", // 挂账金额
      cardNum: "", // 挂账卡号
      isFocus: false // 挂账卡号是否聚焦
    };
  },
  watch: {
    "propParam.combinedPayment": {
      handler(val) {
        if (!val) {
          // 没开启组合支付时,键盘输入的内容，默认放到账号卡号框
          this.isFocus = true;
        } else {
          // 有开启组合支付时，键盘输入的内容，默认放到收款金额框
          this.isFocus = false;
        }
      },
      immediate: true
    }
  },
  methods: {
    doPay() {
      if (!this.cardNum) {
        this.$message.warning("请先输入挂账卡号");
        return;
      }
      payOncreditOrder({
        orderId: this.propParam.orderNumber,
        onCreditAccountNo: this.cardNum
      })
        .then(res => {
          this.paySuccess({
            ifCanPrint: !res.ifCanPrint // 挂账支付，后台告知是否需要打印功能
          });
        })
        .catch(() => {});
    },
    // 键盘输入
    keyBoardInput(val) {
      // 输入的内容放到 付款码
      if (!this.isFocus) {
        let str = this.money;
        str += String(val);
        this.money = str;
      } else {
        // 输入的内容放到 收款金额
        let str = this.cardNum;
        str += String(val);
        this.cardNum = str;
      }
    },
    // 键盘退格
    keyBack() {
      if (!this.isFocus && this.money) {
        this.money = this.money.substr(0, this.money.length - 1);
      } else if (this.isFocus && this.cardNum) {
        this.cardNum = this.cardNum.substr(0, this.cardNum.length - 1);
      }
    },
    codeFocus() {
      this.isFocus = false;
    },
    cardFocus() {
      this.isFocus = true;
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ant-input.ant-input-lg {
  font-size: 18px;
}
</style>
