var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"title":_vm.tit,"visible":_vm.vis,"body-style":{
    'max-height': '460px'
  },"z-index":1001,"width":600,"mask-closable":false,"keyboard":false},on:{"cancel":_vm.close}},[_c('a-form-model',{ref:"myForm",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"label-align":"left"}},[_c('a-form-model-item',{attrs:{"label-col":{ span: 0 },"wrapper-col":{ span: 24 },"label":""}},[_c('div',{staticClass:"title-pannel-wrap ph-2x pv-1x",on:{"click":function($event){return _vm.collapseClick(1)}}},[_c('div',{staticClass:"left title-pannel"},[_vm._v("公共设置")]),_c('div',{staticClass:"right pointer"},[_c('a-icon',{staticClass:"icon-transition",class:{ 'rotate-icon': !_vm.collapseList[0] },attrs:{"type":"down"}})],1)])]),_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseList[0]),expression:"collapseList[0]"}],attrs:{"label-col":{ span: 0 },"wrapper-col":{ span: 24 },"label":""}},[_c('a-form-model-item',{staticClass:"pl-2x",attrs:{"label":"收银小票","colon":false}},[_c('a-switch',{model:{value:(_vm.form.cashierReceipt),callback:function ($$v) {_vm.$set(_vm.form, "cashierReceipt", $$v)},expression:"form.cashierReceipt"}}),_c('span',{staticClass:"gray ml-1x"},[_vm._v(" "+_vm._s(_vm.form.cashierReceipt ? "打印" : "不打印")+" ")])],1),_c('a-form-model-item',{staticClass:"pl-2x",attrs:{"label":"收银小票打印模板","colon":false,"prop":"receiptTemplate","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"rules":[
          {
            required: _vm.form.cashierReceipt ? true : false,
            message: '请选择',
            trigger: 'change'
          }
        ]}},[_c('a-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.form.receiptTemplate),callback:function ($$v) {_vm.$set(_vm.form, "receiptTemplate", $$v)},expression:"form.receiptTemplate"}},_vm._l((_vm.receiptTemplateList),function(son,index){return _c('a-select-option',{key:index,attrs:{"value":son.id}},[_vm._v(" "+_vm._s(son.templateName)+" ")])}),1)],1),_c('a-form-model-item',{staticClass:"pl-2x",attrs:{"label":"交账打印模板","colon":false,"prop":"accountforTemplate","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.form.accountforTemplate),callback:function ($$v) {_vm.$set(_vm.form, "accountforTemplate", $$v)},expression:"form.accountforTemplate"}},_vm._l((_vm.accountforTemplateList),function(son,index){return _c('a-select-option',{key:index,attrs:{"value":son.id}},[_vm._v(" "+_vm._s(son.templateName)+" ")])}),1)],1),_vm._l((_vm.publicSettingOptions),function(item){return _c('a-form-model-item',{key:item.key,staticClass:"pl-2x",attrs:{"label":item.labelName,"colon":false,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"prop":item.key,"rules":[
          {
            required: item.items.length > 0,
            message: '请选择',
            trigger: 'change'
          }
        ]}},[_c('a-select',{attrs:{"placeholder":"请选择"},on:{"change":function (e) { return _vm.handleSelectChange(item.key, e); }},model:{value:(_vm.form[item.key]),callback:function ($$v) {_vm.$set(_vm.form, item.key, $$v)},expression:"form[item.key]"}},_vm._l((item.items),function(son,index){return _c('a-select-option',{key:index,attrs:{"value":son}},[_vm._v(" "+_vm._s(son)+" ")])}),1),(item.key == 'idCardNames' && _vm.isWindow)?_c('router-link',{attrs:{"tag":"a","target":"_blank","to":"/DownloadDriver"}},[_vm._v(" 驱动下载 ")]):_vm._e()],1)})],2),_c('a-form-model-item',{attrs:{"label-col":{ span: 0 },"wrapper-col":{ span: 24 },"label":""}},[_c('div',{staticClass:"title-pannel-wrap ph-2x pv-1x",on:{"click":function($event){return _vm.collapseClick(2)}}},[_c('div',{staticClass:"left title-pannel"},[_vm._v("门票")]),_c('div',{staticClass:"right pointer"},[_c('a-icon',{staticClass:"icon-transition",class:{ 'rotate-icon': !_vm.collapseList[1] },attrs:{"type":"down"}})],1)])]),_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseList[1]),expression:"collapseList[1]"}],attrs:{"label-col":{ span: 0 },"wrapper-col":{ span: 24 },"label":""}},[_c('a-form-model-item',{staticClass:"pl-2x",attrs:{"label":"打印门票","colon":false}},[_c('a-switch',{model:{value:(_vm.form.printTicket),callback:function ($$v) {_vm.$set(_vm.form, "printTicket", $$v)},expression:"form.printTicket"}}),_c('span',{staticClass:"gray ml-1x"},[_vm._v(" "+_vm._s(_vm.form.printTicket ? "打印" : "不打印")+" ")])],1),_vm._l((_vm.ticketSettingOptions),function(item){return _c('a-form-model-item',{key:item.key,staticClass:"pl-2x",attrs:{"label":item.labelName,"colon":false,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"prop":item.key,"rules":[
          {
            required: item.items.length > 0,
            message: '请选择',
            trigger: 'change'
          }
        ]}},[_c('a-select',{attrs:{"placeholder":"请选择"},on:{"change":function (e) { return _vm.handleSelectChange(item.key, e); }},model:{value:(_vm.form[item.key]),callback:function ($$v) {_vm.$set(_vm.form, item.key, $$v)},expression:"form[item.key]"}},_vm._l((item.items),function(son,index){return _c('a-select-option',{key:index,attrs:{"value":son}},[_vm._v(" "+_vm._s(son)+" ")])}),1),(item.key == 'printerNames' && _vm.isWindow)?_c('router-link',{attrs:{"tag":"a","target":"_blank","to":"/DownloadDriver"}},[_vm._v(" 驱动下载 ")]):_vm._e()],1)})],2),_c('a-form-model-item',{attrs:{"label-col":{ span: 0 },"wrapper-col":{ span: 24 },"label":""}},[_c('div',{staticClass:"title-pannel-wrap ph-2x pv-1x",on:{"click":function($event){return _vm.collapseClick(3)}}},[_c('div',{staticClass:"left title-pannel"},[_vm._v("游乐")]),_c('div',{staticClass:"right pointer"},[_c('a-icon',{staticClass:"icon-transition",class:{ 'rotate-icon': !_vm.collapseList[2] },attrs:{"type":"down"}})],1)])]),_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.collapseList[2]),expression:"collapseList[2]"}],attrs:{"label-col":{ span: 0 },"wrapper-col":{ span: 24 },"label":""}},[_c('a-form-model-item',{staticClass:"pl-2x",attrs:{"label":"打印项目套餐","colon":false}},[_c('a-switch',{model:{value:(_vm.form.printProject),callback:function ($$v) {_vm.$set(_vm.form, "printProject", $$v)},expression:"form.printProject"}}),_c('span',{staticClass:"gray ml-1x"},[_vm._v(" "+_vm._s(_vm.form.printProject ? "打印" : "不打印")+" ")])],1),_c('a-form-model-item',{staticClass:"pl-2x",attrs:{"label":"项目套餐打印模板","colon":false,"prop":"projectTemplate","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"rules":[
          {
            required: _vm.form.printProject ? true : false,
            message: '请选择',
            trigger: 'change'
          }
        ]}},[_c('a-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.form.projectTemplate),callback:function ($$v) {_vm.$set(_vm.form, "projectTemplate", $$v)},expression:"form.projectTemplate"}},_vm._l((_vm.projectTemplateList),function(son,index){return _c('a-select-option',{key:index,attrs:{"value":son.id}},[_vm._v(" "+_vm._s(son.templateName)+" ")])}),1)],1),_vm._l((_vm.amusementSettingOptions),function(item){return _c('a-form-model-item',{key:item.key,staticClass:"pl-2x",attrs:{"label":item.labelName,"colon":false,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"prop":item.key,"rules":[
          {
            required: item.items.length > 0,
            message: '请选择',
            trigger: 'change'
          }
        ]}},[_c('a-select',{attrs:{"placeholder":"请选择"},on:{"change":function (e) { return _vm.handleSelectChange(item.key, e); }},model:{value:(_vm.form[item.key]),callback:function ($$v) {_vm.$set(_vm.form, item.key, $$v)},expression:"form[item.key]"}},_vm._l((item.items),function(son,index){return _c('a-select-option',{key:index,attrs:{"value":son}},[_vm._v(" "+_vm._s(son)+" ")])}),1)],1)})],2)],1),_c('div',{staticClass:"flex-center",attrs:{"slot":"footer"},slot:"footer"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v("提交")]),_c('a-button',{on:{"click":_vm.close}},[_vm._v("取消")])],1),_c('GaoPaiYiCamera',{attrs:{"showInfo":_vm.showInfo},on:{"close":function($event){_vm.showInfo = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }