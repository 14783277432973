<template>
  <div class="left-bar">
    <ul class="left-menu" :class="{ 'hide-menu': !isShowLeftBar }">
      <li class="iconfont iconshouqicaidan item" @click="toggleLeftMenu"></li>
      <li
        v-for="item in currentLeftMenuList"
        :key="item.id"
        class="item"
        :class="{ active: currentLeftMenuKey === item.menuPermissionKey }"
        @click="routerTo(item)"
      >
        <i class="iconfont" :class="icons[item.url]"></i>
        <p>{{ item.name }}</p>
      </li>
    </ul>
    <div
      class="ctrl-btn"
      @click="toggleLeftMenu"
      :class="{ 'show-btn': !isShowLeftBar }"
    >
      <li class="iconfont iconzhankaicaidan"></li>
    </div>
  </div>
</template>
<script>
export default {
  name: "LeftNav",
  data() {
    const icons = {
      /*门票*/
      "/home": "iconsvg13", // 售票
      "/home/sell/SellProduct": "iconsvg13", // 售票
      "/home/ticket/TicketList": "iconshoupiao", // 门票
      "/play/recharge/ticketSearch": "iconchongzhi", // 门票充值
      /*游乐*/
      "/play/package/projectPackage": "iconyoulechang1", // 项目套餐
      "/play/consume/index": "iconjiaozhang", // 项目消费
      "/play/sell/SellProduct": "iconyoulechang1", // 游乐销售
      /*零售*/
      "/retail": "iconlingshou", // 零售
      "/retail/sell/SellProduct": "iconlingshou", // 零售
      "/retail/take/pickTicketSearch": "iconqianzhangyanzheng", //核销取货
      /*物品租赁*/
      "/lease": "iconzulindengji", // 物品租赁
      /*餐饮*/
      "/catering": "icondiancan1", // 桌台点餐
      "/catering/menuList": "icondiancan", // 快速点餐
      "/catering/takeFood": "iconquerenqucan", // 取餐
      /*餐饮-升级版*/
      "/cateringNew": "icondiancan1", // 桌台点餐
      "/cateringNew/menuList": "icondiancan", // 快速点餐
      "/cateringNew/takeFood": "iconquerenqucan", // 取餐
      /*剧场*/
      "/theater/TheaterList": "iconshoupiao", // 售剧场票
      "/theater/pick-ticket/PickTicket": "iconqupiao", // 取票
      "/theater/change-seat/ChangeSeat": "iconzuowei", // 换座
      /*剧场-升级版*/
      "/theater/sell/SellProduct": "iconshoupiao", // 售剧场票
      /*组合产品*/
      "/combination-product": "iconsvg13", // 销售
      "/combination-product/sell/SellProduct": "iconsvg13", // 销售
      "/combination-product/ticket": "iconshoupiao", // 门票
      /*订单*/
      "/home/order/OrderList": "icondingdan", // 订单列表
      "/home/orderNew/OrderList": "icondingdan", // 订单列表
      "/home/orderNew/ProductRecordList": "icondiancan", // 订单列表
      "/home/orderNew/RefundRecordList": "icondiancan", // 订单产品退款记录
      "/home/orderNew/RefundApprove": "iconshoupiao", // 订单产品退款审批
      "/home/order/SettleAccounts": "icondiancan", // 清账
      "/home/order/OrderDetailList": "icondiancan", // 明细
      "/home/order/OrderTicket": "iconshoupiao", // 门票
      /*我的订单*/
      "/home/order/MyOrderList": "icondingdan", // 订单列表
      "/home/order/MyOrderDetailList": "icondiancan", // 明细
      "/home/order/MyOrderTicket": "iconshoupiao", // 门票
      /*交账*/
      "/account/AccountDetail": "iconhuaban37fuben2", // 交账
      "/account/RepairAccountDetail": "icondingdan-", // 补账
      /*快速下单*/
      "/home/sell/TicketOrder": "iconshoufeichu", // 快速下单-票务
      "/home/quickPlaceOrder/placeOrder": "iconsvg13", // 快速下单
      "/home/quickPlaceOrderV2/placeOrder": "iconshoupiao", // 极速下单
      "/home/quickPlaceOrderFlying/placeOrder": "iconmenpiao" // 飞速下单
    };
    return {
      icons, // 左侧菜单图标
      isShowLeftBar: true, //是否展示左侧栏
      leftMenus: [], //左侧菜单
      list: [] // 最终展示的菜单列表
    };
  },
  computed: {
    // 激活菜单id
    currentLeftMenuKey() {
      return this.$store.state.common.currentLeftMenuKey;
    },
    // 左侧菜单列表
    currentLeftMenuList() {
      return this.$store.state.common.currentLeftMenuList;
    }
  },
  created() {},
  methods: {
    // 路由跳转
    routerTo({ url, menuPermissionKey }) {
      this.$store.commit("common/setCurrentLeftMenuKey", menuPermissionKey);
      this.$router.push(url);
    },
    // 开关左侧菜单
    toggleLeftMenu() {
      this.isShowLeftBar = !this.isShowLeftBar;
      this.$store.commit("common/updateMenuStatus", this.isShowLeftBar);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.left-bar {
  flex-shrink: 0;
  position: relative;

  .left-menu {
    overflow: hidden;
    height: 100%;
    width: 100px;
    background: linear-gradient(to top, #44b8fb, #3e55fc);
    box-shadow: 2px 0 3px #ddd;
    transition: all 0.3s ease-in-out;
    &.hide-menu {
      transform: translateX(-100px);
      width: 0;
    }
    .item {
      width: 100px;
      padding: 12px 0;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        background-color: @blue;
      }
      &.active {
        background-color: @blue;
      }
    }
    .iconfont {
      font-size: 24px;
      line-height: 1.2;
    }

    .iconshouqicaidan {
      font-size: 32px;
    }

    @media screen and (max-width: 1046px) {
      .item {
        width: 80px;
      }
    }
  }

  .ctrl-btn {
    position: absolute;
    z-index: 10;
    left: -40px;
    top: 4px;
    width: 36px;
    height: 36px;
    background: linear-gradient(to right, @primary-light, @primary);
    overflow: hidden;
    border-radius: 50%;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    transition: left 0.3s ease-in-out;
    &.show-btn {
      left: 4px;
    }
    .iconzhankaicaidan {
      font-size: 24px;
    }
  }
}
</style>
