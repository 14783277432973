export default {
  //设置购票人/出游人信息
  setTouristInfo({ commit }, data) {
    commit("setTouristInfo", data);
  },
  //保存购物车列表
  setCartList({ commit }, data) {
    commit("setCartList", data);
  },

  // 支付重构：订单折扣优惠（新增、更新）
  setOrderDiscountTextList({ commit }, data) {
    commit("setOrderDiscountTextList", data);
  },
  // 支付重构：订单折扣优惠（删除）
  removeOrderDiscountTextList({ commit }, data) {
    commit("removeOrderDiscountTextList", data);
  },
  // 支付重构：积分抵扣（新增、更新）
  setIntegralDeduction({ commit }, data) {
    commit("setIntegralDeduction", data);
  },
  // 支付重构：积分抵扣（删除）
  removeIntegralDeduction({ commit }, data) {
    commit("removeIntegralDeduction", data);
  },
  // 支付重构，清空所有优惠数据
  clearDiscount({ commit }) {
    commit("clearDiscount");
  },
  // 支付完成后，需要告知支付页面的操作配置
  setEndOfPayConfig({ commit }, data) {
    commit("setEndOfPayConfig", data);
  }
};
