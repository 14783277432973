<template>
  <div class="new-pay">
    <ProgressBar :cur-step="2"></ProgressBar>
    <div class="new-pay-wrap relative" v-if="payMode.length">
      <!-- 应付金额及开启组合支付 -->
      <div v-show="!zeroType" class="flex-between align-center">
        <span>
          <span class="font-l">应付：</span>
          <span class="red font-xxl">{{ totalFee | money }}</span>
        </span>
        <!-- <span class="flex-start align-center">
          <span>是否开启组合支付</span>
          <a-switch class="ml-1x mr-x" v-model="combinedPayment" />
          <span>{{ combinedPayment ? "开启" : "关闭" }}</span>
        </span> -->
      </div>
      <!-- 抹零金额及提示 -->
      <div v-show="zeroType">
        <a-radio-group v-model="zeroRadio">
          <a-radio :value="1">
            <span>应付：</span>
            <span class="red font-xxl">{{ totalFee | money }}</span>
          </a-radio>
          <a-radio :value="2">
            <span>抹零金额：</span>
            <span class="red font-xxl ml-1x">
              {{ (zeroAmount * 100) | money }}
            </span>
          </a-radio>
        </a-radio-group>
        <p>
          抹零方式：
          <span v-if="zeroType == 1" class="gray font-s">
            抹分: 向下抹分，如订单折后价为9.99元，使用现金收款时，实收9.9元
          </span>
          <span v-if="zeroType == 2" class="gray font-s">
            抹角：向下抹角，如订单折后价为9.99元，使用现金收款时，实收9元
          </span>
          <span v-if="zeroType == 3" class="gray font-s">
            四舍五入到"分"：向下抹角，如订单折后价为9.89元，使用现金收款时，实收9.9元
          </span>
          <span v-if="zeroType == 4" class="gray font-s">
            四舍五入到"角"：向下抹角，如订单折后价为9.89元，使用现金收款时，实收10元
          </span>
        </p>
      </div>
      <div class="pay-mode-wrap mt-1x">
        <ul class="pay-mode slide font-l flex-between align-center">
          <li
            v-for="(item, index) in payMode"
            :key="index"
            class="li-item pointer"
            @click="payModeClick(item)"
          >
            {{ item.payFormCategory == 1 ? "扫码支付" : item.payFormName }}
            <span
              class="span"
              :class="{ 'act-span': curIndex == item.payFormCategory }"
            >
              {{ item.payFormCategory == 1 ? "扫码支付" : item.payFormName }}
            </span>
          </li>
        </ul>
        <ul
          v-show="subPayMode.length"
          class="pay-mode slide font-l flex-between align-center"
        >
          <li
            class="li-item pointer"
            v-for="(subItem, subIndex) in subPayMode"
            :key="subIndex"
            @click="payModeClick2(subItem, subIndex)"
          >
            {{ subItem.payWayName }}
            <span
              class="span1"
              :class="{ 'act-span2': subCurIndex == subIndex }"
              >{{ subItem.payWayName }}</span
            >
          </li>
        </ul>
      </div>
      <component
        :is="payType"
        :prop-param="{
          combinedPayment,
          totalFee,
          orderNumber,
          formatId,
          subPayModeCode,
          subPayModeName,
          subPayModeId,
          subPayWaySetting,
          zeroAmount,
          zeroRadio
        }"
      ></component>
      <a-button @click="cancelPay" class="cancel-pay-btn" type="primary" ghost
        >取消收款</a-button
      >
    </div>
    <div v-if="payMode.length == 0" class="new-pay-wrap text-c">
      <p class="font-xxxl mb-2x red">支付配置未开启</p>
      <p class="font-xl gray">请先去后台启用支付功能</p>
      <a-button @click="cancelPay" class="cancel-pay-btn2" type="primary" ghost>
        取消收款
      </a-button>
    </div>
  </div>
</template>
<script>
import ProgressBar from "@/components/common/ProgressBar.vue";
import NewScan from "@/components/pay/NewScan.vue";
import OfflinePay from "@/components/pay/OfflinePay";
import DeductionPay from "@/components/pay/DeductionPay";
import HangBill from "@/components/pay/HangBill";
import { merchantuthPayFormWayInfo } from "../api/pay";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewPay",
  components: { ProgressBar, NewScan, OfflinePay, DeductionPay, HangBill },
  props: {},
  data() {
    return {
      combinedPayment: false, // 是否开启组合支付
      curIndex: "", // 默认显示扫码支付
      payMode: [], // 支付形式
      subPayMode: [], // 支付方式
      subCurIndex: 0,
      payType: "NewScan",
      totalFee: 0, // 订单支付总费用
      orderNumber: "", // 订单id
      formatId: "", // 业态id，用来判断打印
      subPayModeCode: "", // 第二支付形式code
      subPayModeName: "", // 第二支付形式名称
      subPayModeId: "",
      subPayWaySetting: {}, // 设置的抹零等配置信息
      zeroType: "", // 当前开启了何种抹零方式
      zeroAmount: 0, // 抹零金额
      zeroRadio: 1
    };
  },
  computed: {
    ...mapGetters("order", ["endOfPayConfig"])
  },
  created() {
    // 获取支付形式
    this.getPayMode();
    this.totalFee = this.$route.query.totalFee || 0;
    this.orderNumber = this.$route.query.orderNumber || "";
    this.formatId = this.$route.query.formatId || "";
  },
  methods: {
    ...mapActions("order", ["setCartList", "setEndOfPayConfig"]),
    ...mapActions("common", ["updateMember"]),
    getPayMode() {
      merchantuthPayFormWayInfo()
        .then(res => {
          // 根据url query判断是否需要隐藏哪些支付方式
          // 如：清账跳转过来的支付，取消挂账支付显示
          let arr = [];
          if (this.$route.query.ignorePay) {
            const ignorePayArr = this.$route.query.ignorePay.split(",");
            ignorePayArr.forEach(item => {
              arr.push({
                id: item
              });
            });
          }
          let newArr = res.filter(item => {
            return !arr.some(ele => ele.id == item.payFormCategory);
          });
          this.payMode = newArr || [];
          this.curIndex = localStorage.getItem("payType"); // 默认支付方式
          let flag = false; // 是否匹配到了默认支付方式
          this.payMode.forEach(item => {
            if (item.payFormCategory == this.curIndex) {
              // 是否有子选项支付选择
              if (item.authPayWayVOS && item.authPayWayVOS.length) {
                this.subPayMode = item.authPayWayVOS;
                this.mySwitch(item.payFormCategory, false);
              }
              flag = true;
            }
          });
          // 若没有匹配的默认的支付方式，则取第一个值为默认支付
          // 如：设置里设置挂账为默认支付方式后，而清账功能跳转过来时，又是取消挂账支付功能的。所以这里就会匹配不到默认支付方式
          if (!flag) {
            const item = this.payMode[0] || {};
            this.mySwitch(item.payFormCategory, item);
          }
        })
        .catch(() => {});
    },
    // 切换支付形式
    payModeClick(item) {
      this.curIndex = item.payFormCategory;
      this.subCurIndex = 0;
      this.zeroType = "";
      this.mySwitch(false, item);
    },
    mySwitch(i, item) {
      let index = "";
      item ? (index = item.payFormCategory) : (index = i);
      switch (index) {
        case 1: // 扫码支付：（含：线上支付、原生支付、钱柜子支付）
          this.payType = "NewScan";
          item && this.setSubPayMode(item);
          break;
        case 2: // 线下支付
          this.payType = "OfflinePay";
          item && this.setSubPayMode(item);
          break;
        case 3: // 挂账支付
          this.payType = "HangBill";
          item && this.setSubPayMode(item);
          break;
        case 4: // 抵扣支付
          this.payType = "DeductionPay";
          item && this.setSubPayMode(item);
          break;
        default:
          break;
      }
    },
    // 设置subPayMode
    setSubPayMode(item) {
      if (item.authPayWayVOS && item.authPayWayVOS.length) {
        let authPayWayVOS = item.authPayWayVOS;
        if (
          localStorage.getItem("noCash") &&
          localStorage.getItem("noCash") == 1
        ) {
          // 无现金支付
          authPayWayVOS = authPayWayVOS.filter(vo => vo.payWayName != "现金");
        }
        this.subPayMode = authPayWayVOS;
        // this.subPayMode = item.authPayWayVOS;
        this.getSubPayMode(this.subPayMode[0]);
      } else {
        this.subPayMode = [];
      }
    },
    payModeClick2(subItem, i) {
      this.subCurIndex = i;
      this.getSubPayMode(subItem);
    },
    // 拿出sub支付方式数据
    getSubPayMode(item) {
      this.subPayModeCode = item.payWayCode;
      this.subPayModeId = item.payWayId;
      this.subPayModeName = item.payWayName;
      this.subPayWaySetting = item.payWaySetting;
      const obj = JSON.parse(this.subPayWaySetting);
      this.zeroType = obj && obj.type;
      // 算出抹零金额
      this.calcZeroAmount();
    },
    // 取消收款
    cancelPay() {
      this.setCartList({});
      const url = this.endOfPayConfig.backUrl || "/home";
      // 是否需要清空会员信息
      if (this.endOfPayConfig.logoutMember) {
        this.updateMember({});
      }
      // 恢复配置信息到默认状态
      this.setEndOfPayConfig({
        logoutMember: true,
        backUrl: "/home"
      });
      this.$router.replace({ path: url });
    },
    // 根据配置，算出抹零金额
    calcZeroAmount() {
      const total = String(this.totalFee / 100);
      switch (Number(this.zeroType)) {
        // 抹分
        case 1:
          if (total.indexOf(".") > -1) {
            this.zeroAmount = total.substr(0, total.indexOf(".") + 2);
          } else {
            this.zeroAmount = total;
          }
          break;
        // 抹角
        case 2:
          if (total.indexOf(".") > -1) {
            this.zeroAmount = total.substr(0, total.indexOf("."));
          } else {
            this.zeroAmount = total;
          }
          break;
        // 四舍五入到“分”
        case 3:
          this.zeroAmount = Number(total).toFixed(1);
          break;
        // 四舍五入到“角”
        case 4:
          this.zeroAmount = Number(total).toFixed(0);
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "../assets/less/custom.less";
.new-pay-wrap {
  width: 520px;
  margin: 30px auto 0;
  .pay-mode-wrap {
    background: #dbdfe6;
    border-radius: 6px;
  }
  .pay-mode {
    overflow-x: auto;
    height: 70px;
    .li-item {
      height: 50px;
      padding: 0 30px;
      flex-shrink: 0;
      line-height: 50px;
      position: relative;
      left: 0;
      top: 0;
      span {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 6px;
        overflow: hidden;
      }
      .span {
        width: 0;
        height: 0;
      }
      .span1 {
        height: 100%;
        width: 0;
      }
      .act-span {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        transition: all 0.5s;
        background: @primary;
        color: #fff;
        text-align: center;
      }
      .act-span2 {
        border-radius: 6px;
        width: 100%;
        height: 100%;
        transition: all 0.5s;
        background: #1ec289;
        color: #fff;
        text-align: center;
      }
    }
  }
  .slide {
    overflow-y: hidden;
    overflow-x: auto;
  }
}
.cancel-pay-btn {
  position: absolute;
  right: -340px;
  bottom: 0;
  width: 200px;
  height: 60px;
  border-radius: 30px;
  margin-right: 80px;
}
.cancel-pay-btn2 {
  width: 200px;
  height: 60px;
  border-radius: 30px;
  margin-top: 30px;
}

// // 隐藏滚动条
// ::-webkit-scrollbar {
//   display: none;
//   width: 0;
//   height: 0;
//   color: transparent;
// }
</style>
