import App from "../main";
import axios from "axios";
// import global from "./global";
import { message as Message, Modal } from "ant-design-vue";
import store from "../store/index";

/**
 * @name: 全局AJAX请求类
 * @date: 2020-12-21
 * @author: 易远胜
 * @param: {String} url - 请求地址
 * @param: {Object} params - get和delete方法的请求参数
 * @param: {Object} data - post/put/add方法的请求参数
 * @param: {Object} config - axios的config(可以覆盖实例默认配置),
 *                  config.loading = false :该请求取消加载动画，如扫码支付二维码等待结果接口不需要加载动画
 *                  config.catch = false 公共方法不处理异常，留给页面处理
 * @return: {Object} Promise - 异步请求响应的参数
 */
class Http {
  constructor() {
    this.requestCount = 0; // 请求数量
    this.showConfirm = false; // 展示登录失效弹窗
  }
  // 公共配置
  instance() {
    return {
      // baseURL: "http://localhost:3333",
      // baseURL: "http://127.0.0.1:10011",
      timeout: 60000,
      headers: {
        "open-d": localStorage.getItem("token"),
        "Access-Control-Expose-Headers": "hello-qmp-one",
        // 销售商户Id,即供应商Id
        "Sale-Merchant": store.getters["common/getSaleMerchant"]
      }
    };
  }
  // 转换成URLSearchParams对象形式
  paramsSerializer(params) {
    let str = "";
    for (let key in params) {
      if (Array.isArray(params[key])) {
        str += params[key].map(item => `${key}=${item}`).join("&");
      } else if (
        typeof params[key] === "string" ||
        typeof params[key] === "number" ||
        typeof params[key] === "boolean"
      ) {
        str += `${key}=${params[key]}&`;
      }
    }
    if (/&$/.test(str)) str = str.substring(0, str.length - 1);
    return str;
  }
  // 添加数据
  add(url, data, config = {}) {
    return this.request(
      {
        url,
        method: "post",
        data,
        ...config
      },
      "添加成功"
    );
  }
  // 删除数据
  delete(url, params = {}, config = {}) {
    return this.request(
      {
        url,
        method: "delete",
        params,
        ...config,
        paramsSerializer: this.paramsSerializer
      },
      "删除成功"
    );
  }
  // 修改/编辑数据
  put(url, data, config = {}) {
    return this.request(
      {
        url,
        method: "put",
        data,
        ...config
      },
      "修改成功"
    );
  }
  // 更新数据-核销/开启/关闭/特殊操作等需要自定义提示语的需求
  update(url, data, config = {}) {
    return this.request({
      url,
      method: "put",
      data,
      ...config
    });
  }
  // 获取数据
  get(url, params = {}, config = {}) {
    return this.request({
      url,
      method: "get",
      params,
      ...config,
      // `paramsSerializer` 是一个负责 `params` 序列化的函数
      paramsSerializer: this.paramsSerializer
    });
  }
  // 提交数据-需要自定义提示语的需求
  post(url, data = {}, config = {}) {
    return this.request({
      url,
      method: "post",
      data,
      ...config
    });
  }
  // 通用请求方法
  request(config = {}, message) {
    // 是否展示全局loading动画（全局遮罩层）
    const loading = config.loading !== false;
    if (loading) {
      store.commit("common/updateLoading", true);
      this.requestCount++;
    }
    return new Promise((resolve, reject) => {
      // console.log(222);
      axios
        .request({ ...this.instance(), ...config })
        .then(({ headers, data, status }) => {
          if (message) Message.success(message);
          // 刷新token
          if (headers["hello-qmp-one"]) {
            localStorage.setItem("token", headers["hello-qmp-one"]);
          }
          // 200：get成功；201：提交成功；204：修改/删除成功
          if (status === 200 || status === 201 || status === 204) {
            resolve(data);
          } else {
            if (config.catch !== false) {
              Modal.error({
                title: "提示",
                content: `${data.message || "连接异常"}`,
                okText: "确认"
              });
            }
            reject(data);
          }
        })
        .catch(res => {
          const { response } = res;
          // 如果有响应信息
          if (response) {
            // 刷新token
            if (response.headers["hello-qmp-one"]) {
              localStorage.setItem("token", response.headers["hello-qmp-one"]);
            }
            if (response.data) {
              const { message, status } = response.data;
              // 字符串类型：401为登录失效，403是无权限访问
              if (status === "SYS-ERROR401" || status === "SYS-ERROR402") {
                // 并发接口只弹一个登录失效弹框
                // 如果登录失效调用退出登录接口不会再有登录失效弹框
                if (
                  !this.showConfirm &&
                  response.config.url !== "/api/user/logout" &&
                  localStorage.getItem("token")
                ) {
                  this.showConfirm = true;
                  Modal.warning({
                    title: "提示",
                    content: message,
                    okText: "重新登录",
                    onOk: () => {
                      this.showConfirm = false;
                      localStorage.removeItem("token");
                      App.$router.push(
                        `/login?redirect=${encodeURIComponent(
                          App.$route.fullPath
                        )}`
                      );
                      // App.$router.push(
                      //   `/login?redirect=${encodeURIComponent("/")}`
                      // );
                    }
                  });
                }
              }
              // 刷新太频繁警告提示-如抢购
              else if (/GATEWAY/.test(status)) {
                Message.warning(message);
              }
              // 其它异常
              else if (config.catch !== false) {
                Modal.error({
                  title: "提示",
                  content: message || "连接异常",
                  okText: "确认"
                });
              }
              reject(response.data);
            } else {
              const { status, statusText } = response;
              if (config.catch !== false) {
                Modal.error({
                  title: "提示",
                  content: `连接异常：${statusText}`,
                  okText: "确认"
                });
              }
              reject({
                message: "连接异常",
                status
              });
            }
          } else {
            Modal.error({
              title: "提示",
              content: `网络中断，请检查网络（url: ${config.url}，${res}）`,
              okText: "确认"
            });
            reject({
              message: "网络中断，请检查网络",
              status: ""
            });
          }
        })
        .finally(() => {
          if (loading) this.requestCount--;
          if (this.requestCount <= 0 && loading) {
            store.commit("common/updateLoading", false);
          }
        });
    });
  }
}

export default new Http();
