import { findUserDetail } from "../../api/user";
export default {
  openShortcutKey({ commit }) {
    commit("OpenShortcutKey");
  },
  closeShortcutKey({ commit }) {
    commit("CloseShortcutKey");
  },
  setLockScreen({ commit }, status) {
    commit("SetLockScreen", status);
  },
  // 获取用户信息
  getUser({ commit }) {
    findUserDetail({
      userId: localStorage.userId
    })
      .then(res => {
        commit("updateUser", res);
      })
      .catch(() => {});
  },
  // 取票信息
  GetTicketAction({ commit }, data) {
    commit("GetTicketMutation", data);
  },
  updateMember({ commit }, data) {
    commit("UpdateMember", data);
  },
  //修改当前的窗口类型
  setWindowType({ commit }, data) {
    commit("setWindowType", data);
  }
};
