/**
 * MS-会员接口 3.0
 **/
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/member`; //服务前缀

/**
 * 会员
 */
// 【member003】会员增加/办理-马鹏浩
export const memberSaveOne = data => {
  return http.add(`${prefix}/save/one`, data);
};

// 修改会员资料-马鹏浩
export const memberUpdate = data => {
  return http.put(`${prefix}/update`, data);
};

//修改支付密码 -刘资涌
export const updatePaymentPassword = data => {
  return http.put(`${prefix}/update/payment/password`, data);
};
//修改支付密码 -子健 http://192.168.1.50:40001/project/41/interface/api/4024
export const updatePaymentPasswordTemporary = data => {
  return http.put(`${prefix}/update/payment/password/temporary`, data);
};

// 查询会员详情/编辑会员回显
export const memberInfoOne = data => {
  return http.get(`${prefix}/info/one`, data);
};

// 根据会员凭证返回会员封装后信息 罗子健-http://192.168.1.50:40001/project/41/interface/api/4032
export const evidenceOneMemberVisitor = data => {
  return http.get(`${prefix}/evidence/one/member/visitor`, data);
};

// 查询会员详情基本信息 - 罗子健 http://192.168.1.50:40001/project/41/interface/api/3904
export const orderOneMemberInfo = data => {
  return http.get(`${prefix}/evidence/one/member`, data);
};
// 查询会员详情基本信息 - 罗子健 http://192.168.1.50:40001/project/41/interface/api/4001
export const memberRefundBalance = data => {
  return http.get(`${prefix}/member/refund/balance`, data);
};
// 会员-查询会员资产明细-罗子健
export const findMemberProperty = data => {
  return http.get(`${prefix}/find/member/property`, data);
};
// 会员-根据会员id查询会员资产记录(充值退款明细列表)-罗子健
export const findMemberAssetsInfo = data => {
  return http.get(`${prefix}/find/member/assets/info`, data);
};
// 会员-.会员详情单个资产退款-罗子健 http://192.168.1.50:40001/project/41/interface/api/3958
export const memberAssetsInfo = data => {
  return http.put(`${prefix}/member/assets/info`, data);
};

//查看积分规则 -刘资涌
export const integralRule = data => {
  return http.get(`${prefix}/integral/rule`, data);
};

//查看会员等级详情 -刘资涌
export const memberLevel = data => {
  return http.get(`${prefix}/level`, data);
};

//订单折扣中的会员折扣 -刘资涌
export const cartCheckMemberLevel = data => {
  return http.get(`${prefix}/cart/check/member/level`, data);
};

/**
 * 会员详情下的记录
 */
// 【member005】资产记录 -刘资涌
export function balanceRecord(data) {
  return http.get(`${prefix}/balance/record/info`, data);
}
// 【member006】积分记录 -刘资涌
export function integralRecord(data) {
  return http.get(`${prefix}/integral/record/info`, data);
}
// 成长值记录 -刘资涌
export function growthRecord(data) {
  return http.get(`${prefix}/growth/record/info`, data);
}

/**###################  新旧接口分割线  #######################*/

/**
 * 会员等级
 */
// 【member010】查看会员等级详情 -刘资涌
export const levelOne = data => {
  return http.get(`${prefix}/level`, data);
};

// 会员折扣-家炜http://192.168.1.50:40001/project/41/interface/api/4666
export const wholeOrderMemberDiscountInfo = data => {
  return http.post(`${prefix}/whole/order/member/discount/info`, data);
};

/**
 * MS-会员接口 2.0
 */
/**
 * 会员积分规则
 */
//添加积分规则 -刘资涌
export const pointRuleSave = data => {
  return http.add(`${prefix}/point/rule/save`, data);
};
//编辑积分规则 -刘资涌
export const pointRuleUpdate = data => {
  return http.put(`${prefix}/point/rule/update`, data);
};
//获取当前景区积分规则 -刘资涌
export const pointRuleInfoOne = data => {
  return http.get(`${prefix}/point/rule/info/one`, data);
};
//计算会员积分抵扣金额 - 刘院民
export const memberPointRule = data => {
  return http.get(`${prefix}/member/point/rule`, data);
};

/**
 * 会员
 */
//添加会员 -刘资涌
export const memberSave = data => {
  return http.add(`${prefix}/member/save`, data);
};
//更新会员 -刘资涌
// export const memberUpdate = data => {
//   return http.put(`${prefix}/member/update`, data);
// };
//获取会员列表 分页 -刘资涌
export const memberListPage = data => {
  return http.get(`${prefix}/member/list/page`, data);
};
//获取会员详情 -刘资涌
// export const memberInfoOne = data => {
//   return http.get(`${prefix}/member/info/one`, data);
// };
//修改会员积分 -刘资涌
export const memberPointUpdate = data => {
  return http.put(`${prefix}/member/point/update`, data);
};
//积分记录 -刘资涌
export const memberPointListPage = data => {
  return http.get(`${prefix}/member/point/list/page`, data);
};
//会员积分剩余详情 -刘资涌
export const memberRemainPoint = data => {
  return http.get(`${prefix}/member/remain/point`, data);
};
//开通会员特权-刘院民
export const memberDredgePrivilege = data => {
  return http.post(`${prefix}/member/dredge/privilege`, data);
};
// 景区后台和窗口 积分记录分页 -刘资涌
export const pagePointRecord = data => {
  return http.get(`${prefix}/page/point/record`, data);
};
// 景区后台和窗口 资产列表分页 -刘资涌
export const pageBalanceRecord = data => {
  return http.get(`${prefix}/page/balance/record`, data);
};
// 窗口-会员-消费记录 - 刘院民
export const memberConsumerRecord = data => {
  return http.get(`${prefix}/member/consumer/record`, data);
};

/**
 * 会员等级
 */
//添加会员等级 -刘资涌
export const levelSave = data => {
  return http.add(`${prefix}/member/level/save`, data);
};
//会员等级列表 不分页 -刘资涌
export const levelList = data => {
  return http.get(`${prefix}/level/list`, data);
};
//会员等级信息详情 -刘资涌
// export const levelOne = data => {
//   return http.get(`${prefix}/member/level/one`, data);
// };
//删除会员等级信息 -刘资涌
export const levelDelete = data => {
  return http.delete(`${prefix}/member/level/delete`, data);
};
//编辑会员等级信息 -刘资涌
export const levelUpdate = data => {
  return http.put(`${prefix}/member/level/update`, data);
};
//会员等级图片列表 分页 根据了当前的景区id -刘资涌
export const levelIconPage = data => {
  return http.get(`${prefix}/member/level/icon/page`, data);
};
//添加会员等级图片 -刘资涌
export const levelIconSave = data => {
  return http.add(`${prefix}/member/level/icon/save`, data);
};

/** 会员充值 */
//新增充值套餐 -刘资涌
export const saveMemberRechargePack = data => {
  return http.add(`${prefix}/save/member/recharge/pack`, data);
};
// 查询充值套餐详情 -刘资涌
export const oneMemberRechargePack = data => {
  return http.get(`${prefix}/one/member/recharge/pack`, data);
};
// 更新充值套餐 -刘资涌
export const updateMemberRechargePack = data => {
  return http.get(`${prefix}/update/member/recharge/pack`, data);
};
// 停售 -刘资涌
export const statusMemberRechargePack = data => {
  return http.put(`${prefix}/status/member/recharge/pack`, data);
};
// 查询充值套餐列表 分页 -刘资涌
export const pageMemberRechargePack = data => {
  return http.get(`${prefix}/page/member/recharge/pack`, data);
};
// 获取当前景区的充值套餐，不分页 -刘资涌
export const listMemberRechargePack = data => {
  return http.get(`${prefix}/list/member/recharge/pack`, data);
};

/** 会员余额 */
// 可退会员余额列表-刘院民
export const packageInfoList = data => {
  return http.get(`${prefix}/package/info/list`, data);
};

// 退会员余额-确定退款操作-刘院民
export const refundMemberBalance = data => {
  return http.post(`${prefix}/refund/member/balance`, data);
};

/** 会员优惠券 */
// 优惠券记录-刘院民
export const memberCouponList = data => {
  return http.get(`${prefix}/member/coupon/list`, data);
};

// 查询快速下单团体分类下拉框 不分页-许家炜
export const touristManagementList = data => {
  return http.get(`${prefix}/select/tourist/management/info/by/group`, data);
};

// 根据身份证过滤信息 查询游客分类列表(窗口使用)-许家炜
export const touristInfoBycard = data => {
  return http.get(`${prefix}/select/tourist/window/info/by/card`, data);
};
// 最最新版本 根据身份证过滤信息 查询游客分类列表(窗口/游小铺使用)-许家炜 -http://192.168.1.50:40001/project/41/interface/api/3731
export const toUpTouristInfoByCard = data => {
  return http.post(
    `${prefix}/to/up/date/select/tourist/window/info/by/card`,
    data
  );
};
// 根据身份证过滤信息 查询游客分类列表(窗口使用)-许家炜-新版 http://192.168.1.50:40001/project/41/interface/api/4039
export const newTouristInfoBycard = data => {
  return http.get(`${prefix}/new/select/tourist/info/by/card`, data);
};
// 查询快速下单分类下拉框 不分页-许家炜
export const selectTouristManagementInfo = data => {
  return http.get(`${prefix}/select/tourist/management/info`, data);
};

// 根据产品ID查询 快速下单个人类型-许家炜
export const selectTouristWindowInfoByProductId = data => {
  return http.get(`${prefix}/select/tourist/window/info/by/product/id`, data);
};
// 根据产品ID查询 游客类型-许家炜
export const newSelectTouristWindowInfoByProductId = data => {
  return http.get(
    `${prefix}/new/select/tourist/window/info/by/product/id`,
    data
  );
};

// 通过会员id和产品列表计算会员折扣多少钱 http://192.168.1.50:40001/project/41/interface/api/3959
export const levelDiscount = data => {
  return http.post(`${prefix}/level/discount`, data);
};
// 绑定实体卡号和修改实体卡号 http://192.168.1.50:40001/project/41/interface/api/4044
export const memberBindingEntityCard = data => {
  return http.post(`${prefix}/member/binding/entity/card`, data);
};
// 会员详情中 产品列表下的兑换券明细
export const memberCoinDetail = data => {
  return http.get(`${prefix}/member/coin/certificate/detail`, data);
};
// 根据会员信息、购物车id返回我的产品,我的兑换券,我的兑换币-罗子健 http://192.168.1.50:40001/project/41/interface/api/4246
export const memberPaymentInformation = data => {
  return http.post(`${prefix}/verify/member/payment/information`, data);
};
//检验用户支付密码是否正确 - 罗子健 暂无api文档
export const checkMemberPasswordInfo = data => {
  return http.get(`${prefix}/cart/check/member/password/info`, data);
};
