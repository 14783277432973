<!--
  @name: 读取腕带ic卡组件
  @author: fengyanlong
  @date:2021-05-19
-->
<template>
  <a-input
    :value="value"
    :placeholder="placeholder"
    @focus="listen"
    @blur="stop"
    :allow-clear="allowClear"
    @change="handleChange"
    @pressEnter="pressEnter"
    ref="focus"
  >
    <a-icon type="scan" slot="suffix" class="primary font-xl" />
  </a-input>
</template>

<script>
export default {
  name: "IcCardInput",
  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "请录入卡/腕带编号"
    },
    allowClear: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      cardInfo: null,
      ready: false
    };
  },
  methods: {
    focus() {
      this.$refs.focus.focus(); // 获取焦点
    },
    readIcCard(res) {
      if (this.ready) {
        this.$emit("input", res);
        this.$emit("change");
        this.ready = false;
      }
    },
    readIdCard(res) {
      this.cardInfo = JSON.parse(res);
      if (this.ready) {
        this.$emit("input", this.cardInfo.id);
        this.$emit("change");
        this.$emit("readCard", this.cardInfo);
      }
    },
    listen() {
      window.readIcCard = this.readIcCard; // 安卓端占位
      window.readIdCard = this.readIdCard; // 四合一硬件
      this.ready = true;
    },
    stop() {
      this.$emit("blur");
      this.ready = false;
      window.readIdCard = function() {};
    },
    handleChange(e) {
      this.$emit("input", e.target.value);
      this.$emit("change");
    },
    // 回车键
    pressEnter() {
      this.$emit("pressEnter");
    }
  }
};
</script>

<style lang="less" scoped></style>
