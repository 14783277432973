<!--
  @name: 售票购物车组件
  @author: fengyanlong
  @date:2021-03-31
-->
<template>
  <div
    class="wrapper shopping-cart bg-white"
    :class="{ 'full-height': fullheight }"
  >
    <header class="header bd-b p-2x">待结账清单</header>
    <section
      class="main flex-grow"
      :class="{ 'flex-col flex-center': !cartList.id }"
    >
      <!-- 空购物车 -->
      <a-empty
        :image="emptyCar"
        :image-style="{
          width: '238px',
          height: '238px',
          margin: '0 auto 50px'
        }"
        :description="false"
        v-if="!cartList.id"
      />

      <!-- 购物车列表开始 -->
      <template v-if="cartList">
        <div
          class="l_tree_container"
          v-for="product in cartList.productItemList"
          :key="product.productId"
        >
          <div class="l_row">
            <div class="l_folder">
              <span class="text-2">{{ product.productName }}</span>
              <span class="handle align-start">
                <a-button type="link" @click="delGoods(product, cartList.id)">
                  删除
                </a-button>
              </span>
            </div>
          </div>
          <p class="dark">
            <template v-if="editProductId !== product.productId">
              <span>{{ product.sellingPrice | money }}</span>
              <a-button
                v-if="product.whetherChangePrice"
                type="normal"
                size="small"
                class="ml-1x"
                @click="changePrice(product)"
                >改价</a-button
              >
            </template>
            <template v-if="editProductId === product.productId">
              <a-input
                v-inputfocus
                v-model="newPrice"
                ref="changePriceInputRef"
                :placeholder="product.sellingPrice / 100"
                size="small"
                prefix="￥"
                style="width:120px"
                clearable
              >
              </a-input>
              <a-button
                type="primary"
                class="ml-x"
                size="small"
                @click="doChangePrice"
              >
                确定
              </a-button>
              <a-button size="small" @click="editProductId = ''">取消</a-button>
            </template>
          </p>
          <!--分时预约-->
          <div class="l_row mt-x" v-if="product.appointmentTimeId">
            <span class="gray"
              >预约时段：{{ product.appointmentBeginTime | date("hh:mm") }} ~
              {{ product.appointmentEndTime | date("hh:mm") }}</span
            >
          </div>
          <ul
            class="l_tree"
            v-if="
              product.voucherPrice ||
                product.whetherRecommend ||
                product.isSupportFastChannel
            "
          >
            <!--卡腕带-->
            <li class="l_tree_branch" v-if="product.voucherPrice">
              <div v-if="product.voucherType">
                <p v-if="product.voucherType == 0">纸质门票</p>
                <p v-else-if="product.voucherType == 1">非芯片卡/腕带</p>
                <p v-else-if="product.voucherType == 2">芯片卡/腕带</p>
                <p class="dark">{{ product.voucherPrice | money }}</p>
              </div>
            </li>
            <!--推荐加购-->
            <li class="l_tree_branch" v-if="product.whetherRecommend">
              <div class="l_row flex-start align-center">
                <div class="l_tree_children_btn" @click="changePlus">+</div>
                <div class="flex-between align-center flex-grow">
                  <p class="flex-grow ml-1x">加购产品推荐</p>
                  <p class="text-r">
                    <a-button type="link" @click="addPurchase(product)"
                      >选择</a-button
                    >
                  </p>
                </div>
              </div>
              <ul
                class="l_tree"
                v-show="isShowList && product.addPurchaseProductItemList"
              >
                <li
                  class="l_tree_branch"
                  v-for="son in product.addPurchaseProductItemList"
                  :key="son.spuId"
                  v-show="son.buyNum > 0"
                >
                  <div class="l_row">
                    <a-row>
                      <a-col :span="16">{{ son.productName }} </a-col>
                      <a-col :span="8">x{{ son.buyNum }}</a-col>
                    </a-row>
                    <p class="dark">
                      {{ (son.sellingPrice * son.buyNum) | money }}
                    </p>
                  </div>
                </li>
              </ul>
            </li>
            <!--快速通行-->
            <li class="l_tree_branch" v-if="product.isSupportFastChannel">
              <p class="l_folder">
                <span style="width: 100%">快速通行服务</span>
                <a-switch
                  :checked="product.fastChannelType"
                  @change="onChange($event, product)"
                />
              </p>
              <p class="dark">
                {{ product.fastChannelPrice | money }}
              </p>
            </li>
          </ul>
          <a-row class="mv-1x">
            <a-col :span="8" class="gray">{{
              product.totalSellingPrice | money
            }}</a-col>
            <a-col :span="8" class="font-xl">
              <!-- settlementPrice  -->
              {{ product.allSettlementPrice | money }}
            </a-col>
            <div class="quantity-style">
              <div class="minus-style" @click="reduceNum(product, cartList.id)">
                <a-icon type="minus" />
              </div>
              <a-input
                v-model="product.buyNum"
                class="num-style"
                @keydown="onkeydown($event)"
                @focus="setIsDefaultKeydown"
                onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                @blur="
                  () => {
                    handleChange(product.buyNum, product, cartList.id);
                  }
                "
              ></a-input>
              <div
                class="plus-style"
                :disabled="isAddNumDisabled(product.buyNum)"
                @click="addNum(product, cartList.id)"
              >
                <a-icon type="plus" />
              </div>
            </div>
          </a-row>
        </div>
      </template>
      <!-- 购物车列表结束 -->
    </section>
    <!-- 购物车底部开始 -->
    <div class="shadow">
      <div class="align-center flex-between ph-3x pt-x">
        <span class="gray">共{{ cartList.ticketNum || 0 }}项 合计</span>
        <span class="font-xxl">{{ cartList.totalPrice || 0 | money }}</span>
      </div>
      <div class="under flex-between">
        <a-button
          v-permission="'appHomeSellOrderCancel'"
          class="flex-grow"
          size="large"
          shape="round"
          :disabled="!cartList.ticketNum"
          @click="cancelAll"
          >整单取消</a-button
        >
        <a-button
          v-permission="permission"
          class="flex-grow ml-2x"
          type="primary"
          size="large"
          shape="round"
          @click="checkOut"
          :disabled="!cartList.ticketNum"
        >
          <span class="mr-2x font-n"
            >结账<span class="f-key" v-if="shortcutKey"></span>：</span
          >
          <span> {{ cartList.totalPrice || 0 | money }}</span>
        </a-button>
      </div>
    </div>
    <!-- 购物车底部结束 -->
  </div>
</template>

<script>
import {
  updateShoppingCart,
  deleteOrCancel,
  registerSettleAccounts,
  changePrice
} from "@/api/cart";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ShoppingCart",
  props: {
    // 购物车，是否满屏（用于副屏页面设置）
    fullheight: {
      type: Boolean,
      default: false
    },
    permission: {
      type: String,
      default: "appHomeSellOrderCalc"
    }
  },
  data() {
    return {
      title: "购物车",
      emptyCar: require("../assets/images/empty_car.png"),
      SHOP_MAX: 1000, // 和振山确认,每张票最多只能买1000张,不管后台配置的最大购买值
      isShowList: false,
      excessQuantity: false, // 是否有票超出最大购买值
      editProductId: "", //正在改价格的产品id
      newPrice: "" //改价格时输入的新价格
    };
  },
  computed: {
    ...mapGetters({
      doingAddPurchase: "ticketSales/getdoingAddPurchase",
      shortcutKey: "setting/getshortcutKey",
      cartList: "order/cartList"
    })
  },
  created() {
    //window.addEventListener("keydown", this.keyDown);
    // 监听storage事件,用于T2副屏购物车组件实现数据实时更新
    window.addEventListener("storage", this.storageFn);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.storageFn);
  },
  methods: {
    storageFn(e) {
      const _this = this;
      if (e.newValue && (e.key == "showcartT2" || e.key == "T2cartList")) {
        // 下了单，又返回购物车的场景
        if (e.newValue == "true" && e.key == "showcartT2") {
          _this.setCartList(JSON.parse(localStorage.getItem("T2cartList")));
        } else if (e.newValue && e.key == "T2cartList") {
          _this.setCartList(JSON.parse(e.newValue));
        }
      } else {
        _this.setCartList({});
      }
    },
    setIsDefaultKeydown() {
      this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    },
    /**** 数据交互方法 **/
    ...mapActions("order", ["setTouristInfo", "setCartList"]),
    /*** 页面交互方法 */

    //监听keydown事件
    onkeydown(e) {
      // 键盘按下，处理相关事件
      var ev = window.event || e;
      var code = ev.keyCode || ev.which;
      let conflictArr = [
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        96,
        97,
        98,
        99,
        100,
        101,
        102,
        103,
        104,
        105
      ];
      if (conflictArr.some(item => item === code)) {
        this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
      }
    },
    /**
     * @description 判断当前数量是否大于最大数量
     */
    isAddNumDisabled(num) {
      return +num >= this.SHOP_MAX;
    },
    //删除一个
    delGoods(row, cartId) {
      if (this.cartList.productItemList.length === 1) {
        this.handleClear();
      } else {
        this.updateByChangeNum(row, cartId, 0);
      }
    },
    //数量减一
    reduceNum(row, cartId) {
      this.excessQuantity = false;
      this.updateByBuyNum(row, cartId, -1);
    },
    //数量加一
    addNum(row, cartId) {
      if (row.buyNum > this.SHOP_MAX) {
        this.excessQuantity = true;
        this.$message.warning(`${row.productName}最多买${this.SHOP_MAX}张`);
        return;
      }
      this.excessQuantity = false;
      this.updateByBuyNum(row, cartId, 1);
    },
    //手动改变数量
    handleChange(number, row, cartId) {
      if (number > this.SHOP_MAX) {
        this.excessQuantity = true;
        this.$message.warning(`${row.productName}最多买${this.SHOP_MAX}张`);
        return;
      }
      this.excessQuantity = false;
      this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
      this.updateByChangeNum(row, cartId, number);
    },
    //整单取消
    cancelAll() {
      this.$confirm({
        title: "清空购物车",
        content: "确定要清空购物车吗？",
        onOk: () => {
          this.handleClear();
        },
        onCancel() {}
      });
    },
    //回车结账
    keyDown(e) {
      if (!this.shortcutKey) return;
      if (e.keyCode === 13 && this.cartList.id) {
        this.checkOut();
      }
    },
    //结账
    checkOut() {
      // 购买的数量，超出最大数量
      if (this.excessQuantity) {
        this.$message.warning(`每份最大购买数量为：${this.SHOP_MAX}`);
        return;
      }
      if (this.doingAddPurchase) {
        this.$message.warning("请先完成推荐加购产品的选择");
        return false;
      }
      localStorage.setItem("showcartT2", "false"); // 共享数据，给T2副屏用（副屏是另外一个VUE实例）,用于告知副屏是否显示购物车组件
      if (this.$store.state.common.windowType === "sellTicket") {
        // console.log(this.cartList.id);
        //请求出游人信息
        registerSettleAccounts({ id: this.cartList.id })
          .then(res => {
            if (res.needVisitorInfo) {
              //保存出游人信息到vuex
              this.saveTourisInfo(res);
              this.$router.push({
                name: "homeRegisterTouristInfo"
              });
            } else {
              this.$router.push("/home/sell/OrderInfo");
            }
          })
          .catch(() => {});
      } else {
        this.$router.push("/home/sell/OrderInfo");
      }
    },
    //改变快票状态
    onChange(checked, row) {
      this.setFast(row, checked);
    },
    //保存出游人资料到vuex
    saveTourisInfo(res) {
      let visitorsList = [];
      res.productItemList.forEach(item => {
        let i = item.buyNum;
        while (i > 0) {
          let newItem = JSON.parse(JSON.stringify(item)); //deepclone
          //拆分成多个出游人信息
          if (newItem.travelerList !== null) {
            visitorsList.push(newItem);
          }
          i--;
        }
      });
      this.setTouristInfo({
        visitorsList: visitorsList,
        purchaserMemberInfo: res.purchaserMemberInfo
      });
    },
    //+号展开伸缩
    changePlus() {
      this.isShowList = !this.isShowList;
    },
    //选择加购
    addPurchase(row) {
      this.isShowList = true;
      // this.isShowPurchase = true;
      //触发加购列表的事件
      this.$bus.emit("addPurchase", row);
      this.$store.commit("ticketSales/setdoingAddPurchase", true);
    },
    //通过改变buyNum更新购物车
    updateByBuyNum(row, cartId, number) {
      let data = {
        id: cartId, //购物车id
        productId: row.productId, // 产品id
        spuId: row.spuId, // 产品的spuId
        playDate: row.playDate,
        timeShareAppointmentRuleId: row.appointmentTimeId,
        buyNum: number //-1或减1
      };
      this.updateSelf(data);
    },
    //通过changeNum更新购物车
    updateByChangeNum(row, cartId, number) {
      let data = {
        id: cartId, //购物车id
        productId: row.productId, // 产品id
        spuId: row.spuId, // 产品的spuId
        playDate: row.playDate,
        timeShareAppointmentRuleId: row.appointmentTimeId,
        changeNum: Number(number)
      };
      this.updateSelf(data);
    },
    //通知父组件更新
    updateSelf(data) {
      updateShoppingCart(data)
        .then(res => {
          if (!res.productItemList) {
            this.handleClear();
            return;
          }
          this.setCartList(res); // 更新购物车列表
          localStorage.setItem("T2cartList", JSON.stringify(res)); // 共享数据，给T2副屏用（副屏是另外一个VUE实例）
        })
        .catch(() => {});
    },
    //执行清空购物车
    handleClear() {
      deleteOrCancel({ id: this.cartList.id })
        .then(() => {
          this.clearCart();
        })
        .catch(err => {
          console.log(err);
          this.$message.warning("删除失败，请重试");
        });
    },
    //本地清空购物车
    clearCart() {
      this.setCartList({});
      localStorage.setItem("T2cartList", JSON.stringify({})); // 共享数据，给T2副屏用（副屏是另外一个VUE实例）
    },
    setFast(row, checked) {
      let data = {
        id: this.cartList.id, //购物车id
        productId: row.productId, // 产品id
        spuId: row.spuId, // 产品的spuId
        whetherFastTicket: checked
        //buyNum: row.buyNum
      };
      this.updateSelf(data);
    },
    //改价格
    changePrice(product) {
      this.editProductId = product.productId;
      this.$nextTick(() => {
        this.$refs.changePriceInputRef[0].focus();
      });
    },
    //确定改价
    doChangePrice() {
      changePrice({
        id: this.cartList.id,
        productId: this.editProductId,
        changePrice: Math.round(this.newPrice * 100)
      })
        .then(res => {
          this.setCartList(res); // 更新购物车列表
          this.editProductId = "";
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
@import "../assets/less/custom.less";

// 去掉了number的默认样式
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.shopping-cart {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  width: 364px;
  .header {
    padding: 12px 16px;
    font-size: 24px;
  }
  .main {
    padding: 0 16px 8px;
    font-size: 16px;
    /deep/.ant-btn-link {
      padding: 0 0 0 16px;
      font-size: 18px;
      line-height: 1;
    }
  }
}
.l_tree_container {
  width: 100%;
  position: relative;
  padding-top: 16px;
  padding-bottom: 12px;
  border-bottom: 3px solid #eff0f3;
  &:last-child {
    border: none;
  }
}
.l_tree {
  width: 100%;
  height: 100%;
  padding-left: 14px;
  padding-top: 20px;
}
.l_tree_branch {
  position: relative;
  display: block;
  width: 100%;
  padding: 6px 0 12px 12px;
}
.l_folder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.l_tree_children_btn {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #999;
  color: #999;
  outline: none;
  cursor: pointer;
}
.l_row {
  position: relative;
  .l_tree_children_btn + .l_folder {
    position: absolute;
    top: -3px;
    left: 30px;
  }
}
ul.l_tree:before {
  content: "";
  border-left: 1px dashed #999999;
  height: calc(100%);
  position: absolute;
  top: 0;
  left: 10px;
}

.l_tree .l_tree_branch:last-child::before {
  content: "";
  width: 4px;
  height: calc(100% - 16px);
  display: block;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: -6px;
}

.l_tree,
.l_tree_branch {
  position: relative;
}

.l_tree_branch::after {
  content: "";
  width: 12px;
  height: 0;
  border-bottom: 1px dashed #999999;
  position: absolute;
  right: calc(100% - 9px);
  top: 16px;
}
.text-2 {
  width: 100%;
}
.handle {
  width: 60px;
}
.quantity {
  width: 50px;
  text-align: center;
  border-left: none;
  border-right: none;
}
.under {
  padding: 4px 16px 12px;
}

@media screen and (max-width: 1366px) {
  .f-key {
    display: none;
  }
}

.full-height {
  height: 100%;
}
</style>
