<!--
  @name: 身份证输入框
  @author: fengyanlong
  @attr: {
    v-model:{
      type: String   //身份证ID
    }
  }
  @event:{
    change:fn()   //组件内身份证id改变时触发
    readCard:return{
      sex:男、女
      photo:base64图片
      name:姓名
      birth: 生日  yyyy-mm-dd
    } //把读到的身份证信息返回到组件外
  }
-->
<template>
  <a-input
    :size="size"
    :value="value"
    :placeholder="currentPlaceholder"
    @focus="listen"
    @blur="stop"
    @pressEnter="pressEnter"
    :maxLength="18"
    @change="handleChange"
    :allow-clear="allowClear"
    ref="focus"
  >
    <span
      @click="pressEnter"
      slot="addonAfter"
      class="iconfont iconcredentials_icon myicon primary"
    ></span>
  </a-input>
</template>

<script>
export default {
  name: "IdCardInput",
  data() {
    return {
      currentPlaceholder: "请刷身份证/输入身份证号",
      cardNo: "",
      cardInfo: null,
      ready: false
    };
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "default"
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    // 是否社保卡，默认不是
    medicalCard: {
      type: Boolean,
      default: false
    },
    // 是否是用于购物信息批量读取身份证校验，对应产品填入信息，默认false
    isCartVerify: {
      type: Boolean,
      default: false
    },
    currentCardId: {
      type: String,
      default: ""
    }
  },

  created() {
    if (this.placeholder) {
      this.currentPlaceholder = this.placeholder;
    } else if (this.medicalCard) {
      this.currentPlaceholder = "请刷社保卡/输入社保卡号";
    }
  },

  mounted() {
    // this.stop()
    // this.listen();
  },
  methods: {
    focus() {
      this.$refs.focus.focus(); // 获取焦点
    },
    readIdCard(res) {
      if (res) {
        this.cardInfo = JSON.parse(res);
        let id = this.medicalCard ? this.cardInfo.xbCard : this.cardInfo.id;
        // isCartVerify为true开启时
        if (this.isCartVerify) {
          // 如果购物信息读取身份证发现与上一次读取是同一个证件号，不作处理
          if (this.currentCardId === this.cardInfo.id) {
            this.$message.warning("证件号读取重复!");
            return;
          }
        }
        this.$emit("input", id);
        this.$emit("change", id);
        this.$emit("readCard", this.cardInfo);
        this.$refs.focus.blur(); // 失去焦点
      }
    },
    //因为扫码不触发 readIdCard
    pressEnter() {
      // let value = this.$refs.focus.value;
      // let err = isIdcard(value);
      // // 不是身份证则是社保卡
      // if (err && value.length >= 18) {
      //   socialSecurityCardGetTouristInfoWindow({
      //     socialSecurityCode: value
      //   }).then(res => {
      //     let obj = { id: res.aac002, name: res.aac003, mobile: res.mobile };
      //     this.cardInfo = obj;
      //     this.$emit("input", this.cardInfo.id);
      //     this.$emit("change");
      //     this.$emit("readCard", this.cardInfo);
      //     this.stop();
      //   });
      // } else {
      //   this.$emit("pressEnter");
      //   this.ready = false;
      // }

      this.$emit("pressEnter");
      this.stop();
    },
    listen(e) {
      this.$emit("focus", e.target.value);
      // isCartVerify为true开启时
      if (e.target.value && this.isCartVerify) {
        // 如果当前有值，则赋值
        this.$emit("update:current-card-id", e.target.value);
      }
      window.readIdCard = this.readIdCard;
    },
    stop() {
      this.$emit("blur");
      window.readIdCard = function() {};
    },
    handleChange(e) {
      if (!e.target.value && this.isCartVerify) {
        this.$emit("update:current-card-id", "");
      }
      this.$emit("input", e.target.value);
      this.$emit("change", e.target.value);
    }
  },
  beforeDestroy() {
    window.readIdCard = () => {};
  }
};
</script>

<style lang="less" scoped>
.iconfont.myicon {
  font-size: 20px;
}
</style>
