<template>
  <div class="index">
    <a-button @click="test">测试按钮</a-button>
    <a-button type="primary" @click="openCamera">打开摄像头</a-button>
    <a-button type="primary" @click="print">打印门票</a-button>
    <a-button type="primary" @click="say">语音播放</a-button>
    <a-button @click="getSize">获取尺寸</a-button>
    <a-button type="primary" @click="openNotify">打开通知</a-button>
    <a-form class="myform">
      <a-form-item
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        label="读取身份证"
      >
        <IdCardInput v-model="card1"></IdCardInput>
        <IdCardInput v-model="card2"></IdCardInput>
        <IcCardInput v-model="icCard1"></IcCardInput>
      </a-form-item>
    </a-form>
    <a-row>
      <a-col :span="12">
        <div class="keyboard">
          <keyboard-pro type="2" v-model="numbers" @ok="searchMember">
            <template v-slot:input="{ confirm }">
              <a-form-model autocomplete="off">
                <a-input
                  class="mb-2x"
                  size="large"
                  placeholder="请扫码查询/输入手机号查询"
                  v-model="numbers"
                  allow-clear
                  @pressEnter="confirm()"
                >
                  <a-icon slot="prefix" type="search" />
                  <a-icon type="scan" slot="suffix" />
                </a-input>
              </a-form-model>
            </template>
            <div slot="midSlot">
              <span class="red">{{ this.numbers }}还不是会员，</span>
              <a-button class="p-0" type="link">快速办理</a-button>
            </div>
          </keyboard-pro>
        </div>
      </a-col>
      <a-col :span="8">
        <face-input class="m-2x" v-model="person1"></face-input>
        <picture-input class="m-2x"></picture-input>
      </a-col>
      <a-col :span="8">
        <cell-password v-model="numbers2" :cellNumber="8"></cell-password>
      </a-col>
    </a-row>

    <SelectAddress
      v-model="areaList"
      @selected="selected"
      :level="1"
    ></SelectAddress>
  </div>
</template>

<script>
import IdCardInput from "@/components/hardware/IdCardInput";
import IcCardInput from "../components/hardware/IcCardinput.vue";
import KeyboardPro from "../components/common/KeyboardPro.vue";
import FaceInput from "../components/hardware/FaceInput.vue";

import SelectAddress from "../components/select/SelectAddress.vue";
import PictureInput from "../components/hardware/PictureInput.vue";
import CellPassword from "../components/common/CellPassword.vue";

export default {
  name: "test",
  data() {
    return {
      title: "首页",
      numbers: "",
      scanCode: "",
      imgSrc: "",
      card1: "",
      card2: "",
      card3: "",
      icCard1: "",
      person1: 0,
      person2: 0,
      areaList: ["44", "4409"],
      areaListName: [],
      numbers2: ""
    };
  },
  components: {
    IdCardInput,
    IcCardInput,
    KeyboardPro,
    FaceInput,
    SelectAddress,
    PictureInput,
    CellPassword
  },
  created() {},
  mounted() {
    window.deeptest = this.deeptest;
    window.getPicture = this.getPicture;
  },
  methods: {
    selected(data) {
      this.areaListName = data;
    },
    searchMember() {},
    test() {
      window.test.choicePrinter("yanlong");
    },
    deeptest(res) {
      this.$message.success(res);
    },
    say() {
      window.test.speak("你好吗？how are you");
    },
    print() {
      let data = { name: "yanlong", age: "31", sex: 1, height: 175 };
      let sub = JSON.stringify(data);
      window.test.printTicket(sub);
    },
    openCamera() {
      window.test.openCamera();
    },
    getSize() {
      let w = window.screen.width;
      let h = window.screen.height;
      this.$message.success(`屏幕宽度：${w}px，屏幕高度：${h}px`);
    },
    scanOk() {
      this.$message.success(this.scanCode);
    },
    openNotify() {
      this.$notification.open({
        message: "Notification Title",
        description: h => {
          let arr = [1, 2, 3];
          return h(
            "ul",
            arr.map(item => {
              return h(
                "li",
                {
                  class: ["primary", "p-2x"]
                },
                "a" + item
              );
            })
          );
        },
        icon: <a-icon type="smile" style="color: #108ee9" />
      });
    },
    getPicture(base64) {
      this.imgSrc = `data:image/png;base64,${base64}`;
    }
  }
};
</script>

<style lang="less" scoped>
.index {
  height: 90%;
  min-height: 600px;
  width: 100%;
  z-index: 99;
  top: 10%;
  overflow: hidden;
}
.myform {
  background: #ffffff;
  width: 600px;
  margin: 0 auto;
}
.ant-btn {
  margin: 10px;
}
.keyboard {
  width: 500px;
}
.input-fixed-left {
  /deep/.ant-input {
    &:not(:last-child) {
      padding-left: 90px;
    }
  }
}
</style>
