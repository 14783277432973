/**
 * 由于切换景区和退出登录需要清空数据，避免污染
 * 增删state的内容时要同时维护mutations内的resetState的方法
 * 后续有时间优化这里
 * */
export default {
  shortcut_key: true, //快捷键控制
  loading: false, //全局的loading
  user: {}, // 用户信息
  member: {
    id: ""
  }, //会员信息
  windowType: "sellTicket", //售票：sellTicket,零售：retail,项目消费：consume
  isEnableTakeOrderBtn: false, // 是否启用取单按钮

  // 选中的销售商户信息，请求时在头部传给后台
  saleMerchantId: sessionStorage.getItem("saleMerchantId") || "", // 销售商户Id
  saleMerchantName: sessionStorage.getItem("saleMerchantName") || "", // 销售商户名称

  // 动态菜单内容
  showMenu: true, // 展开菜单
  currentLeftMenuList: [], // 左侧菜单
  currentTopMenuKey: sessionStorage.getItem("currentTopMenuKey") || "", //高亮的顶部菜单key
  currentLeftMenuKey: sessionStorage.getItem("currentLeftMenuKey") || "" //高亮的左侧菜单key
};
