export default {
  //设置购票人/出游人信息
  setTouristInfo(state, data) {
    state.visitorsList = data.visitorsList; //出游人信息
    state.purchaserMemberInfo = data.purchaserMemberInfo; //购票人需要填的字段信息
  },
  //保存购物车列表
  setCartList(state, data) {
    state.cartList = data; //购物车列表
  },
  // 重置state，清空历史数据
  resetState(state) {
    const data = {
      cartList: {},
      visitorsList: [],
      purchaserMemberInfo: {},
      discountTextList: [],
      integralTextList: [],
      endOfPayConfig: {
        logoutMember: true,
        backUrl: "/home"
      }
    };
    for (let key in state) {
      state[key] = data[key];
    }
  },

  // 支付重构：订单折扣优惠（新增、更新）
  setOrderDiscountTextList(state, data) {
    state.discountTextList = [];
    state.discountTextList.push(data);
  },
  // 支付重构：订单折扣优惠（删除）
  removeOrderDiscountTextList(state) {
    state.discountTextList = [];
  },
  // 支付重构：积分抵扣（新增、更新）
  setIntegralDeduction(state, data) {
    state.integralTextList = [];
    state.integralTextList.push(data);
  },
  // 支付重构：积分抵扣（删除）
  removeIntegralDeduction(state) {
    state.integralTextList = [];
  },
  // 支付重构：清空所有优惠数据
  clearDiscount(state) {
    state.discountTextList = [];
    state.integralTextList = [];
  },
  // 支付完成后，需要告知支付页面的操作配置
  setEndOfPayConfig(state, data) {
    if (Object.prototype.toString.call(data) != "[object Object]") {
      throw new Error("设置支付完成配置vuex：传入的参数，只能是js对象");
    }
    state.endOfPayConfig = { ...state.endOfPayConfig, ...data };
  }
};
