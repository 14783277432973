/**
 * @name:MS-用户中心接口-3.0
 * @date:2021-03-24
 * @description:新表模块
 **/
import http from "../utils/fetch"; // 公共请求方法
const prefix = "/api/user";
/**
 *用户登录授权
 */
// user001-用户登录-云玉峰
export const login = data => {
  return http.post(`${prefix}/login`, data);
};
// user002-用户授权-云玉峰
export const authorize = data => {
  return http.post(`${prefix}/authorize`, data);
};
// user003-用户登录-云玉峰
export const logout = data => {
  return http.post(`${prefix}/logout`, data);
};

/**
 *用户管理
 */
//user023-查询当前商户员工列表(不分页)-云玉峰
export const findUserList = data => {
  return http.get(`${prefix}/find/user/list`, data);
};
//user024-用户详情查询-云玉峰
export const findUserDetail = data => {
  return http.get(`${prefix}/find/user/detail`, data);
};

/**
 *部门管理
 */
//【merchant033】- 获取部门用户 - 刘院民
export const deptUserList = data => {
  return http.get(`${prefix}/dept/user/list`, data);
};

/**
 * @version:2.0 MS-用户中心接口-以下旧接口
 *
 * 待删除
 **/

/**
 *用户权限信息
 */
//根据用户id获取用户组织列表信息-李腾飞
export const findStructureList = () => {
  return http.get(`${prefix}/find/structure/list`);
};
//获取用户授权菜单-云玉峰
export const findUserMenuList = () => {
  return http.get(`${prefix}/find/user/menu/list`);
};
//锁屏后登录-判断密码-刘院民
export const unlockTheScreen = data => {
  return http.post(`${prefix}/unlock/the/screen`, data);
};

// 长白山商户重构 - 获取登陆用户授权菜单 现在是在窗口端用 http://192.168.1.50:40001/project/41/interface/api/3714
export const getMainMenuList = data => {
  return http.get(`${prefix}/get/main/menu/list`, data);
};

// 长白山商户重构 - 获取登陆用户授权菜单 现在是在窗口端用 http://192.168.1.50:40001/project/41/interface/api/3715
export const getSubMenuList = data => {
  return http.get(`${prefix}/get/sub/menu/list`, data);
};
// 查询用户是否有改价权限，非窗口端和手持机都是false http://192.168.1.50:40001/project/41/interface/api/4489
export const roleHighAuth = data => {
  return http.get(`${prefix}/role/high/auth`, data);
};

/**
 *用户管理
 */
//查询当前组织用户列表-云玉峰
export const findUserCurrentList = () => {
  return http.get(`${prefix}/find/user/current/list`);
};
//组织用户列表分页查询-云玉峰
export const findUserPageList = data => {
  return http.get(`${prefix}/find/user/page/list`, data);
};
//组织用户详情查询-云玉峰
export const findUserInfo = data => {
  return http.get(`${prefix}/find/user/info`, data);
};
//获取当前登录用户的基本信息-云玉峰
export const findCurrentInfo = () => {
  return http.get(`${prefix}/find/current/info`);
};
//新增组织用户-云玉峰
export const userSave = data => {
  return http.add(`${prefix}/user/save`, data);
};
//修改组织用户-云玉峰
export const userUpdate = data => {
  return http.put(`${prefix}/user/update`, data);
};
//启用组织用户账号-云玉峰
export const userEnable = data => {
  return http.put(`${prefix}/user/enable`, data);
};
//禁用组织用户账号-云玉峰
export const userDisable = data => {
  return http.put(`${prefix}/user/disable`, data);
};

/**
 *角色管理
 */
//分页查询角色列表-云玉峰
export const rolePageList = data => {
  return http.get(`${prefix}/role/page/list`, data);
};
//获取可配置的菜单列表-云玉峰
export const roleMenuList = data => {
  return http.get(`${prefix}/role/menu/list`, data);
};
//获取下拉角色列表-云玉峰
export const roleList = () => {
  return http.get(`${prefix}/role/list`);
};
//新增修改角色-云玉峰
export const roleSave = data => {
  return http.add(`${prefix}/role/save`, data);
};
//新增修改角色-云玉峰
export const roleUpdate = data => {
  return http.put(`${prefix}/role/update`, data);
};
//查看角色详情-云玉峰
export const roleInfo = data => {
  return http.get(`${prefix}/role/info`, data);
};
//删除角色-云玉峰
export const roleDelete = data => {
  return http.delete(`${prefix}/role/delete`, data);
};

/**
 *菜单管理
 */
//获取菜单树-云玉峰
export const menuTree = data => {
  return http.get(`${prefix}/menu/tree`, data);
};
//新增菜单-云玉峰
export const menuSave = data => {
  return http.add(`${prefix}/menu/save`, data);
};
//编辑菜单-云玉峰
export const menuUpdate = data => {
  return http.put(`${prefix}/menu/update`, data);
};
//删除菜单-云玉峰
export const menuDelete = data => {
  return http.delete(`${prefix}/menu/delete`, data);
};

/**
 *部门管理
 */
//部门信息详情-云玉峰
export const sectionInfo = data => {
  return http.get(`${prefix}/section/info`, data);
};
//部门树查询-云玉峰
export const sectionTree = () => {
  return http.get(`${prefix}/section/tree`);
};
//部门用户树查询
export const sectionUserTree = () => {
  return http.get(`${prefix}/section/user/tree`);
};
//部门信息保存(新增)-云玉峰
export const sectionSave = data => {
  return http.post(`${prefix}/section/save`, data);
};
//部门信息保存(编辑)-云玉峰
export const sectionUpdate = data => {
  return http.put(`${prefix}/section/update`, data);
};
//删除部门-云玉峰
export const sectionDelete = data => {
  return http.delete(`${prefix}/section/delete`, data);
};

/**
 *产品授权
 */
//获取软件产品可授权的菜单信息-云玉峰
export const productMenuTree = data => {
  return http.get(`${prefix}/product/menu/tree`, data);
};
//保存软件产品授权菜单信息-云玉峰
export const productMenuSave = data => {
  return http.add(`${prefix}/product/menu/save`, data);
};
//修改软件产品授权菜单信息-云玉峰
export const productMenuUpdate = data => {
  return http.put(`${prefix}/product/menu/update`, data);
};

/**
 *二销-商户接口
 */
// 查询所属商户信息-许家炜
export const findSellerListIds = () => {
  return http.get(`${prefix}/find/seller/list/ids`);
};
// 增加商户 -刘资涌
export const saveSeller = data => {
  return http.add(`${prefix}/save/seller`, data);
};
// 更新商户 -刘资涌
export const updateSeller = data => {
  return http.put(`${prefix}/update/seller`, data);
};
// 获取商户详情 -刘资涌
export const findSellerOne = data => {
  return http.get(`${prefix}/find/seller/one`, data);
};
// 获取商户列表 - 刘资涌
export const findPageList = data => {
  return http.get(`${prefix}/find/page/list`, data);
};
// 获取商户列表 不分页 -刘资涌
export const findList = () => {
  return http.get(`${prefix}/find/list`);
};
// 变更商户状态 -刘资涌
export const updateSellerStatus = data => {
  return http.put(`${prefix}/update/seller/status`, data);
};
// 获取支付配置信息 -刘资涌
export const findPayConfig = () => {
  return http.get(`${prefix}/find/pay/config`);
};
// 查询二销产品类型下对应的商户集合 -刘资涌
export const findSellerCategoryIds = data => {
  return http.get(`${prefix}/find/seller/category/ids`, data);
};
// 通过商户id（mchId，非主键）获取商户信息-陈铭
export const findSellerMchId = data => {
  return http.get(`${prefix}/find/seller/mchId`, data);
};

/**
 *二销-商户类型接口
 */
// 新增商户类型 -刘资涌
export const addSellerType = data => {
  return http.add(`${prefix}/add/seller/type`, data);
};
// 修改商户类型 -刘资涌
export const updateSellerType = data => {
  return http.put(`${prefix}/update/seller/type`, data);
};
// 根据景区id获取商户类型列表 分页 -刘资涌
export const listSellerType = data => {
  return http.get(`${prefix}/list/seller/type`, data);
};
// 删除商户类型 -刘资涌
export const deleteSellerType = data => {
  return http.delete(`${prefix}/delete/seller/type`, data);
};
// 获取商户类型最大顺序和编号 -刘资涌
export const selectMax = () => {
  return http.get(`${prefix}/select/max`);
};

/**
 *分销商
 */
// 添加景区分销商分组-胡汉杰
export const addDistributorGroupOrganization = data => {
  return http.add(`${prefix}/distributor/group/organization`, data);
};
// 编辑分销商分组-胡汉杰
export const updateDistributorGroup = data => {
  return http.put(`${prefix}/distributor/group`, data);
};
// 获取分销商分组详情-胡汉杰
export const getDistributorGroup = data => {
  return http.get(`${prefix}/distributor/group`, data);
};
// 获取景区分销商分组列表-胡汉杰
export const updateDistributorGroupOrganization = data => {
  return http.get(`${prefix}/distributor/group/organization`, data);
};
// 景区删除分销商分组-胡汉杰
export const deleteDistributorGroupOrganization = data => {
  return http.delete(`${prefix}/distributor/group/organization`, data);
};
// 景区添加分销商-胡汉杰
export const addDistributorOrganization = data => {
  return http.add(`${prefix}/distributor/organization`, data);
};
// 更新分销商-胡汉杰
export const updateDistributorOrganization = data => {
  return http.put(`${prefix}/distributor/organization`, data);
};
// 获取景区分销商详情-胡汉杰
export const getDistributorOrganization = data => {
  return http.get(`${prefix}/distributor/organization`, data);
};
// 获取景区分销商列表-胡汉杰
export const getDistributorOrganizationList = data => {
  return http.get(`${prefix}/distributor/organization/list`, data);
};
// 景区批量修改分销商分组-胡汉杰
export const distributorOrganizationGroup = data => {
  return http.put(`${prefix}/distributor/organization/group`, data);
};
// 景区变更分销商状态-胡汉杰
export const distributorOrganizationStatus = data => {
  return http.put(`${prefix}/distributor/organization/status`, data);
};
// 预存余额调整-胡汉杰
export const distributorAccountRecordDeposit = data => {
  return http.put(`${prefix}/distributor/account/record/deposit`, data);
};
// 预存账户变更记录-胡汉杰
export const distributorAccountRecordDepositList = data => {
  return http.get(`${prefix}/distributor/account/record/deposit/list`, data);
};
// 授信额度调整-胡汉杰
export const distributorAccountRecordCredit = data => {
  return http.put(`${prefix}/distributor/account/record/credit`, data);
};
// 授信额度调整记录-胡汉杰
export const distributorAccountRecordCreditList = data => {
  return http.get(`${prefix}/distributor/account/record/credit/list`, data);
};
// 还款登记-胡汉杰
export const distributorAccountRecordCreditRefund = data => {
  return http.post(`${prefix}/distributor/account/record/credit/refund`, data);
};
// 授信账户变更记录-胡汉杰
export const distributorAccountRecordCreditRefundList = data => {
  return http.get(
    `${prefix}/distributor/account/record/credit/refund/list`,
    data
  );
};
// 保证金登记-胡汉杰
export const distributorAccountRecordBond = data => {
  return http.put(`${prefix}/distributor/account/record/bond`, data);
};
// 保证金变更记录-胡汉杰
export const distributorAccountRecordBondList = data => {
  return http.get(`${prefix}/distributor/account/record/bond/list`, data);
};

/**
 *景区管理
 */
// 获取景区列表(查询单个数据)-许家炜
export const organizationList = data => {
  return http.get(`${prefix}/organization/list`, data);
};
// 详情(根据ID查询数据)-许家炜
export const organizationGetbyidOne = data => {
  return http.get(`${prefix}/organization/get_by_id/one`, data);
};
// 新增景区信息-许家炜
export const organizationSave = data => {
  return http.add(`${prefix}/organization/save`, data);
};
//修改景区信息-许家炜
export const organizationUpdate = data => {
  return http.put(`${prefix}/organization/update`, data);
};
// 注册景区信息（添加体验用户）-许家伟
export const registeredOrganizationSave = data => {
  return http.post(`${prefix}/registered/organization/save`, data);
};

// 暂无子账户的景区-许家炜
export const organizationNotSubList = () => {
  return http.get(`${prefix}/organization/not_sub/list`);
};
//景区信息维护（景区后台） -刘资涌
export const organizationInfoUpdate = data => {
  return http.put(`${prefix}/organization/info/update`, data);
};
//获取景区信息（景区后台） -刘资涌
export const organizationInfo = () => {
  return http.get(`${prefix}/organization/info`);
};
//获取购票协议（景区后台） -刘资涌
export const organizationBuyProtocolOne = () => {
  return http.get(`${prefix}/organization/buy/protocol/one`);
};
//编辑购票协议（景区后台） -刘资涌
export const organizationBuyProtocolUpdate = data => {
  return http.put(`${prefix}/organization/buy/protocol/update`, data);
};

/**
 * 服务商接口
 */
//新增服务商-邓小春
export const serviceProviderSave = data => {
  return http.add(`${prefix}/service_provider/save`, data);
};
//服务商续期-邓小春
export const renewalServiceProvider = data => {
  return http.put(`${prefix}/renewal/service_provider`, data);
};
//服务商充值-邓小春
export const rechargeServiceProvider = data => {
  return http.put(`${prefix}/recharge/service_provider`, data);
};
//服务商终止协议-邓小春
export const terminationServiceProvider = data => {
  return http.put(`${prefix}/termination/service_provider`, data);
};
//获取服务商编码-邓小春
export const serviceProviderCode = () => {
  return http.get(`${prefix}/service_provider/code`);
};
//修改服务商-邓小春
export const serviceProviderUpdate = data => {
  return http.put(`${prefix}/service_provider/update`, data);
};
//分页获取服务商列表-邓小春
export const serviceProviderList = data => {
  return http.get(`${prefix}/service_provider/list`, data);
};
//分页查询服务商交易明细-邓小春
export const serviceProviderFlowList = data => {
  return http.get(`${prefix}/service/account/flow/list`, data);
};
//获取服务商详情-邓小春
export const findServiceProviderByid = data => {
  return http.get(`${prefix}/find/service_provider/byid`, data);
};
//获取当前服务商详情-云玉峰
export const findServiceProviderCurrent = () => {
  return http.get(`${prefix}/find/service_provider/current`);
};
//服务商列表-许家炜
export const serviceProviderAllList = () => {
  return http.get(`${prefix}/service_provider/all/list`);
};
//修改服务商信息-许家炜
export const changeOrgService = data => {
  return http.put(`${prefix}/change_org_service`, data);
};
// 获取景区列表(服务商平台)-许家炜
export const organizationListService = data => {
  return http.get(`${prefix}/organization/list/service`, data);
};
//user000-获取登录用户账号信息-云玉峰
export const getUserInfo = data => {
  return http.get(`${prefix}/find/user/account/info`, data);
};
//user000-设置密码-云玉峰
export const setPassword = data => {
  return http.post(`${prefix}/pwd/setting`, data);
};
//user000-重置密码-云玉峰
export const restPassword = data => {
  return http.post(`${prefix}/pwd/reset`, data);
};

/**
 *短信验证码登录
 */
//用户发送短信验证码登录
export const smsCodeLogin = data => {
  return http.post(`${prefix}/sms/code/login`, data);
};

//获取游小铺导游列表-胡汉杰
export const guideList = data => {
  return http.get(`${prefix}/guide/list`, data);
};
//分销商添加导游-刘资涌
export const guide = data => {
  return http.add(`${prefix}/guide`, data);
};
