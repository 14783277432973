/**
 *交账路由
 */
export default [
  // 交账入口
  {
    path: "/account",
    // route level code-splitting 路由级代码分割
    // this generates a separate chunk (about.[hash].js) for this route 为该路由生成一个单独的块(about.[hash].js)
    // which is lazy-loaded when the route is visited. 当路由被访问时，它是惰性加载的。
    component: () =>
      import(
        /* webpackChunkName: "chuck-login" */ "../views/account/AccountIndex.vue"
      ),
    children: [
      // 交账详情
      {
        path: "/account/AccountDetail",
        name: "AccountDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/account/AccountDetail.vue"
          )
      },
      // 交账记录
      {
        path: "/account/AccountsRecording",
        name: "AccountsRecording",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/account/AccountsRecording.vue"
          )
      },
      // 补账记录
      {
        path: "/account/RepairAccountDetail",
        name: "RepairAccountDetail",
        component: () =>
          import(
            /* webpackChunkName: "chuck-home" */ "../views/account/RepairAccountDetail.vue"
          )
      }
    ]
  }
];
