import {
  Button,
  Icon,
  Row,
  Col,
  Dropdown,
  Menu,
  Pagination,
  Cascader,
  Checkbox,
  DatePicker,
  TimePicker,
  FormModel,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Upload,
  Avatar,
  Collapse,
  Empty,
  List,
  Popover,
  Table,
  Tabs,
  Tag,
  Drawer,
  Modal,
  Spin,
  ConfigProvider,
  Divider,
  message,
  notification,
  Form,
  Transfer,
  Tooltip,
  Calendar
} from "ant-design-vue";
const components = [
  Button,
  Icon,
  Row,
  Col,
  Dropdown,
  Menu,
  Pagination,
  Cascader,
  Checkbox,
  DatePicker,
  TimePicker,
  FormModel,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Upload,
  Avatar,
  Collapse,
  Empty,
  List,
  Popover,
  Table,
  Tabs,
  Tag,
  Drawer,
  Modal,
  Spin,
  ConfigProvider,
  Divider,
  Form,
  Transfer,
  Tooltip,
  Calendar
];
export function antd(Vue) {
  components.forEach(comp => {
    Vue.use(comp);
  });
  Vue.prototype.$message = message;
  Vue.prototype.$notification = notification;
  Vue.prototype.$confirm = Modal.confirm;
  Vue.prototype.$info = Modal.info;
  Vue.prototype.$success = Modal.success;
  Vue.prototype.$error = Modal.error;
  Vue.prototype.$warning = Modal.warning;
  process.env.NODE_ENV !== "production" &&
    console.warn(
      "Antd组件是按需引入，需用到哪个组件，请添加到：antd.js文件里;\n\n 需用到的icon图标，请在antd-icons.js文件添加"
    );
}
