/**
 *全局的相关变量配置，其他属性请不要写入这个文件哦
 */
let getEveryConfigFn = (state, typeStr) => {
  let obj = {};
  if (typeStr === "get") {
    for (const key in state) {
      obj[`${typeStr}${key}`] = s => {
        return s[key];
      };
    }
  } else {
    for (const key in state) {
      obj[`${typeStr}${key}`] = (s, params) => {
        s[key] = params;
      };
    }
  }
  return obj;
};

const state = {
  SYSTEM_INPUT_IS_DEFAULT_DOWN: false //全局键盘事件，true=为input框默认的键盘事件,false=为自定义的键盘事件
};

const mutations = {
  // 重置state，清空历史数据 (系统设置信息不需要重置)
  /*  resetState(state) {
    const data = {
      SYSTEM_INPUT_IS_DEFAULT_DOWN: false
    };
    for (let key in state) {
      state[key] = data[key];
    }
  }*/
};
export default {
  name: "setting",
  namespaced: true,
  state,
  getters: getEveryConfigFn(state, "get"),
  mutations: { ...getEveryConfigFn(state, "set"), ...mutations }
};
