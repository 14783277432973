<!--
  支付成功页面
-->
<template>
  <div class="pay-success">
    <ProgressBar :cur-step="3"></ProgressBar>
    <div class="pay-success-wrap">
      <div class="check-icon">
        <a-icon type="check" />
      </div>
      <p class="font-xxl">
        支付成功...
      </p>
      <p>
        <span class="red">{{ timeNum }}</span>
        秒后将自动跳转返回
      </p>
      <a-button
        @click="continueSure"
        type="primary"
        size="large"
        class="btn-lg"
      >
        继续下单
      </a-button>
    </div>
  </div>
</template>
<script>
import ProgressBar from "@/components/common/ProgressBar.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "PaySuccess",
  components: { ProgressBar },
  props: {},
  data() {
    return {
      timeNum: 5,
      timer: null
    };
  },
  computed: {
    ...mapGetters("order", ["endOfPayConfig"])
  },
  created() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timeNum = 5;
    }
    this.payCountdown();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig"]),
    ...mapActions("common", ["updateMember"]),
    // 继续下单
    continueSure() {
      const url = this.endOfPayConfig.backUrl || "/";
      // 是否需要清空会员信息
      if (this.endOfPayConfig.logoutMember) {
        this.updateMember({});
      }
      // 恢复配置信息到默认状态
      this.setEndOfPayConfig({
        logoutMember: true,
        backUrl: "/"
      });
      this.$router.replace({ path: url });
    },
    // 支付倒计时
    payCountdown() {
      this.timer = setInterval(() => {
        if (this.timeNum <= 0) {
          clearInterval(this.timer);
          this.timer = null;
          // 跳转回首页
          this.continueSure();
          return;
        }
        this.timeNum = this.timeNum - 1;
      }, 1000);
    }
  }
};
</script>
<style lang="less" scoped>
.pay-success-wrap {
  width: 1000px;
  margin: 100px auto 0;
  text-align: center;
  .check-icon {
    width: 110px;
    height: 110px;
    text-align: center;
    line-height: 110px;
    background: #31ba61;
    color: #fff;
    border-radius: 50%;
    margin: 0 auto 30px;
    font-size: 58px;
  }
  .btn-lg {
    width: 253px;
    height: 60px;
    background: #5396fb;
    border-radius: 30px;
    margin-top: 50px;
  }
}
</style>
