export default {
  shortcutKey(state) {
    return state.shortcut_key;
  },
  lockScreen(state) {
    return state.lock_screen;
  },
  memberId(state) {
    return state.member.id;
  },
  memberInfo(state) {
    return state.member;
  },

  // 获取权限按钮
  getCurrentBtnPermission(state) {
    const permission = [];
    findPermission(state.currentLeftMenuList);
    function findPermission(arr) {
      for (let item of arr) {
        if (item.menuKey) {
          permission.push(item.menuKey);
        }
        if (item.children && item.children.length) {
          findPermission(item.children);
        }
      }
    }
    return permission;
  },

  // 获取商户信息，header请求信息用
  getSaleMerchant(state) {
    let saleMerchant = "";
    if (state.saleMerchantId && state.saleMerchantName) {
      saleMerchant = `${state.saleMerchantId},${encodeURIComponent(
        state.saleMerchantName
      )}`;
    }
    return saleMerchant;
  }
};
